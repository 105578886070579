import React, { useState } from 'react';
import Swal from 'sweetalert2';
import '../estilos/tarjeta_servicio_a.css';

const ServiceCard = ({ name, quantities, unitCost, onHire, blocked, tipoGastronomia }) => {
  const [quantity, setQuantity] = useState(1);

  const handleHire = () => {
    if (isNaN(unitCost) || isNaN(quantity) || quantity <= 0) {
      console.error('El costo unitario y la cantidad deben ser números válidos y positivos');
      return;
    }

    const CostoServicio = unitCost * quantity;

    Swal.fire({
      title: `¿Deseas agregar el servicio de ${name} por un valor de $${CostoServicio} para ${quantity} personas?`,
      showCancelButton: true,
      confirmButtonText: 'Sí, agregar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        onHire(CostoServicio, name, quantity);
        Swal.fire('Agregado!', 'El servicio ha sido agregado al carrito.', 'success');
      }
    });
  };

  const handleChangeQuantity = (e) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value > 0) {
      setQuantity(value);
    } else {
      setQuantity(1);
    }
  };

  return (
    <div className={`card food-service-card mb-4 ${blocked ? 'blocked' : ''}`}>
      <div className='card-body'>
        <div className="row">
          <div className='col-xl-5'>
            <h5>{name}</h5>
            {tipoGastronomia && (
              <p className='text-muted'>
                {Object.keys(tipoGastronomia)
                  .filter(key => tipoGastronomia[key].checked)
                  .map(key => key)
                  .join(', ')
                }
              </p>
            )}
          </div>

          <div className='col-xl-5'>
            <p>Costo promedio por plato : ${unitCost}</p>
            <label htmlFor="quantity">Cantidad de personas que comerán aquí por día:</label>
            <input className='form-control' type="number" id="quantity" value={quantity} onChange={handleChangeQuantity} />
          </div>

          <div className='col-xl-2'>
            <button onClick={handleHire} disabled={blocked} className='btn btn-primary mt-4 mt-xl-0'>Agregar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
