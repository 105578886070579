import React, { useState } from 'react';
import './estilos/crear.css'
import {useNavigate} from 'react-router-dom';
import { /*signUp*/ signIn, resetPassword, auth } from '../firebaseConfig/firebase'; // Importa las funciones de autenticación
import BannerServices from '../componentes/componentes_paginas/bannerServices';
import Swal from 'sweetalert2';

function Sesion() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    /*const handleSignUp = () => {
      signUp(email, password)
        .then((userCredential) => {
          // Usuario registrado con éxito
          const user = userCredential.user;
          console.log('Usuario registrado:', user);
        })
        .catch((error) => {
          // Handle errors
          console.error('Error al registrar el usuario:', error);
        });
    };*/
  
    const handleSignIn = () => {
      signIn(email, password)
        .then((userCredential) => {
          // Usuario inició sesión con éxito
          const user = userCredential.user;
          console.log('Usuario inició sesión:', user);
          navigate('/ver');
        })
        .catch((error) => {
          // Handle errors
          console.error('Error al iniciar sesión:', error);
          Swal.fire('Error al iniciar sesión')
          navigate('/Sesion');
        });
    };

    // Función para cerrar la sesión del usuario
  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate('/'); // Redirige al usuario a la página de inicio u otra página según tus necesidades
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
      Swal.fire('Error al cerrar sesión');
    }
  };

    const handleResetPassword = async () => {
      if (email) {
        const result = await resetPassword(email);
        if (result) {
          // Éxito al enviar el correo de restablecimiento
          console.log('Correo de restablecimiento enviado con éxito.');
          Swal.fire(`Se ha enviado un email a ${email} para cambiar tu contraseña`)
          navigate('/Sesion');
        } else {
          // Error al enviar el correo de restablecimiento
          console.error('Error al enviar el correo de restablecimiento.');
          Swal.fire(`Ocurrio un error al enviar el correo de recuperación a ${email}, vuelve a intentarlo en un momento`)
          navigate('/ver');
        }
      } else {
        // El campo de correo electrónico está vacío
        console.error('Ingrese un correo electrónico válido.');
        // Puedes mostrar un mensaje de error al usuario aquí.
      }
    };
    
  
    return (
      <div className=" d-flex flex-column justify-content-center align-items-center">
        <div className="container-fluid bg-light">
          <div className='container pt-md-5'>
            <div className='row'>
              <div className='col-12 py-4'>
                <h1 className='pt-5'></h1>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid bg-light">
          <div className='container py-5'>
            <div className='row'>
              <div className='col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4'>
                <div className='card p-5'>
                  <div className='card-body'>
                    <h2 className='pb-4'> Iniciar Sesión </h2>

                    <div className="mb-3">
                      <label className="form-label">Correo</label>
                      <input type="email" className="form-control" placeholder="Correo electrónico" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Contraseña</label>
                      <div className="input-group mb-3">
                        <input type={showPassword ? "text" : "password"} className="form-control" placeholder="Contraseña" value={password} onChange={(e) => setPassword(e.target.value)} />
                        <button className="btn btn-secondary" type="button" onClick={() => setShowPassword(!showPassword)} >
                          {showPassword ? <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i> }
                        </button>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-12 mb-3'>
                        <button onClick={handleSignIn} className="btn btn-primary w-100">Iniciar Sesión</button>
                      </div>
                      <div className='col-12'>
                        <button onClick={handleResetPassword} className="btn btn-link w-100">¿Olvidaste tu contraseña?</button>
                      </div>
                      {/*<div className='col-12'><button onClick={handleSignUp}>Registrarse</button></div>*/}
                      {/*<div className='col-12'><button onClick={handleSignOut} className="btn btn-danger">Cerrar Sesión</button></div>*/}                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BannerServices/>
      </div>
    );
  }

export default Sesion;
