import React, { useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from "../firebaseConfig/firebase";
//import { Collapse } from 'bootstrap';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

const Ver = () => {

    //1.- configuramos los hooks
    const [servAlojamiento, setServAlojamiento] = useState ( [] );
    const [servTransporte, setServTransporte] = useState ( [] );
    const [servGastronomico, setServGastronomico] = useState ( [] );
    const [servOtro, setServOtro] = useState ( [] );

    const [searchTerm, setSearchTerm] = useState("");
    //const [open, setOpen] = useState(false);

    //2.- referenciamos a la database de Firestore
    const servAlojamientoCollection = collection(db,'servicio_alojamiento');
    const servTransporteCollection = collection(db,'servicio_transporte');
    const servGastronomicoCollection = collection(db,'servicio_gastronomico');
    const servOtroCollection = collection(db,'servicio_otro');

    //3.- creamos la funcion para mostrar todos los documentos
    const getServAlojamiento = async () => {
       const data = await getDocs(servAlojamientoCollection);
       //console.log(datos.docs)
       setServAlojamiento(
            data.docs.map( (doc) => ( {...doc.data(),id:doc.id} ))
       )
       console.log(servAlojamiento);
    } 

    const getServTransporte = async () => {
      const data = await getDocs(servTransporteCollection);
      //console.log(datos.docs)
      setServTransporte(
           data.docs.map( (doc) => ( {...doc.data(),id:doc.id} ))
      )
      console.log(servTransporte);
    }
   
   const getServGastronomico = async () => {
      const data = await getDocs(servGastronomicoCollection);
      //console.log(datos.docs)
      setServGastronomico(
         data.docs.map( (doc) => ( {...doc.data(),id:doc.id} ))
      )
      console.log(servGastronomico);
    }

    const getServOtro = async () => {
      const data = await getDocs(servOtroCollection);
      //console.log(datos.docs)
      setServOtro(
         data.docs.map( (doc) => ( {...doc.data(),id:doc.id} ))
      )
      console.log(servOtro);
    }

    //4.- creamos una funcion para eliminar un documento
    const deleteAlojamiento = async (id) => {
        const alojamientoDoc = doc(db, 'servicio_alojamiento', id)
        await deleteDoc(alojamientoDoc)
        getServAlojamiento();
      }

    const deleteTransporte = async (id) => {
      const transporteDoc = doc(db, 'servicio_transporte', id)
      await deleteDoc(transporteDoc)
      getServAlojamiento();
    }

    const deleteGastronomico = async (id) => {
      const gastronomicoDoc = doc(db, 'servicio_gastronomico', id);
      await deleteDoc(gastronomicoDoc);
      getServGastronomico();
    }

    const deleteOtro = async (id) => {
      const otroDoc = doc(db, 'servicio_otro', id);
      await deleteDoc(otroDoc);
      getServOtro();
    }
    
    //5.- creamos una funcion de confirmacion para sweetAlert
    const confirmarDelete = (id, tipoServicio) => {
      let deleteFunc;
    
      switch (tipoServicio) {
        case 'alojamiento':
          deleteFunc = deleteAlojamiento;
          break;
        case 'transporte':
          deleteFunc = deleteTransporte;
          break;
        case 'gastronomico':
          deleteFunc = deleteGastronomico;
          break;
        case 'otro':
          deleteFunc = deleteOtro;
          break;
        default:
          console.log('Tipo de servicio no válido');
          return;
      }
    
      MySwal.fire({
        title: 'Estas seguro?',
        text: "no se podrá revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Borrarlo!'
      }).then((result) => {
        if (result.isConfirmed) {
          deleteFunc(id)
          Swal.fire(
            'Borrado!',
            'La locacion a sido borrada de la base de datos.',
            'success'
          )
        }
      })
    }

    // 6.- usamos useEffect
    useEffect( () => {
      getServAlojamiento()
      getServTransporte()
      getServGastronomico()
      getServOtro()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] )

    const renderTableRows = (data, tipoServicio) => {
      return data
        .filter((item) =>
          item.empresa.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.rut.includes(searchTerm) ||
          item.ciudad.includes(searchTerm)
        )
        .map((item) => (
          <tr key={item.id}>
            <td>{tipoServicio}</td>
            <td>{item.empresa}</td>
            <td>{item.ciudad}</td>
            <td>
              {/* Mostrar la imagen */}
              <img src={item.imagen} alt={`Imagen de ${item.empresa}`} style={{ maxWidth: '100px', maxHeight: '100px' }} />
            </td>
            <td>
              <Link to={`/inspeccionar/${item.id}/${tipoServicio}`} className="btn btn-light"><i className="fa-solid fa-eye"></i></Link>
              <button onClick={() => confirmarDelete(item.id, tipoServicio)} className="btn btn-danger"><i className="fa-regular fa-trash-can"></i></button>
            </td>
          </tr>
      ));
    }
    // 8.- retornamos la vista de nuestro componente
    return(
        <div className="admin-container">
          <div className="container-fluid bg-white">
            <div className='container pt-5'>
              <div className='row pt-4'>
                <div className='col-12 py-4'>
                  <h1 className='pt-5'>Admin <strong>dashboard</strong></h1>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-4">
                <div className='d-grid gap-2'>
                  <Link to='/locacionVer' className='btn btn-secondary mt-2 mb-2'><i className="fa-solid fa-panorama fa-lg"></i>  LOCACIONES</Link>
                </div>
                <div className='d-grid gap-2'>
                  <Link to='/servicio' className='btn btn-secondary mt-2 mb-2'><i className="fa-solid fa-hotel"></i> AGREGAR UN NUEVO SERVICIOS DE ALOJAMIENTO</Link>
                </div>
                {<div className='d-grid gap-2'>
                  <Link to='/servicio_transporte' className='btn btn-secondary mt-2 mb-2'><i className="fa-solid fa-van-shuttle"></i> AGREGAR UN NUEVO SERVICIOS DE TRASNPORTE</Link>
                </div>}
                {<div className='d-grid gap-2'>
                  <Link to='/servicio_gastronomico' className='btn btn-secondary mt-2 mb-2'><i className="fa-solid fa-utensils"></i> AGREGAR UN NUEVO SERVICIOS DE GASTRONOMIA</Link>
                </div>}
                {<div className='d-grid gap-2'>
                  <Link to='/servicio_otro' className='btn btn-secondary mt-2 mb-2'><i className="fa-solid fa-asterisk"></i> AGREGAR UN TIPO DIFERENTE DE SERVICIO</Link>
                </div>}
                {<div className='d-grid gap-2'>
                  <Link to='/blog_entrada' className='btn btn-secondary mt-2 mb-2'><i className="fa-brands fa-blogger"></i> AGREGAR UNA ENTRADA DE BLOG</Link>
                </div>}
              </div>

              <div className="col-8">
                <div className='d-flex justify-content-between mb-3'>
                  <input type='text' placeholder='Buscar por nombre, ciudad o RUT' className='form-control' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                </div>
                {searchTerm !== "" && (
                <table className='table table-dark table-hover '>
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Empresa</th>
                      <th>Ciudad</th>
                      <th>Imagen</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderTableRows(servAlojamiento, 'alojamiento')}
                    {renderTableRows(servTransporte, 'transporte')}
                    {renderTableRows(servGastronomico, 'gastronomico')}
                    {renderTableRows(servOtro, 'otro')}
                  </tbody>
                </table>
                )}
              </div>
            </div>
          </div>
        </div>
        
    )
}

export default Ver;
