import React from 'react';
import '../estilos/tarjeta.css'
import { Link } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';

function Tarjeta({ imageSrc, title, estado, ruta, tags }) {
  const handleClick = () => {
    console.log('LINK DESDE TARJETA', ruta);
  };

  // Procesar los tags de la misma manera que en el carrusel
  const tagsArray = tags ? tags.split(/[,#]/) : []; // Usamos una expresión regular para dividir la cadena por comas o almohadillas
  const trimmedTags = tagsArray.map(tag => tag.trim()); // Eliminamos los espacios en blanco alrededor de cada tag
  const limitedTags = trimmedTags.slice(0, 2); // Limitar a un máximo de 3 tags, OJO consultar dieño para poder integrar mas tags si es necesario
  const tagsElements = limitedTags.filter(tag => tag !== "").map((tag, index) => (
    <Badge key={index} pill bg="secondary" className="p-2 text-dark">{tag}</Badge>
  ));

  return (
    <Link to={ruta} style={{ textDecoration: 'none', color: 'inherit' }}>
      <div className="card mb-4 overflow-hidden" onClick={handleClick} style={{ cursor: 'pointer' }}>
        <img src={imageSrc} alt={title} className='img-fluid' />
        <div className="card-img-overlay">
          <div className='etiqueta-proxima'>
            {estado === 0 && <span className="etiqueta">Próximamente</span>}
          </div>
          <h5 className="card-title">{title}</h5>
          <Stack direction="horizontal" gap={2}>
            {tagsElements}
          </Stack>
        </div>
      </div>
    </Link>
  );
}

export default Tarjeta;
