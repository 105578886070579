// Vacantes.js

export const mapeoTipoCamaAPersonas = {
  UNA_PLAZA: 1,
  UNAYMEDIA_PLAZA: 1,
  DOS_PLAZA: 2,
  LITERA: 2,
  KING: 2,
  SUPER_KING: 2,
  // Agrega más tipos de cama según sea necesario
};

const Vacantes = ({ camasTipoCantidad }) => {
  const calcularTotalVacantes = () => {
    let totalVacantes = 0;

    Object.entries(camasTipoCantidad)
      .filter(([tipo, info]) => info.checked && mapeoTipoCamaAPersonas[tipo] !== undefined)
      .forEach(([tipo, info]) => {
        totalVacantes += info.cantidad * mapeoTipoCamaAPersonas[tipo];
      });

    return totalVacantes;
  };

  const totalVacantes = calcularTotalVacantes();

  return totalVacantes;
};

export default Vacantes;
