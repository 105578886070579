import React, { useState } from 'react';
import { sendCustomEmail } from '../../resendConfig/resend';
import logo from '../../imagenes/logos/logo cuadradon blanco png SHP.png'
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next'; // Importa el hook de traducción

export const ContactForm = () => {
  const { t } = useTranslation(); // Usa el hook de traducción
  const [formData, setFormData] = useState({
    nombre: '',
    correo: '',
    texto: '',
  });

  const [isButtonDisabled] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  function handleSubmit(e) {
		e.preventDefault();
		let nombre = e.target.nombre.value;
		let correo = e.target.correo.value;
		let texto = e.target.texto.value;
		sendCustomEmail(nombre, correo, texto);
		e.target.nombre.value = e.target.correo.value = e.target.texto.value = '';
	}

  return (
    <div className="container-fluid contacto py-5">
      <div className='container py-md-5 my-md-5'>
        <div className='row'>
          <div className='col-6 offset-3 col-md-3 offset-md-2 pb-5'>
            <img src={logo} alt={t('LOGO_ALT.PROYECTO')} className='img-fluid' />
          </div>

          <div className="col-md-4 offset-md-1">
            <h2 className='pb-4'>{t('CONTACT_FORM.TITULO')}</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-3">
                <label htmlFor="nombre">{t('CONTACT_FORM.NOMBRE_LABEL')}</label>
                <input type="text" className="form-control" id="nombre" name="nombre" value={formData.nombre} onChange={handleInputChange} />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="correo">{t('CONTACT_FORM.CORREO_LABEL')}</label>
                <input type="email" className="form-control" id="correo" name="correo" value={formData.correo} onChange={handleInputChange} />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="texto">{t('CONTACT_FORM.TEXTO_LABEL')}</label>
                <textarea className="form-control" id="texto" name="texto" value={formData.texto} onChange={handleInputChange} />
              </div>

              <Button type="submit" variant="primary" size="lg" className="rounded-pill px-5 text-uppercase my-5">{t('CONTACT_FORM.BOTON')}</Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
