import React, { useState, /* useRef */ } from 'react';
import { sendCustomEmail } from '../../resendConfig/resend2';
//import postal from '../../imagenes/carrusel/MonteSanValentin.jpg';
import '../estilos/contacto.css';

export const ContactForm = ({ cotizacion }) => {
  const [formData, setFormData] = useState({
    nombre: '',
    correo: '',
    texto: '',
  });

  const [isButtonDisabled, /* setIsButtonDisabled */] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let nombre = e.target.nombre.value;
    let correo = e.target.correo.value;
    sendCustomEmail(nombre, correo, cotizacion);
    e.target.nombre.value = e.target.correo.value = '';
  };

  /* const captcha = useRef(null);
  const onChange = () => {
    const token = captcha.current.getValue();
    setIsButtonDisabled(false);
  }; */

  return (
    <div className="contacto-container">
      <form onSubmit={handleSubmit}>
        <div className='card bg-light'>
          <div className='card-body'>
            <div className="form-group mb-3">
              <label htmlFor="nombre">Nombre</label>
              <input type="text" className="form-control" id="nombre" name="nombre" value={formData.nombre} onChange={handleInputChange} />
            </div>
            
            <div className="form-group mb-3">
              <label htmlFor="correo">Correo electrónico</label>
              <input type="email" className="form-control" id="correo" name="correo" value={formData.correo} onChange={handleInputChange} />
            </div>
          </div>
        </div>
        
        <div className='captcha-container'>
          <button type="submit" className="btn btn-primary mt-3 btn-captcha btn-lg" disabled={isButtonDisabled}>
            <i className="fa-regular fa-paper-plane fa-lg"></i> Enviar
          </button>
        </div>
      </form>
    </div>
  );
};
