const mapeoRangoCostoAPromedio = {
    '10.000 20.000': 0,
    '10.000 25.000': 1,
    '3.500 7.000': 2,
    '7.000 15.000': 3,  
    '8.000 20.000': 4,
    '900 3.500': 5,
    'MÁS DE 25.000': 6,
    'OTRO VALOR': 7,
    'otro': "" 
  };
  
  const CostoxServicio = ({ costo_servicio }) => {
    // Verificar si al menos uno de los objetos tiene 'checked' en true
    const hasChecked = Object.values(costo_servicio).some(obj => obj.checked);
  
    if (!hasChecked) {
      // Si ninguno de los objetos tiene 'checked' en true, retornar 0
      return 0;
    }
  
    let costoMaximo = null;
  
    Object.entries(costo_servicio)
      .filter(([rango, info]) => info && info.checked && mapeoRangoCostoAPromedio[rango] !== undefined)
      .forEach(([rango]) => {
        const [, max] = rango.split(' ').map(Number); //Aqui obtengo el costo mas alto [TENER EN CUENTA]]
  
        if (costoMaximo === null || max > parseFloat(costoMaximo)) {
          costoMaximo = parseFloat(max);
        }
      });
  
    // Multiplicar el costo máximo por 1000 para convertirlo en el formato deseado
    costoMaximo *= 1000;
  
    // Verificar si costoMaximo es NaN y devolver 0 en ese caso
    return isNaN(costoMaximo) ? 0 : costoMaximo;
  };
  
  export default CostoxServicio;
  