// resend.js
import { db } from '../firebaseConfig/firebase';
import { collection, addDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';

const cuerpoCorreoHTML = `<!DOCTYPE html>
<html lang="es">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css" integrity="sha512-iecdLmaskl7CVkqkXNQ/ZH/XLlvWZOJyj7Yy7tcenmpD1ypASozpmT/E0iPtmFIB46ZmdtAc9eNBvH0H/ZpiBw==" crossorigin="anonymous" referrerpolicy="no-referrer" />
<style type=text/css>
    body {
        font-family: Arial, sans-serif;
        background-color: #f5f5f5;
        margin: 0;
        padding: 0;
    }
    .container {
        max-width: 600px;
        margin: 10px auto;
        background-color: #fff;
        padding: 0px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    .contenido {
        padding: 30px;
    }
    h1 {
        text-align: center;
        color: #333;
    }
    p {
        margin-bottom: 20px;
    }
    .banner {
        position: relative;
        width: 100%;
        height: 40vh; /* Altura del banner al 50% de la altura de la pantalla */
        overflow: hidden; /* Oculta el contenido que se desborda del banner */
    }
    .banner img {
        width: 100%;
        object-fit: cover; /* Escala la imagen para que cubra todo el contenedor sin deformarse */
    }
    .logo {
        position: absolute;
        top: 50px; /* Margen superior */
        left: 50px; /* Margen izquierdo */
    }
    .logo img {
        width: 20vh; /* Ancho de la imagen */
        height: auto; /* Altura automática */
    }
    .footer img{
        width: 100%;
        object-fit: cover; /* Escala la imagen para que cubra todo el contenedor sin deformarse */
    }
    table {
        width: 100%; /* Ajusta el ancho de la tabla al 100% del contenedor */
        border-collapse: collapse; /* Fusiona los bordes de las celdas */
    }
    td {
        text-align: center; /* Centra el contenido de las celdas */
    }
</style>
<title>Respuesta Automática - Formulario de Contacto</title>
</head>
<body>
<div class="container">
    <header>
        <div class="banner">
            <img src="https://firebasestorage.googleapis.com/v0/b/shoot-in-patagonia.appspot.com/o/web%2Frecursos_correos%2FBanner%20mailing%20SHP.jpg?alt=media&token=68fdc93a-75f1-4d46-8f88-89737b3fd444" alt="banner mail">
            <div class="logo">
                <img src="https://shootinpatagonia.com/static/media/logo%20cuadradon%20blanco%20png%20SHP.589bbe3d49df5d6c090e.png" alt="Logo Shoot in Patagonia">
            </div>
        </div>
    </header>
    <section class="contenido">
        <h1>Nuevo Correo de contacto</h1>
        <p>Hola,</p>
        <p>Tienes un nuevo correo de contacto desde la plataforma shootinpatagonia.com: Contacto.</p>
        <p>Detalles del mensaje:</p>
        <ul>
            <li><strong>Nombre:</strong> [Nombre del Remitente]</li>
            <li><strong>Correo Electrónico:</strong> [Correo del Remitente]</li>
            <li><strong>Mensaje:</strong></li>
        </ul>
        <blockquote>[Mensaje del Remitente]</blockquote>
        <p>Atentamente,</p>
        <p>Tu equipo de [Equipo SHP]</p>
    </section>
    <footer class="footer">
        <img src="https://firebasestorage.googleapis.com/v0/b/shoot-in-patagonia.appspot.com/o/web%2Frecursos_correos%2FFooter%20mailing%20shoot%20in%20patagonia.jpg?alt=media&token=432f9180-e585-45e7-91ed-4ae948067ea7" alt="footer mail">
    </footer>
</div>
</body>
</html>
`;

export async function sendCustomEmail(nombre, correo, body) {
    const collectionRef = collection(db, 'mail');
    const emailContent = {
        to: 'desarrollador@catarsis.cl, osvaldo@catarsis.cl,franciscolealm@gmail.com',
        message: {
            subject: nombre,
            text: body,
            html: cuerpoCorreoHTML.replace('[Nombre del Remitente]', nombre)
                                    .replace('[Correo del Remitente]', correo)
                                    .replace('[Mensaje del Remitente]', body),
        },
    };
    Swal.fire('ENVIADO !, MUY PRONTO ESTAREMOS RESPONDIENDO A TU CORREO');
    console.log('listo para ser enviado');
    return await addDoc(collectionRef, emailContent);
}
