import React from "react";
import { Routes,Route, /*Navigate*/ } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseConfig/firebase"; // Importa el objeto de autenticación

function RutaPrivada({ element, ...props }) {
  const [usuario] = useAuthState(auth);

  // Permite el acceso a /Sesion sin autenticación
  if (props.path === "/Sesion") {
    return console.log('epepepe')
   
  }

  return usuario ? (
    <Routes> 
      <Route {...props} element={element} /> 
    </Routes>
  ) : (
    <Routes> 
      <Route {...props} element={'/'} /> 
    </Routes>

  );
}

export default RutaPrivada;
