const mapeoRangoCostoAPromedio = {
  '50.000 75.000': 0,
  '75.000 100.000': 1,
  '100.000 150.000': 2,
  '150.000 200.000': 3,
  '25.000 35.000': 4,
  '35.000 50.000': 5,
  OTRO: 6,
  // Agrega más rangos de costo según sea necesario
};

const CostoxNoche = ({ costo_noche }) => {
  // Verificar si al menos uno de los objetos tiene 'checked' en true
  const hasChecked = Object.values(costo_noche).some(obj => obj.checked);

  if (!hasChecked) {
    // Si ninguno de los objetos tiene 'checked' en true, retornar 0
    return 0;
  }

  let costoMaximo = null;

  Object.entries(costo_noche)
    .filter(([rango, info]) => info && info.checked && mapeoRangoCostoAPromedio[rango] !== undefined)
    .forEach(([rango]) => {
      const [, max] = rango.split(' ').map(Number); //Aqui obtengo el costo mas alto [TENER EN CUENTA]]

      if (costoMaximo === null || max > parseFloat(costoMaximo)) {
        costoMaximo = parseFloat(max);
      }
    });

  // Multiplicar el costo máximo por 1000 para convertirlo en el formato deseado
  costoMaximo *= 1000;

  // Verificar si costoMaximo es NaN y devolver 0 en ese caso
  return isNaN(costoMaximo) ? 0 : costoMaximo;
};

export default CostoxNoche;

