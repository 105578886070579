import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Estilos del carrusel
import { Carousel } from "react-responsive-carousel";
//import videoSource from '../../videos/promociones/Estancia_nirehuao_instagram.mov';
import imagen0 from '../../imagenes/carrusel/banner0.jpg';
import imagen1 from '../../imagenes/carrusel/banner1.jpg';
import imagen2 from '../../imagenes/carrusel/banner2.jpg';
import imagen3 from '../../imagenes/carrusel/banner3.jpg';
import imagen4 from '../../imagenes/carrusel/banner4.jpg';
import imagen5 from '../../imagenes/carrusel/banner5.jpg';
import imagen6 from '../../imagenes/carrusel/banner6.jpg';
import imagen7 from '../../imagenes/carrusel/MonteSanValentin.jpg'; //concerba su nombre por ser usada en otra dependencia (modificar)
import imagen8 from '../../imagenes/carrusel/banner8.jpg';
import imagen9 from '../../imagenes/carrusel/banner9.jpg';
import imagen10 from '../../imagenes/carrusel/banner10.jpg';
import imagen11 from '../../imagenes/carrusel/banner11.jpg';
import imagen12 from '../../imagenes/carrusel/banner12.jpg';
//import logoinicio from '../../imagenes/logos/logo cuadradon blanco png SHP.png';

export
	const CarouselComponent = () => {
	
  return (
    <Carousel autoPlay infiniteLoop showThumbs={false} showIndicators={false} showArrows={false} showStatus={false} animationHandler="fade" swipeable={false} interval={3000} transitionTime={2000}>
     	<div><img src={imagen0} alt="shootinpatagonia_banner0" /></div>
		<div><img src={imagen1} alt="shootinpatagonia_banner1" /></div>
		<div><img src={imagen2} alt="shootinpatagonia_banner2" /></div>
		<div><img src={imagen3} alt="shootinpatagonia_banner3" /></div>
		<div><img src={imagen4} alt="shootinpatagonia_banner4" /></div>
		<div><img src={imagen5} alt="shootinpatagonia_banner5" /></div>
		<div><img src={imagen6} alt="shootinpatagonia_banner6" /></div>
		<div><img src={imagen7} alt="shootinpatagonia_banner7" /></div>
		<div><img src={imagen8} alt="shootinpatagonia_banner8" /></div>
		<div><img src={imagen9} alt="shootinpatagonia_banner9" /></div>
		<div><img src={imagen10} alt="shootinpatagonia_banner10" /></div>
		<div><img src={imagen11} alt="shootinpatagonia_banner11" /></div>
		<div><img src={imagen12} alt="shootinpatagonia_banner12" /></div>
    	{/* Agrega más elementos div para más imágenes */}
    </Carousel>
  );
};

/* export const CarouselComponent4 = () => {
	return (
	  <Carousel autoPlay infiniteLoop showThumbs={true} showArrows={false} showStatus={false} transitionEffect="fade">
		
				<div>
					<video controls className="video-player">
       			<source src={videoSource} type="video/quicktime" />
        		<source src={videoSource} type="video/mp4" />
        		<source src={videoSource} type="video/webm" />
        		Your browser does not support the video tag.
      		</video>
     		</div>
				 <div>
					<video controls className="video-player">
       			<source src={videoSource} type="video/quicktime" />
        		<source src={videoSource} type="video/mp4" />
        		<source src={videoSource} type="video/webm" />
        		Your browser does not support the video tag.
      		</video>
     		</div>
				 <div>
					<video controls className="video-player">
       			<source src={videoSource} type="video/quicktime" />
        		<source src={videoSource} type="video/mp4" />
        		<source src={videoSource} type="video/webm" />
        		Your browser does not support the video tag.
      		</video>
     		</div>

	  </Carousel>
	);
  };
 */
export default CarouselComponent;
