import React, { useState, useEffect } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { REACT_APP_GOOGLE_MAPS_KEY } from "../contents/contents"

const MapComponent = ({ selectedLocation, onCoordenadasChange }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_KEY,
  });
  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);
  
  const [markerPosition, setMarkerPosition] = useState({
    lat: -45.57524, 
    lng: -72.06619,
 });
 
 useEffect(() => {
    if (selectedLocation) {
      setMarkerPosition({
        lat: selectedLocation.lat,
        lng: selectedLocation.lng,
      });
    }
 }, [selectedLocation]);
    console.log(markerPosition.lat);
  if (loadError) return "Error";
  if (!isLoaded) return "Maps";

  return (
    <div style={{ marginTop: "10px"}}>
      <GoogleMap
        mapContainerStyle={{
          height: "400px",
        }}
        center={markerPosition}
        zoom={13}
        onLoad={onMapLoad}
      >
        <MarkerF
          position={markerPosition}
          draggable={true}
          onDragEnd={(e) => {
            const newPosition = e.latLng;
            setMarkerPosition(newPosition);
            onCoordenadasChange({ lat: newPosition.lat(), lng: newPosition.lng() });
          }}
          icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"}

        />
      </GoogleMap>
    </div>
  );
};

export default MapComponent;


