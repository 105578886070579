import React, { useState } from 'react';
import Swal from 'sweetalert2';
import '../estilos/tarjeta_servicio_a.css';

const ServiceCard = ({ name, description, unitCost, onHire, blocked }) => {
  const [days, setDays] = useState(1);

  const handleHire = () => {
    if (isNaN(unitCost) || unitCost <= 0 || isNaN(days) || days <= 0) {
      Swal.fire('Error', 'El costo unitario y la cantidad de días deben ser números válidos y positivos', 'error');
      return;
    }

    const CostoServicio = unitCost * days;

    Swal.fire({
      title: `¿Deseas contratar el servicio de ${name} por ${days} días con un costo total de $${CostoServicio}?`,
      showCancelButton: true,
      confirmButtonText: 'Sí, contratar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        onHire(CostoServicio, name, days);
        Swal.fire('Contratado!', 'El servicio ha sido agregado al carrito.', 'success');
      }
    });
  };

  const handleChangeDays = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setDays(value);
    } else {
      setDays(1);
    }
  };

  return (
    <div className={`card service-card mb-4 ${blocked ? 'blocked' : ''}`}>
      <div className='card-body'>
        <h4>{name}</h4>
        <p>{description}</p>
        <p>Costo: ${unitCost}</p>
        <label htmlFor="days">Contratar este servicio por:</label>
        <input className='form-control' type="number" id="days" value={days} onChange={handleChangeDays} min={1} />
        <button onClick={handleHire} className='btn btn-primary mt-2' disabled={blocked}>
          {blocked ? "Cotizado" : "Cotizar"}
        </button>
      </div>
    </div>
  );
};

export default ServiceCard;
