import React, { useState, useEffect } from "react";
import { collection, getDocs } from 'firebase/firestore';
import { db } from "../../firebaseConfig/firebase";
import ServiceCard from "./tarjeta_servicio_a";
import Vacantes, { mapeoTipoCamaAPersonas } from "./vacantes"; // Importar mapeoTipoCamaAPersonas
import CostoxNoche from "./costoNoche";
import '../estilos/cotizadorAlojamiento.css';
import { useTranslation } from "react-i18next";

const CotizadorAlojamiento = ({ onNextStep, id, ciudadCercana, totalPersonas, onDataFromChild }) => {
  const { t } = useTranslation();
  const [servAlojamiento, setServAlojamiento] = useState([]);
  const [searchTerm, setSearchTerm] = useState(ciudadCercana);
  const [costoTotal, setCostoTotal] = useState(0);
  const [nombreServicio, setNombreServicio] = useState('');
  const [cantidadPersonas, setCantidadPersonas] = useState(0);
  const [serviciosCotizados, setServiciosCotizados] = useState([]); // lista de servicios ya cotizados
  const [tipoServicioCotizado] = useState('alojamiento');

  const servAlojamientoCollection = collection(db, 'servicio_alojamiento');

  const getServAlojamiento = async () => {
    const data = await getDocs(servAlojamientoCollection);
    setServAlojamiento(
      data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
  };

  useEffect(() => {
    getServAlojamiento();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddService = (CostoServicio, NombreServicio, CantidadPersonas) => {
    setCostoTotal(prevTotal => prevTotal + CostoServicio);
    setNombreServicio(NombreServicio);
    setCantidadPersonas(CantidadPersonas);
    onDataFromChild({ tipoServicioCotizado, CostoServicio, NombreServicio, CantidadPersonas });

    // Agregar la tarjeta de servicio cotizada al estado serviciosCotizados
    setServiciosCotizados(prevServicios => [...prevServicios, { NombreServicio, CostoServicio, CantidadPersonas }]);
  };

  return (
    <div className='services-container'>
      <h3 className='pb-5'>{t('COTIZADOR_A.SERVICIO_DE_ALOJAMIENTO')}</h3>

      <div className='search-bar'>
        <input
          type='text'
          placeholder='Buscar por nombre, ciudad o RUT'
          className='form-control'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className='service-list'>
        {servAlojamiento
          .filter((item) =>
            item.empresa.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.rut.includes(searchTerm) ||
            item.ciudad.includes(searchTerm)
          )
          .filter(item => {
            const allFalse = Object.values(item.valor_noche).every(obj => obj.checked === false);
            return !allFalse;
          })
          .filter(item => {
            // Filtrar por vacantes y costo unitario
            const unitCost = CostoxNoche({ costo_noche: item.valor_noche });
            const totalVacancies = Object.entries(item.camas_tipo_cantidad)
              .filter(([tipo, info]) => info.checked && mapeoTipoCamaAPersonas[tipo] !== undefined)
              .reduce((total, [tipo, info]) => total + (info.cantidad * mapeoTipoCamaAPersonas[tipo]), 0);

            return totalVacancies >= totalPersonas && unitCost > 0;
          })
          .map((item) => {
            const unitCost = CostoxNoche({ costo_noche: item.valor_noche });
            const isCotizado = serviciosCotizados.some(serv => serv.NombreServicio === item.empresa); // Verificar si el servicio está cotizado
            const totalVacancies = Object.entries(item.camas_tipo_cantidad)
              .filter(([tipo, info]) => info.checked && mapeoTipoCamaAPersonas[tipo] !== undefined)
              .reduce((total, [tipo, info]) => total + (info.cantidad * mapeoTipoCamaAPersonas[tipo]), 0);

            return (
              <ServiceCard
                key={item.id}
                name={item.empresa}
                vacancies={totalVacancies}
                unitCost={unitCost}
                totalPersonas={totalPersonas}
                onHire={handleAddService}
                blocked={isCotizado} // Pasar el estado de bloqueo como prop
              />
            );
          })}
      </div>
      
    </div>
  );
};

export default CotizadorAlojamiento;
