import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from "../firebaseConfig/firebase";
import Tarjeta from "../componentes/componentes_paginas/tarjeta";
import BannerServices from "../componentes/componentes_paginas/bannerServices";
import LocacionSeleccionada from "./locacionSeleccionada"; // Importa tu componente LocacionSeleccionada
import { useTranslation } from 'react-i18next'; // Importa el hook de traducción

const Locaciones = () => {
  const { t } = useTranslation(); // Usa el hook de traducción
  const [locaciones, setLocaciones] = useState([]);
  const [locacionSeleccionadaId, setLocacionSeleccionadaId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCiudad, setSelectedCiudad] = useState('');
  const [selectedEcosistema, setSelectedEcosistema] = useState('');
  const locacionCollection = collection(db, 'locacion');

  const getLocaciones = async () => {
    try {
      const data = await getDocs(locacionCollection);
      setLocaciones(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    } catch (error) {
      console.error("Error al obtener locaciones:", error);
    }
  };

  useEffect(() => {
    getLocaciones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSeleccionarLocacion = (id) => {
    setLocacionSeleccionadaId(id);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleComunaChange = (event) => {
    setSelectedCiudad(event.target.value);
  };

  const handleEcosistemaChange = (event) => {
    setSelectedEcosistema(event.target.value);
  };

  const filteredLocaciones = locaciones.filter(locacion => {
    const matchesSearchTerm = locacion.nombre.toLowerCase().includes(searchTerm.toLowerCase()) || locacion.ciudadCercana.toLowerCase().includes(searchTerm.toLowerCase()) || locacion.tags.toLowerCase().includes(searchTerm.toLowerCase()) || locacion.ecosistema.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCiudad = selectedCiudad ? locacion.ciudadCercana === selectedCiudad : true;
    const matchesEcosistema = selectedEcosistema ? locacion.ecosistema === selectedEcosistema : true;
    return matchesSearchTerm && matchesCiudad && matchesEcosistema;
  });

  return (
    <div className="locaciones">
      <div className="container-fluid bg-white">
        <div className='container pt-5'>
          <div className='row pt-4'>
            <div className='col-12 py-4'>
              <h1 className='pt-5'>{t('LOCACIONES.TITULO')}</h1>
              <p className='lead'>{t('LOCACIONES.DESCRIPCION')}</p>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid bg-light py-5'>
        <div className='container'>
          <div className='row g-1'>
            <div className='col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 mb-sm-4'>
              <input 
                type='text' 
                placeholder={t('LOCACIONES.PLACEHOLDER_BUSCAR')} 
                className='form-control' 
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className='col-4 col-sm-4 col-md-3 col-lg-3 col-xl-2 mb-4'>
              <select 
                className='form-select' 
                aria-label={t('LOCACIONES.ARIA_LABEL')}
                value={selectedCiudad}
                onChange={handleComunaChange}
              >
                <option value=''>{t('LOCACIONES.SELECCIONAR_LOCALIDAD')}</option>
                <option value='coyhaique'>Coyhaique</option>
                <option value='aysen'>Puerto Aysen</option>
                <option value='chacabuco'>Puerto Chacabuco</option>
                <option value='puerto_cisnes'>Puerto Cisnes</option>
                <option value='melinka'>Melinka</option>
                <option value='puerto_aguirre'>Puerto Aguirre</option>
                <option value='chile_chico'>Chile Chico</option>
                <option value='guadal'>Puerto Guadal</option>
                <option value='puerto_betrand'>Puerto Bertrand</option>
                <option value='tranquilo'>Puerto Rio Tranquilo</option>
                <option value='bahia_murta'>Bahía Murta</option>
                <option value='puerto_sanchez'>Puerto Sanchez</option>
                <option value='cochrane'>Cochrane</option>
                <option value='tortel'>Tortel</option>
                <option value='villa_ohiggins'>Villa O'higgins</option>
                <option value='balmaceda'>Balmaceda</option>
                <option value='cerro_castillo'>Cerro Castillo</option>
                <option value='puerto_ibañez'>Puerto Ibañez</option>
                <option value='mañihuales'>Mañihuales</option>
                <option value='villa_amengual'>Villa Amengual</option>
                <option value='villa_ortega'>Villa Ortega</option>
                <option value='lago_verde'>Lago Verde</option>
                <option value='puyuhuapi'>Puyuhuapi</option>
                <option value='la_junta'>La Junta</option>
                <option value='raul_marin_balmaceda'>Raúl Marín Balmaceda</option>
                <option value='futaleufu'>Futaleufu</option>
                <option value='chaiten'>Chaiten</option>
              </select>
            </div>
            <div className='col-4 col-sm-4 col-md-3 col-lg-3 col-xl-2 mb-4'>
              <select 
                className='form-select' 
                aria-label='Default select example'
                value={selectedEcosistema}
                onChange={handleEcosistemaChange}
              >
                <option value=''>{t('LOCACIONES.SELECCIONAR_ECOSISTEMA')}</option>
                <option value='bosque'>{t('ECOSISTEMAS.BOSQUE')}</option>
                <option value='montaña'>{t('ECOSISTEMAS.MONTAÑA')}</option>
                <option value='fluvial'>{t('ECOSISTEMAS.FLUVIAL')}</option>
                <option value='estepa'>{t('ECOSISTEMAS.ESTEPA')}</option>
                <option value='urbano'>{t('ECOSISTEMAS.URBANO')}</option>
              </select>
            </div>
            <div className='col-4 col-sm-4 col-md-2 col-lg-2 offset-lg-1 col-xl-1 offset-xl-4 mb-4'>
              <button 
                type='button' 
                className='btn btn-dark rounded-pill w-100'
                onClick={() => {
                  setSearchTerm('');
                  setSelectedCiudad('');
                  setSelectedEcosistema('');
                }}
              >{t('LOCACIONES.BOTON_LIMPIAR')}</button>
            </div>
          </div>

          <div className="row">
            {filteredLocaciones.map(locacion => (
              <div key={locacion.id} className='col-md-6 col-lg-4'>
                <Link 
                  to={`/locacionSeleccionada/${locacion.id}`} 
                  onClick={() => handleSeleccionarLocacion(locacion.id)} 
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <Tarjeta 
                    imageSrc={locacion.portadaLoc} 
                    title={locacion.nombre} 
                    ruta={`/locacionSeleccionada/${locacion.id}`} 
                    tags={locacion.tags}
                  /> 
                  {console.log('LOCACION EN PANTALLA',locacion.ciudadCercana)}
                </Link>
              </div>
            ))}
          </div>
          {locacionSeleccionadaId && (
            <LocacionSeleccionada locacionId={locacionSeleccionadaId} />
          )}
        </div>
      </div>

      <BannerServices />
    </div>
  );
};

export default Locaciones;
