import React, { useState, useEffect } from "react";
import Carousel from 'react-bootstrap/Carousel';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import PantallaCarga from '../../paginas/pantallaCarga'; // importamos la pantalla de carga
import { useTranslation } from "react-i18next";

/** importo la base de datos para poder consultar valores reales */
import { doc, getDoc } from 'firebase/firestore';
import { db } from "../../firebaseConfig/firebase";


function CarouselLocations() {
  const { t } = useTranslation();
  const [locaciones, setLocaciones] = useState([]);
  const locacionIDs = ["2sn5T4SM7rxmyAteJNsW", "DzAAXQ5c3I53wYCfmdDL", "JqYzuTKQEXmu7ZSGCbzQ"]; // reemplazo con las IDs específicas 

  const getLocaciones = async () => {
    try {
      const locacionesData = await Promise.all(locacionIDs.map(async id => {
        const locacionDoc = await getDoc(doc(db, 'locacion', id));
        return { ...locacionDoc.data(), id: locacionDoc.id };
      }));
      setLocaciones(locacionesData);
    } catch (error) {
      console.error("Error al obtener locaciones:", error);
    }
  };

  useEffect(() => {
    getLocaciones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (locaciones.length === 0) {
    return ; //necesario para que carguen tags
  }

  return (
    <Carousel className="locations rounded-1" indicators={false}>
      {locaciones.map((locacion, index) => {
        const tagsString = locacion.tags ? locacion.tags : ''; // Si locacion es null, establece tagsString como una cadena vacía
        const tagsArray = tagsString.split(/[,#]/); // Usamos una expresión regular para dividir la cadena por comas o almohadillas
        const trimmedTags = tagsArray.map(tag => tag.trim()); // Eliminamos los espacios en blanco alrededor de cada tag
        const tagsElements = trimmedTags.filter(tag => tag !== "").map((tag, tagIndex) => (
          <Badge key={tagIndex} pill bg="secondary" className="p-2 text-dark">{tag}</Badge>
        ));

        return (
          <Carousel.Item key={locacion.id}>
            <img src={locacion.portadaLoc} alt={`shootinpatagonia_banner${index}`} />
            <Carousel.Caption>
              <div className="row">
                <div className="col-12 col-md-8">
                  <h4><strong>{locacion.nombre}</strong></h4>
                  <p className="text-truncate">{locacion.descripcion}</p>
                  <Stack direction="horizontal" gap={2}>
                    {tagsElements}
                  </Stack>
                </div>
                <div className="col-12 col-md-4">
                  <Button href={`/locacionSeleccionada/${locacion.id}`} variant="primary" className="rounded-pill px-4 text-uppercase float-md-end mt-4 mt-md-0">{t('CARRUSEL_LOCATIONS.VER DETALLES')}</Button>
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default CarouselLocations;
