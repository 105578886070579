import React, {useState,useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {collection, addDoc} from 'firebase/firestore';
import { db, subirArchivo6, subirArchivo7 } from "../firebaseConfig/firebase";
import MapComponent from './maapa';
//import SearchLocationInput from "./googlePlaces";
//import ReactDatePicker from 'react-datepicker';
import Swal from 'sweetalert2';
import { REACT_APP_GOOGLE_MAPS_KEY, /*REACT_APP_OPENWEATHERMAP_KEY*/ } from "../contents/contents";
import "./estilos/crear.css";


/** INTEGRAR LOS SIGUIENTES ITEMS A ESTA SECCION 
 * 1.- API DE MAPAS *
 * 2.- ZONA URBANA MAS CERCANA A LA LOCACIÓN *
 * 3.- INTEGRAR INFORMACION SOBRE DIRECCION DEL VIENTO SEGUN DETERMINADA EPOCA 
 * 4.- SETEAR VALORES ESTATICOS PARA LOS DATOS CLIMATICOS 
*/

const Crear = () => {

  /**---------------------------- MANEJO DE COORDENADAS ----------------------------------- */
  const [selectedLocation,setSelectedLocation] = useState({
    lat: -45.57524,
    lng: -72.06619,
  });
  const handleCoordenadasChange = (nuevasCoordenadas) => {
    setSelectedLocation(nuevasCoordenadas);
  };

  /**---------------------------- MANEJO DE API CLIMATICA ---------------------------------- */

  /* const latitudClima = selectedLocation.lat; // Ejemplo de latitud
  const longitudClima = selectedLocation.lng; // Ejemplo de longitud
  const apiKey = REACT_APP_OPENWEATHERMAP_KEY; // Reemplaza con tu API Key de OpenWeatherMap

  fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${latitudClima}&lon=${longitudClima}&appid=${apiKey}`)
  .then(response => {
    if (!response.ok) {
      throw new Error('ERROR EN LA RESPUESTA DEL SERVIDOR');
    }
    return response.json();
  })
  .then(data => {
    console.log('Datos del clima:', data);
    // Aquí puedes trabajar con los datos obtenidos
  })
  .catch(error => {
    console.error('Error al obtener datos climáticos:', error);
  });
 */

  /**---------------------------- LOADING Y PAGINAS --------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // aqui seteo las paginas para mejorar la navegacion

  //seteo de datos DESCIPTIVOS para la locación
  const [nombre, setNombre] = useState('');
  const [ecosistema, setEcosistema] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [ciudadCercana, setCiudadCercana] = useState('');
  const [portada, setPortada] = useState(null);
  const [verPortada, setVerPortada] = useState('')
  const [imagen, setImagen] = useState(null);
  const [verImagenes, setVerImagenes] = useState([]);

  const localidadesCoordenadas = {
    coyhaique: { lat: -45.57524,lng: -72.06619, },
    aysen: { lat: -45.40212,lng: -72.70862, },
    chacabuco: { lat: -45.463202,lng: -72.8207699, }, 
    puerto_cisnes: { lat: -44.730227,lng: -72.6821499, }, 
    melinka: { lat: -43.8980488,lng: -73.7463095, }, 
    puerto_aguirre: { lat: -45.1634351,lng: -73.5265666, },
    chile_chico: { lat: -46.5422584,lng: -71.7336613, }, 
    guadal: { lat:  -46.845945,lng: -72.704327, },
    puerto_betrand: { lat: -47.016641,lng: -72.8325369, },   
    tranquilo: { lat:-46.624713,lng: -72.6774529, },  
    bahia_murta: { lat: -46.456445,lng:  -72.6755059, }, 
    puerto_sanchez: { lat: -46.591922,lng:  -72.5818709, }, 
    cochrane: { lat: -47.254045,lng: -72.573327, },  
    tortel: { lat: -47.7987251,lng: -73.5385767, },  
    villa_ohiggins: { lat: -48.466645,lng: -72.5616019, },  
    balmaceda: { lat: -45.909574,lng: -71.6995089, },  
    cerro_castillo: { lat: -46.121351,lng: -72.1641619, }, 
    puerto_ibañez: { lat: -46.2863799,lng: -71.958547, },  
    mañihuales: { lat: -45.173254,lng: -72.1496349, },  
    villa_amengual: { lat: -44.748245,lng: -72.2128219, },  
    villa_ortega: { lat: -45.37196,lng: -71.9974149, },  
    lago_verde: { lat: -44.240639,lng: -71.850283, }, 
    puyuhuapi: { lat: -44.3227962,lng:-72.5600119, },  
    la_junta: { lat: -43.9723415,lng:-72.4133295, },  
    raul_marin_balmaceda: { lat: -43.775755,lng: -72.9572549, },  
    futaleufu: { lat:  -43.186005,lng: -71.8692559, }, 
    chaiten: { lat: -42.9168486,lng: -72.7144013, }, 
  };

  const [setLongitud] = useState(selectedLocation.lng);
  const [setLatitud] = useState(selectedLocation.lat);
  const [tags, setTags] = useState('');
  const [administrador, setAdministrador] = useState('');
  const [email, setEmail] = useState('');
  const [fono, setFono] = useState('');
  const [puplica_privada, setPublica_privada] = useState('');
  const [permiso, setPermiso] = useState(false);
  const [permiso_coste, setPermiso_coste] = useState('');

  //seteo de datos CAMINO, TERMINALES Y DISTANCIAS para la locaicón

  const [estacionamiento, setEstacionamiento] = useState(false);
  const [esta_cap,setEsta_cap] = useState('');
  const [esta_distancia, setEsta_distancia] = useState('');
  const [kilometrosAsfalto, setKilometrosAsfalto] = useState('');
  const [kilometrosRipio, setKilometrosRipio] = useState('');
  const estadosCamino = ['Buen estado', 'Mal estado', 'Regular'];
  const [estadoCaminoAsfalto, setEstadoCaminoAsfalto] = useState('');
  const [estadoCaminoRipio, setEstadoCaminoRipio] = useState('');
  const [santiago, setSantiago] = useState('');
  const [aeropuerto, setAeropuerto] = useState('');
  const [aero_distancia, setAero_distancia] = useState('');
  const [aerodromo, setAerodromo] = useState('');
  const [aerod_distancia, setAerod_distancia] = useState('');

  //seteo de datos de SERVICIOS BÁSICOS Y RED
  const [agua, setAgua] = useState(false);
  const [electricidad, setElectricidad] = useState(false);
  const [refugio, setRefugio] = useState(false);
  const [base_camp, setBase_camp] = useState(false);
  const [bodega, setBodega] = useState(false);
  const [internet, setInternet] = useState(false);
  const [movil, setMovil] = useState(false);
  const [satelite, setSatelite] = useState(false);

  //seteo de datos CLIMATICOS
  //const [selectedField, setSelectedField] = useState('');
  const [datosClimaticos, setDatosClimaticos] = useState({});
  /* const [climaticData, setClimaticData] = useState({
    meses_mas_luz: '12',
    meses_menos_luz: '06',
    mesees_mas_nieve: '08',
    meses_menos_nieve: '01',
    meses_mas_viento: '09',
    meses_menos_viento: '06',
    meses_mas_lluvia: '06',
    meses_menos_lluvia: '01',
    // ... otros campos climáticos con valores iniciales null ...
  }); */
  const months = [
    'ENERO',
    'FEBRERO',
    'MARZO',
    'ABRIL',
    'MAYO',
    'JUNIO',
    'JULIO',
    'AGOSTO',
    'SEPTIEMBRE',
    'OCTUBRE',
    'NOVIEMBRE',
    'DICIEMBRE'
  ];
  const climaticOptions = [
    { label:'MESES CON MÁS LUZ + ', icon: <i class="fa-regular fa-sun"></i>},
    { label:'MESES CON MENOS LUZ - ', icon: <i class="fa-regular fa-sun"></i>},
    { label:'MESES CON MÁS NIEVE + ', icon: <i class="fa-solid fa-snowflake"></i>},
    { label:'MESES CON MENOS NIEVE - ', icon: <i class="fa-solid fa-snowflake"></i>},
    { label: 'MESES CON MÁS VIENTO + ', icon: <i className="fas fa-wind"></i> },
    { label: 'MESES CON MENOS VIENTO - ', icon: <i className="fas fa-wind"></i> },
    { label: 'MESES CON MÁS LLUVIAS + ', icon: <i class="fa-solid fa-cloud-showers-heavy"></i> },
    { label: 'MESES CON MENOS LLUVIAS - ', icon: <i class="fa-solid fa-cloud-showers-heavy"></i> },
    // ... otros campos climáticos ...
  ];
  
  const [temp_agua, setTemp_agua] = useState('');

  //seteo de datos VESTUARIO
  const [vestuario_verano, setVestuario_verano] = useState('');
  const [vestuario_otoño, setVestuario_otoño] = useState('');
  const [vestuario_invierno, setVestuario_invierno] = useState('');
  const [vestuario_primavera, setVestuario_primavera] = useState('');

  const navigate = useNavigate();

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const previousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
  
  /* const handleDateChange = (field, date) => {
    setClimaticData((prevData) => ({
      ...prevData,
      [field]: date,
    }));
  };
 */
  //-------------- HANDLER PARA MANEJO DE ESTADO DE LOS CAMINOS∫
  const handleEstadoAsfaltoChange = (e) => {
    setEstadoCaminoAsfalto(e.target.value);
  };

  const handleEstadoRipioChange = (e) => {
    setEstadoCaminoRipio(e.target.value);
  };

  //-------- HANDLERS PARA MANEJO DE IMAGENES Y RENDERIZADO DE PREVIZUALIZACION --------
  const handlePortadaChange = (e) => {
    const file = e.target.files[0];
    setPortada(e.target.files)
    if (file) {
      setVerPortada(file ? URL.createObjectURL(file) : null);
    }
  };

  const handleImagenesChange = (e) => {
    const files = e.target.files;
    setImagen(e.target.files)
    const imagenesArray = [];

    if (files) {
      for (const file of files) {
        imagenesArray.push(URL.createObjectURL(file));
      }
      setVerImagenes([...imagenesArray]);
    }
  };

  //------------------- HANDLER PARA EL MANEJO DE COORDENADAS SEGUN LOCALIDAD CERCANA 

  const handleLocalidadChange = (e) => {
    const localidadSeleccionada = e.target.value;
    setCiudadCercana(e.target.value)
    const coordenadas = localidadesCoordenadas[localidadSeleccionada];
    if (coordenadas) {
      setSelectedLocation({
        lat: coordenadas.lat,
        lng: coordenadas.lng,
      });
    }
  };
// ----------------------- SETEO DATOS CLIMATICOS POR MESES CHECKBOX ------------

  // Función para manejar el cambio en los meses seleccionados
  const handleCheckboxChange = (optionLabel, month, isChecked) => {
    setDatosClimaticos((prevData) => {
      const newData = { ...prevData };
      // Verificar si ya existe el objeto para el optionLabel actual
      if (!newData[optionLabel]) {
        newData[optionLabel] = { meses: [] };
      }
      // Actualizar los meses seleccionados
      const index = newData[optionLabel].meses.indexOf(month);
      if (isChecked && index === -1) {
        newData[optionLabel].meses.push(month);
      } else if (!isChecked && index !== -1) {
        newData[optionLabel].meses.splice(index, 1);
      }
      return newData;
    });
  };


  //---------------------- SETEO DE RECOMENDACIONES DE VESTUARIO ---------------

  useEffect(() => {
    // Establecer los valores predeterminados utilizando los textos importados
    setVestuario_verano('<h5>VERANO</h5><p><strong>Capas Ligeras y Transpirables</strong>: Dado que el clima puede variar, es aconsejable vestirse en capas. Usa prendas ligeras y transpirables como base y añade capas según sea necesario.</p><p><strong>Ropa Impermeable y Cortaviento</strong>: Aysén es conocido por su clima impredecible, con posibilidad de lluvias incluso en verano. Lleva un impermeable o una chaqueta cortaviento resistente al agua.</p><p><strong>Calzado Cómodo y Resistente</strong>: Si planeas hacer senderismo o explorar la naturaleza, lleva calzado adecuado para caminatas. Las botas de senderismo son ideales Ropa Térmica para las Noches: Las noches pueden ser frías, incluso en verano, así que considera llevar alguna ropa térmica para estar cómodo durante la noche.</p><p><strong>Protección Solar</strong>: A pesar de ser el sur, el sol puede ser fuerte, especialmente en el agua o en zonas nevadas. Incluye gafas de sol, sombrero y protector solar.</p>');
    setVestuario_otoño('<h5>OTOÑO</h5><p><strong>Vestimenta en Capas</strong>: El clima puede cambiar rápidamente, por lo que vestirse en capas es esencial. Comienza con una capa base térmica, añade una capa intermedia para aislamiento (como un forro polar o un suéter), y termina con una capa exterior impermeable y cortaviento.</p><p><strong>Chaqueta Impermeable y Cortaviento</strong>: Una buena chaqueta impermeable y cortaviento es imprescindible, ya que las lluvias son frecuentes en otoño.</p><p><strong>Pantalones Resistentes al Agua</strong>: Considera pantalones que sean resistentes al agua o que se sequen rápidamente para mantenerte cómodo durante las actividades al aire libre.</p><p><strong>Calzado Impermeable</strong>: Las botas de senderismo impermeables son ideales para grabaciones.</p><p><strong>Ropa Térmica</strong>: Las temperaturas pueden descender, especialmente en las mañanas y noches, por lo que la ropa térmica puede ser muy útil.</p><p><strong>Accesorios para el Frío</strong>: No olvides gorros, guantes y bufandas para mantenerte abrigado, especialmente si vas a estar al aire libre durante períodos prolongados.</p><p><strong>Protección Solar</strong>: Aunque es otoño, la protección solar sigue siendo importante. Incluye un sombrero y protector solar, especialmente si vas a estar al aire libre durante el día.</p><p><strong>Ropa de Cambio</strong>: Debido a la posibilidad de lluvia y humedad, es una buena idea llevar ropa de cambio en caso de que te mojes.Mochila Resistente al Agua: Para tus excursiones y actividades al aire libre, lleva una mochila resistente al agua para proteger tus pertenencias.</p>');
    setVestuario_invierno('<h5>INVIERNO</h5><p><strong>Ropa Térmica de Base</strong>: Comienza con una capa base térmica que pueda mantener el calor corporal y al mismo tiempo permita la transpiración. Materiales como la lana merino o las fibras sintéticas son buenos para esto.</p><p><strong>Capas Aislantes</strong>: Sobre tu capa base, añade una o más capas aislantes para retener el calor. Los forros polares, los suéteres de lana, y los chalecos aislantes son buenas opciones.</p><p><strong>Chaqueta Exterior Resistente al Agua y al Viento</strong>: Una chaqueta impermeable y cortaviento es crucial. Opta por una chaqueta de invierno de buena calidad que ofrezca aislamiento y protección contra la nieve y la lluvia.</p><p><strong>Pantalones Impermeables</strong>: Usa pantalones impermeables, especialmente si planeas actividades al aire libre como senderismo o exploración en la nieve.</p><p><strong>Calzado Apropiado para el Invierno</strong>: Elige botas impermeables y aisladas que te proporcionen tracción y calor. Asegúrate de que sean cómodas para caminar largas distancias si es necesario.</p><p><strong>Gorros, Guantes y Bufandas</strong>: Estos accesorios son esenciales para proteger las partes más expuestas de tu cuerpo. Los gorros deben cubrir las orejas y los guantes deben ser impermeables si vas a estar en contacto con la nieve.</p><p><strong>Calcetines de Lana o Térmicos</strong>: Usa calcetines que mantengan tus pies calientes y secos.</p><p><strong>Protección para los Ojos</strong>: Las gafas de sol son importantes para proteger tus ojos del reflejo de la nieve y del sol brillante en los días claros.</p><p><strong>Mochila Resistente al Agua</strong>: Para llevar tus pertenencias, opta por una mochila resistente al agua.</p><p><strong>Ropa de Cambio</strong>: Lleva ropa de cambio en caso de que te mojes, especialmente calcetines y una capa base adicional.</p>');
    setVestuario_primavera('<h5>PRIMAVERA</h5><p><strong>Vestimenta en Capas</strong>: El clima puede ser impredecible, con posibles cambios rápidos en la temperatura. Usa capas ligeras y transpirables que puedas añadir o quitar fácilmente. Una capa base cómoda, seguida de una capa intermedia como un suéter o forro polar, y una capa exterior impermeable es una buena combinación. </p><p><strong>Chaqueta Impermeable y Cortaviento</strong>: Las lluvias son comunes en la región, por lo que una chaqueta impermeable y cortaviento es imprescindible. Asegúrate de que sea lo suficientemente ligera para no sobrecalentarte durante las caminatas. </p><p><strong>Calzado Cómodo y Resistente</strong>: Elige calzado adecuado para actividades al aire libre, preferentemente impermeable, en caso de lluvia o senderos húmedos. </p><p><strong>Pantalones Versátiles</strong>: Los pantalones que se secan rápido o los pantalones convertibles (que se pueden convertir en shorts) son útiles para adaptarse a las variaciones de temperatura. </p><p><strong>Protección Solar</strong>: Aunque no sea verano, el sol puede ser fuerte, especialmente en las áreas abiertas y cerca del agua. Incluye gafas de sol, un sombrero de ala ancha y protector solar. </p><p><strong>Ropa para Actividades Específicas</strong>: Si planeas realizar actividades como pesca, trekking o paseos en bote, asegúrate de llevar ropa adecuada para estas. </p><p><strong>Ropa de Cambio Adicional</strong>: Debido a la posibilidad de mojarte por la lluvia o actividades al aire libre, lleva ropa de cambio. </p><p><strong>Accesorios Prácticos</strong>: Incluye una mochila para excursiones diarias, una botella de agua reutilizable y, dependiendo de tus planes, puede que necesites un par de binoculares o una cámara. </p>');
  }, []);

  const locacionesCollection = collection(db, 'locacion');
  const store = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try{

      const camposAValidar = [
        { campo: nombre, nombreCampo: 'nombre' },
        { campo: descripcion, nombreCampo: 'Descripción' },
        { campo: ecosistema, nombreCampo: 'ecosistema' },
        // Agrega más campos aquí
      ];

      for (const campoInfo of camposAValidar) {
        if (!campoInfo.campo || campoInfo.campo.trim() === "" ) {
            throw Swal.fire(`El campo ${campoInfo.nombreCampo} no puede estar vacío o debe seleccionar al menos un valor`);
        }
      }
      const resultado = await subirArchivo6(imagen); //aqui se suben las imagenes de la locación
      const resultado2 = await subirArchivo7(portada); //aqui se suben la imagen principal de la locación
      console.log(resultado)
      await addDoc (locacionesCollection, {

      //almacenamiento de datos DESCRIPTIVOS 
      nombre:nombre,
      ecosistema:ecosistema,
      descripcion:descripcion,
      portadaLoc:resultado2,
      imagenes:resultado,
      ciudadCercana:ciudadCercana,
      latitud:selectedLocation.lat,
      longitud:selectedLocation.lng,
      tags:tags,
      administrador:administrador,
      email:email,
      fono:fono,
      puplica_privada:puplica_privada,
      permiso:permiso,
      permiso_coste:permiso_coste,

      //almacenamiento de datos CAMINO, TERMINALES Y DISTANCIAS

      kilometrosAsfalto:kilometrosAsfalto,
      kilometrosRipio:kilometrosRipio,
      estadoCaminoAsfalto:estadoCaminoAsfalto,
      estadoCaminoRipio:estadoCaminoRipio,
      estacionamiento:estacionamiento,
      esta_cap:esta_cap,
      esta_distancia:esta_distancia,
      santiago:santiago,
      aeropuerto:aeropuerto,
      aero_distancia:aero_distancia,
      aerodromo:aerodromo,
      aerod_distancia:aerod_distancia,

      //almacenamiento de datos SERVICIOS Y RED
      agua:agua,
      electricidad:electricidad,
      refugio:refugio,
      base_camp:base_camp,
      bodega:bodega,
      internet:internet,
      movil:movil,
      satelite:satelite,

      //almacenamiento de datos CLIMATICOS
      datosClimaticos:datosClimaticos,
      temp_agua:temp_agua,

      //almacenamiento de datos VESTUARIO 
      vestuario_verano:vestuario_verano,
      vestuario_otoño:vestuario_otoño,
      vestuario_invierno:vestuario_invierno,
      vestuario_primavera:vestuario_primavera

    })
    Swal.fire('Se Agrego una Nueva Locación')
    setIsLoading(false);
    navigate('/ver');
    }catch (error){
      console.error(error);
      alert('fallo al subir imagen , intente nuevamente ');
    }
    if (!window.google) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
     }

  }

  return(
    <div className="container">
      <div className='row pt-5'>
        	<div className='col-12 py-5'>
                <h1 className='pt-5'>Crear <strong>locación</strong></h1>
            </div>
        </div>
      {currentPage === 1 && (
        <div className="row">
          {/** DATOS DESCRIPTIVOS DE LA LOCACION */}
          <div className='mb-3'>
              <label className='form-label'>NOMBRE DE LA LOCACIÓN</label>
                <input
                  value={nombre}
                  onChange={ (e) => setNombre(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'> TIPO DE ECOSISTEMA DE LA LOCACIÓN</label>
                <select value={ecosistema} onChange={(e) => setEcosistema(e.target.value)} className='form-select' aria-label='Default select example'>
                  <option selected>tipos de ecosistemas</option>
                  <option value='montaña'>Montaña</option>
                  <option value='fluvial'>Rio-Lago-Mar</option>
                  <option value='estepa'>Estepa</option>
                  <option value='bosque'>Bosque</option>
                  <option value='urbano'>Urbano</option>

                  {/**COMENTARIO : DE MOMENTO NO HAY LOCAICONES NO NATURALES, AQUI SE PODRIA DEFINIR PARAMETROS PARA ESTE TIPO Y SE PODRIAN LISTAR */}
                </select>
            </div>
            <div className='mb-3'>
              <label className='form-label'>DESCRIPCIÓN</label>
                <textarea
                  value={descripcion}
                  onChange={ (e) => setDescripcion(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>

            {/** SECCIÓN DEDICADA AL SETEO DE IMAGENES DE PORTADA Y DE GALERIA 
             */}
            <div className='mb-3'>
              <label className='form-label'>FOTOGRAFÍAS DE PORTADA</label>
              <input
                onChange={handlePortadaChange}
                type='file'
                className='form-control'
              />
              {verPortada && <img src={verPortada} alt='Portada' className='imagen-preview-portada' />}
            </div>

            <div className='mb-3'>
              <label className='form-label'>OTRAS FOTOGRAFÍAS DE LA LOCACIÓN</label>
              <input
                onChange={handleImagenesChange}
                type='file'
                className='form-control'
                multiple
              />
              {verImagenes.map((imagen, index) => (
              <img key={index} src={imagen} alt={`Imagen ${index + 1}`} className='imagen-preview' />
              ))}
            </div>

            <div className='mb-3'>
              <label className='form-label'> LOCALIDAD MÁS CERCANA </label>
              <select 
                value={ciudadCercana} 
                onChange={handleLocalidadChange} 
                className='form-select' 
                aria-label='localidades'
              >
                <option value='' disabled>Seleccionar...</option>
                <option value='coyhaique'>Coyhaique</option>
                <option value='aysen'>Puerto Aysen</option>
                <option value='chacabuco'>Puerto Chacabuco</option>
                <option value='puerto_cisnes'>Puerto Cisnes</option>
                <option value='melinka'>Melinka</option>
                <option value='puerto_aguirre'>Puerto Aguirre</option>
                <option value='chile_chico'>Chile Chico</option>
                <option value='guadal'>Puerto Guadal</option>
                <option value='puerto_betrand'>Puerto Bertrand</option>
                <option value='tranquilo'>Puerto Rio Tranquilo</option>
                <option value='bahia_murta'>Bahía Murta</option>
                <option value='puerto_sanchez'>Puerto Sanchez</option>
                <option value='cochrane'>Cochrane</option>
                <option value='tortel'>Tortel</option>
                <option value='villa_ohiggins'>Villar O'higgins</option>
                <option value='balmaceda'>Balmaceda</option>
                <option value='cerro_castillo'>Cerro_castillo</option>
                <option value='puerto_ibañez'>Puerto Ibañez</option>
                <option value='mañihuales'>Mañihuales</option>
                <option value='villa_amengual'>Villa Amengual</option>
                <option value='villa_ortega'>Villa Ortega</option>
                <option value='lago_verde'>Lago Verde</option>
                <option value='puyuhuapi'>Puyuhuapi</option>
                <option value='la_junta'>La Junta</option>
                <option value='raul_marin_balmaceda'>Raúl Marín Balmaceda</option>
                <option value='futaleufu'>Futaleufu</option>
                <option value='chaiten'>Chaiten</option>
              </select>
            </div>

            <div className=" mt-4 mb-4 d-flex">
              <div className="w-50 me-3">
                <div style={{ height: "100%", width: "100%" }}>
                    <MapComponent
                      selectedLocation={selectedLocation}
                      setSelectedLocation={setSelectedLocation}
                      onCoordenadasChange={handleCoordenadasChange}
                    />
                </div>
              </div>

              <div className="w-50">
                <div className="mt-3 mb-3">
                  <label className='form-label'>COORDENADAS DE LONGITUD</label>
                  <input
                    value={selectedLocation.lng}
                    onChange={(e) => setLongitud(e.target.value)}
                    type='text'
                    className='form-control'
                    readOnly // Agrega el atributo readOnly aquí
                  />
                </div>
                <div className="mt-3 mb-3">
                  <label className='form-label'>COORDENADAS DE LATITUD</label>
                  <input
                    value={selectedLocation.lat}
                    onChange={(e) => setLatitud(e.target.value)}
                    type='text'
                    className='form-control'
                    readOnly // Agrega el atributo readOnly aquí
                  />
                </div>
                <div className="mt-5">
                  <p className="text-muted">Para setear coordenadas apropiadamente primero selecciones un pueblo o cuidad mas cercana a la locación</p>
                  <p className="text-muted">Luego arrastre el ping selector {<img src="http://maps.google.com/mapfiles/ms/icons/green-dot.png" alt="Icono" />} hasta la ubicación desdeada</p>
                </div>
              </div> 
            </div>
            <div className='mb-3'>
              <label className='form-label'>TAGS</label>
                <input
                  value={tags}
                  onChange={ (e) => setTags(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>ADMINISTRADOR DE LA LOCACIÓN </label>
                <input
                  value={administrador}
                  onChange={ (e) => setAdministrador(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>E-MAIL</label>
                <input
                  value={email}
                  onChange={ (e) => setEmail(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>FONO</label>
                <input
                  value={fono}
                  onChange={ (e) => setFono(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>CARACTER DE LA LOCACIÓN</label>
                <select value={puplica_privada} onChange={(e) => setPublica_privada(e.target.value)} className='form-select' aria-label='Default select example'>
                  <option selected>¿La locación es pública o privada?</option>
                  <option value='pública'>Pública</option>
                  <option value='privada'>Privada</option>
                  <option value='pública-privada'>Pública/Privada</option>
                </select>
            </div>
            <div className='form-check form-switch'>
              <label class="form-check-label" for="flexSwitchCheckDefault">¿REQUIERE PERMISIS DE LOCACIÓN?</label>
                <input
                  checked={permiso}
                  onChange={ (e) => setPermiso(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>COSTE DEL PERMISO DE LOCACIÓN</label>
                <input
                  value={permiso_coste}
                  onChange={ (e) => setPermiso_coste(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
        </div>
      )}

      {currentPage === 2 && (
        <div className="row">
         {/** DATOS DE CAMINO, TERMINALES Y DISTANCIAS */}
            <div className='mb-3'>
              <label className='form-label'>DISTANCIA Y ESTADO DEL CAMINO HASTA LA LOCACIÓN </label>
              <div className='mb-3'>
                <label className='form-label'>KILÓMETROS DE CAMINO ASFALTADO</label>
                <div className='input-group'>
                  <input
                    value={kilometrosAsfalto}
                    onChange={(e) => setKilometrosAsfalto(e.target.value)}
                    type='text'
                    className='form-control'
                  />
                  <select
                    className='form-select'
                    onChange={handleEstadoAsfaltoChange}
                    value={estadoCaminoAsfalto}
                    >
                    <option value=''>Selecciona un estado</option>
                    {estadosCamino.map((estado) => (
                      <option key={estado} value={estado}>
                      {estado}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className='mb-3'>
                <label className='form-label'>KILÓMETROS DE CAMINO DE RIPIO</label>
                <div className='input-group'>
                  <input
                    value={kilometrosRipio}
                    onChange={(e) => setKilometrosRipio(e.target.value)}
                    type='text'
                    className='form-control'
                  />
                  <select
                    className='form-select'
                    onChange={handleEstadoRipioChange}
                    value={estadoCaminoRipio}
                  >
                    <option value=''>Selecciona un estado</option>
                    {estadosCamino.map((estado) => (
                      <option key={estado} value={estado}>
                        {estado}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className='form-check form-switch'>
              <label class="form-check-label" for="flexSwitchCheckDefault">¿HAY ESTACIONAMIENTO?
                <input
                  checked={estacionamiento}
                  onChange={ (e) => setEstacionamiento(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
              </label>
            </div>
            <div className='mb-3'>
              <label className='form-label'>CAPACIDAD DEL ESTACIONAMIENTO</label>
                <input
                  value={esta_cap}
                  onChange={ (e) => setEsta_cap(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>DISTANCIA ENTRE EL ESTACIONAMIENTO Y EL PUNTO DE LOCACIÓN</label>
                <input
                  value={esta_distancia}
                  onChange={ (e) => setEsta_distancia(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>DISTANCIA DESDE SANTIAGO</label>
                <input
                  value={santiago}
                  onChange={ (e) => setSantiago(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>AEROPUERTO MÁS CERCANO</label>
                <input
                  value={aeropuerto}
                  onChange={ (e) => setAeropuerto(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>DISTANCIA DESDE EL AEROPUERTO HASTA LA LOCACIÓN</label>
                <input
                  value={aero_distancia}
                  onChange={ (e) => setAero_distancia(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>AERODROMO MÁS CERCANO</label>
                <input
                  value={aerodromo}
                  onChange={ (e) => setAerodromo(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>DISTANCIA DESDE EL AERODROMO HASTA LA LOCACIÓN</label>
                <input
                  value={aerod_distancia}
                  onChange={ (e) => setAerod_distancia(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            
            {/** DATOS DE SERVICIOS Y RED */}
            <div className='form-check form-switch'>
              <label class="form-check-label" for="flexSwitchCheckDefault">¿ACCESO A AGUA POTABLE?</label>
                <input
                  checked={agua}
                  onChange={ (e) => setAgua(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
            </div>
            <div className='form-check form-switch'>
              <label class="form-check-label" for="flexSwitchCheckDefault">¿ACCESO A SERVICIO ELÉCTRICO?</label>
                <input
                  checked={electricidad}
                  onChange={ (e) => setElectricidad(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
            </div>
            <div className='form-check form-switch'>
              <label class='form-check-label' for='flexSwitchCheckDefault'>¿CUENTA CON REFUGIO?</label>
                <input
                  checked={refugio}
                  onChange={ (e) => setRefugio(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
            </div>
            <div className='form-check form-switch'>
              <label class='form-check-label' for='flexSwitchCheckDefault'>¿FACTIBILIDAD PARA BASE CAMP?</label>
                <input
                  checked={base_camp}
                  onChange={ (e) => setBase_camp(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
            </div>
            <div className='form-check form-switch'>
              <label class='form-check-label' for='flexSwitchCheckDefault'>¿CUENTA CON BODEGA?</label>
                <input
                  checked={bodega}
                  onChange={ (e) => setBodega(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
            </div>
            <div className='form-check form-switch'>
              <label class='form-check-label' for='flexSwitchCheckDefault'>¿HAY ACCESO A INTERNET EN LA LOCACIÓN?</label>
                <input
                  checked={internet}
                  onChange={ (e) => setInternet(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
            </div>
            <div className='form-check form-switch'>
              <label className='form-check-label' for='flexSwitchCheckDefault'>¿HAY SEÑAL CELULAR?</label>
                <input
                  checked={movil}
                  onChange={ (e) => setMovil(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
            </div>
            <div className='form-check form-switch'>
              <label className='form-check-label' for='flexSwitchCheckDefault'>¿HAY COBERTURA SATELITAL?</label>
                <input
                  checked={satelite}
                  onChange={ (e) => setSatelite(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
            </div>
        </div>
      )}

      {currentPage === 3 && (
        <div className="row">
          {/** DATOS CLIMATICOS */}
          <div className='mb-4'><h3>DATOS CLIMATICOS</h3></div>
          <div>
            {climaticOptions.reduce((result, option, index) => {
              if (index % 2 === 0) {
                const nextOption = climaticOptions[index + 1];
                result.push(
                  <div key={index} style={{ display: 'flex', marginBottom: '20px' }}>
                    <div style={{ marginRight: '20px', border: '1px solid black', padding: '10px' }}>
                      <h6>{option.label}{option.icon}</h6>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {months.map((month, monthIndex) => (
                          <div key={monthIndex} style={{ marginRight: '10px', marginBottom: '10px' }}>
                            <input
                              type='checkbox'
                              id={`${option.label}-${month}`}
                              value={month}
                              onChange={(e) => handleCheckboxChange(option.label, e.target.value, e.target.checked)}
                            />
                            <label htmlFor={`${option.label}-${month}`}>{month}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {/* Renderizar el siguiente option solo si existe */}
                    {nextOption && (
                      <div style={{ marginRight: '20px', border: '1px solid black', padding: '10px' }}>
                        <h6>{nextOption.label}{nextOption.icon}</h6>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {months.map((month, monthIndex) => (
                            <div key={monthIndex} style={{ marginRight: '10px', marginBottom: '10px' }}>
                              <input
                                type='checkbox'
                                id={`${nextOption.label}-${month}`}
                                value={month}
                                onChange={(e) => handleCheckboxChange(nextOption.label, e.target.value, e.target.checked)}
                              />
                              <label htmlFor={`${nextOption.label}-${month}`}>{month}</label>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                );
              }
              return result;
            }, [])}
          </div>

            <div className='mb-3'>
              <label className='form-label'>TEMPERATURA DEL AGUA : </label>
                <input
                  value={temp_agua}
                  onChange={ (e) => setTemp_agua(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>

            {/**DATOS DE VESTUARIO SEGUN LA TEMPORADA DE LA LOCACION */}
            <div className='mb-3'>
              <label className='form-label'>RECOMENDACIONES DE VESTUARIO EN VERANO</label>
                <textarea
                  value={vestuario_verano}
                  onChange={ (e) => setVestuario_verano(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>RECOMENDACIONES DE VESTUARIO EN OTOÑO</label>
                <textarea
                  value={vestuario_otoño}
                  onChange={ (e) => setVestuario_otoño(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>RECOMENDACIONES DE VESTUARIO EN INVIERNO</label>
                <textarea
                  value={vestuario_invierno}
                  onChange={ (e) => setVestuario_invierno(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>RECOMENDACIONES DE VESTUARIO EN PRIMAVERA</label>
                <textarea
                  value={vestuario_primavera}
                  onChange={ (e) => setVestuario_primavera(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
        </div>
      )}

      {currentPage === 1 && (
        <button onClick={nextPage} className="btn btn-primary">
          SIGUIENTE
        </button>
      )}

      {currentPage > 1 && currentPage < 3 && (
        <>
          <button onClick={previousPage} className="btn btn-primary">
            ANTERIOR
          </button>
          <button onClick={nextPage} className="btn btn-primary">
            SIGUIENTE
          </button>
        </>
      )}

      {currentPage === 3 && (
        <>
          <button onClick={previousPage} className="btn btn-primary">
            ANTERIOR
          </button>
          {isLoading ? (
            <button disabled className="btn btn-primary">
              CARGANDO...
            </button>
            ) : (
            <button onClick={store} className="btn btn-primary">
              CREAR
            </button>
          )}
        </>
      )}
    </div>
  )
}

export default Crear;