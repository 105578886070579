import React, { useState, useEffect } from 'react';
import '../estilos/carrito.css';
import { useTranslation } from 'react-i18next';

const Carrito = ({ CostoServicio, NombreServicio, CantidadPersonas, onRemoveService, tipoServicio, arregloServicio, fechaInicio, fechaFin }) => {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);

  // Efecto secundario para agregar automáticamente al carrito cuando cambian los valores
  useEffect(() => {
    if (CostoServicio !== 0 && NombreServicio !== '' && CantidadPersonas !== 0) {
      const newItem = { CostoServicio, NombreServicio, CantidadPersonas, tipoServicio };
      setItems([...items, newItem]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CostoServicio, NombreServicio, CantidadPersonas]);

  // Calcular el número de días entre fechaInicio y fechaFin
  const startDate = new Date(fechaInicio);
  const endDate = new Date(fechaFin);
  const diffTime = Math.abs(endDate - startDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24) + 1); //se agrega un dia mas para integrar el mismo dia de la toma de servicio y no solo la diferencia entre inicio y termino 

  console.log('A CARRITO HAN LLEGADO DATOS DEL SERVICIO', NombreServicio);

  // Renderizar la tabla con los elementos del carrito
  return (
    <div className='card-body px-3'>
        <table className='table'>
          <thead>
            <tr>
              <th className='bg-light'><small>{t('CARRITO.SERVICIO')}</small></th>
              <th className='bg-light text-end'><small className='text-nowrap'>{t('CARRITO.VALOR_X')} {diffDays} {t('CARRITO.DIA/S')}</small></th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td><small className='text-capitalize'>{item.tipoServicio}</small><br></br>{item.NombreServicio}</td>
                <td className='text-end'>${item.tipoServicio === 'otros' ? item.CostoServicio : item.CostoServicio * diffDays}</td>
                {console.log('tipo de servicio', tipoServicio)}
              </tr>
            ))}
          </tbody>
        </table>
    </div>
  );
};

export default Carrito;
