import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {collection, addDoc} from 'firebase/firestore';
import { db, subirArchivo4 } from "../firebaseConfig/firebase";

import Swal from 'sweetalert2';

const ServicioOtro = () => {

  const [currentPage, setCurrentPage] = useState(1);

  //seteo de datos DESCIPTIVOS para el servicio
  const [email, setEmail] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [encargado, setEncargado] = useState(''); 
  const [rut, setRut] = useState('');
  const [email_encargado, setEmail_encargado] = useState('');
  const [telefono, setTelefono] = useState('');
  const [celular, setCelular] = useState('');
  const [horario, setHorario] = useState(''); // se agrega un horario de atencion para servicio de transporte
  const [presencia_red, setPresencia_red] = useState('');
  const [direccion, setDireccion] = useState('');
  const [ciudad, setCiudad] = useState('ciudades de la región ...');
  const [definicion_negocio, setDef_negocio] = useState({
    'TOUR OPERADOR': {checked: false},
    'AGENCIA DE VIAJES': {checked: false},
    'CABALGATAS': {checked: false},
    'DEPORTES': {checked: false},
    'DEPORTES NAUTICOS': {checked: false},
    'GUIA': {checked: false},
    'PORTEO DE EQUIPAJE': {checked: false},
    
    'OTRO': { checked: false, otro:''},
  });
  const [antiguedad, setAntiguedad] = useState('');
  const [sello_calidad, setSello_calidad] = useState('');
  const [imagen, setImagen] = useState(null);

  //seteo de datos OPERATIVOS del servicio
  const [descripcion_actividad, setDescripcion_actividad] = useState('');
  const [formalizada_si, setFormalizada_si] = useState('');
  const [formalizada_no, setFormalizada_no] = useState('');
  const [extras, setExtras] = useState('');
  const [calificacion_clientes, setCalif_clientes] = useState('');

  //seteo más datos OPERATIVOS del servicio
  const [idiomas, setIdiomas] = useState('');
  const [valor_promedio, setValor_promedio] = useState({
    '500 5.000': { checked: false},
    '5.000 10.000': { checked: false},
    '10.000 30.000': { checked: false},
    '30.000 50.000': { checked: false},
    '50.000 100.000': { checked: false},
    '200.00 300.000': { checked: false},
    'MÁS DE 300.000': { checked: false},
    'RELATIVO A LO QUE BUSQUE EL CLIENTE': { checked: false},
    'OTRO VALOR': { checked: false, otro:''},
  });
  const [temporada_alta, setTemporada_altra] = useState('');
  const [capacidad_temp_alta, setCapacidad_temp_alta] = useState('');
  const [cuenta_bancaria, setCuenta_bancaria] = useState('');
  const [forma_pago, setForma_pago] = useState({
    'CONTADO': { checked: false},
    'EFECTIVO, TRANSFERENCIA Y REDBANK ': { checked: false},
    'ANTICIPO U ORDEN DE COMRPA A 30 DIAS': { checked: false},
    '50% DE ANTICIPO O RESERVA Y SALDO CONTRA ENTREGA': { checked: false},
    'PAGO ADELANTADO VÍA TRANSFERENCIA ELECTRONICA U ORDEN DE COMPRA A 30 DÍAS PREVIA EVALUACION DEL CLIENTE': {checked: false},
    'OTRA FORMA DE PAGO': { checked: false, otro:''},
  });
  const [sistema_pago, setSistema_pago] = useState({
    'TRASFERENCIA ELECTRONICA': { checked: false},
    'TARJETA DE CREDITO': { checked: false},
    'DEBITO': { checked: false},
    'CHEQUES': { checked: false},
    'EFECTIVO': { checked: false},
    'PAYPAL': { checked: false},
    'CRYPTO MONEDAS': {checked: false},
    'OTRO SISTEMA': { checked: false, otro:''},
  });
  const [software, setSoftware] = useState('');
  const [pagar_10, setPagar_10] = useState('');

  const navigate = useNavigate();

  // DEFINIENDO CAPTUAS DE ESTADOS 

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const previousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const serv_otroCollection = collection(db, 'servicio_otro');
  const store = async (e) => {
    e.preventDefault();
    try{

      const camposAValidar = [
        { campo: empresa, nombreCampo: 'Nombre Empresa' },
        { campo: direccion, nombreCampo: 'Dirección' },
        { campo: ciudad, nombreCampo: 'Ciudad' },
        // Agrega más campos aquí
      ];

      for (const campoInfo of camposAValidar) {
        if (!campoInfo.campo || campoInfo.campo.trim() === "" ) {
            throw Swal.fire(`El campo ${campoInfo.nombreCampo} no puede estar vacío o debe seleccionar al menos un valor`);
        }
      }
      const resultado = await subirArchivo4(imagen); //aqui se sube la imagen de referencia del negocio al storage firebase : consultar firebase.js
      console.log(resultado)
      await addDoc (serv_otroCollection, {

      //almacenamiento de datos DESCRIPTIVOS 
      //fecha_inscripcion:fecha_inscripcion,
      email:email,
      empresa:empresa,
      encargado:encargado,
      rut:rut,
      email_encargado:email_encargado,
      telefono:telefono,
      celular:celular,
      horario:horario,
      presencia_red:presencia_red,
      direccion:direccion,
      ciudad:ciudad,
      definicion_negocio:definicion_negocio,
      antiguedad:antiguedad,
      sello_calidad:sello_calidad,
      imagen:resultado,
      

      //almacenamiento de datos OPERATIVOS
      descripcion_actividad:descripcion_actividad,
      formalizada_si:formalizada_si,
      formalizada_no:formalizada_no,
      extras:extras,
      calificacion_clientes:calificacion_clientes,

      //almacenamiento de datos OPERATIVOS Y COSTOS
      idiomas:idiomas,
      valor_promedio:valor_promedio,
      temporada_alta:temporada_alta,
      capacidad_temp_alta:capacidad_temp_alta,
      cuenta_bancaria:cuenta_bancaria,
      forma_pago:forma_pago,
      sistema_pago:sistema_pago,
      software:software,
      pagar_10:pagar_10

    })
    Swal.fire('Se Agrego un nuevo servicio de otra categoria')
    navigate('/ver');
    }catch (error){
      console.error(error);
      alert('fallo al subir imagen , intente nuevamente ');
    }
  }
  return(
    <div className="container">
      <div className='row pt-5'>
        <div className='col-12 py-5'>
          <h1 className='pt-5'>Admin <strong>otros servicios</strong></h1>
        </div>
      </div>
      {currentPage === 1 && (
        <div className="div-descriptivos row">
          {/** DATOS DESCRIPTIVOS DEL SERVICIO*/}
          <label className='h3 mb-5'>DATOS SOBRE EL SERVICIO</label>
            <div className='mb-3'>
              <label className='form-label'>INGRESE EL NOMBRE DE LA EMPRESA</label>
                <input
                  value={empresa}
                  onChange={ (e) => setEmpresa(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'><i className="fa-solid fa-at"></i> E-MAIL DE LA EMPRESA</label>
                <input
                  value={email}
                  onChange={ (e) => setEmail(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'> DEFINICIÓN DEL SERVICIO QUE PRESTAN </label>
              {Object.keys(definicion_negocio).map((tipo) => (
                <div key={tipo}>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id={`checkbox_${tipo}`}
                      checked={definicion_negocio[tipo].checked}
                      onChange={(e) => {
                        const newDefinicion_negocio = {
                          ...definicion_negocio,
                          [tipo]: {
                            ...definicion_negocio[tipo],
                            checked: e.target.checked,
                          },
                        };
                        setDef_negocio(newDefinicion_negocio);
                      }}
                    />
                    <label className='form-check-label' htmlFor={`checkbox_${tipo}`}>
                      {tipo}
                    </label>
                  </div>
                  {tipo === 'OTRO' && definicion_negocio[tipo].checked && (
                   <div className='mt-2'>
                      <input
                        type='text'
                        value={definicion_negocio[tipo].otro}
                        onChange={(e) => {
                          const newDefinicion_negocio = {
                            ...definicion_negocio,
                            [tipo]: {
                             ...definicion_negocio[tipo],
                              otro: e.target.value,
                            },
                          };
                          setDef_negocio(newDefinicion_negocio);
                        }}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className='mb-3'>
              <label className='form-label'>NOMBRE DE ENCARGADO O ADMINITRADOR</label>
                <input
                  value={encargado}
                  onChange={ (e) => setEncargado(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>RUT</label>
                <input
                  value={rut}
                  onChange={ (e) => setRut(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'><i className="fa-solid fa-at"></i> E-MAIL ENCARGADO</label>
                <input
                  value={email_encargado}
                  onChange={ (e) => setEmail_encargado(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'><i className="fa-solid fa-phone"></i> TELEFONO FIJO (copiar/pegar)</label>
              <input
                value={telefono}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.startsWith("+67 ")) {
                    setTelefono(inputValue);
                  } else {
                    setTelefono("+67 " + inputValue);
                  }
                }}
                type='text'
                className='form-control'
              />
            </div>
            <div className='mb-3'>
              <label className='form-label'><i className="fa-solid fa-mobile-screen-button"></i> TELEFONO CELULAR (copiar/pegar)</label>
              <input
                value={celular}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.startsWith("+569 ")) {
                    setCelular(inputValue);
                  } else {
                    setCelular("+569 " + inputValue);
                  }
                }}
                type='text'
                className='form-control'
              />
            </div>

            <div className='mb-3'>
              <label className='form-label'><i className="fa-solid fa-image"></i> FOTO O IMAGEN REFERENCIAL DEL NEGOCIO</label>
                <input
                  files={[0]}
                  onChange={ (e) => setImagen(e.target.files[0])}
                  type='file'
                  className='form-control'
                />
            </div>

            <div className='mb-3'>
              <label className="form-label">¿TIENE PRESENCIA EN INTERNET?</label>
                <input
                  value={presencia_red}
                  onChange={ (e) => setPresencia_red(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>DIRECCION COMERCIAL</label>
                <input
                  value={direccion}
                  onChange={ (e) => setDireccion(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
                <div className='form-label'><label >HORARIO DE ATENCIÓN</label></div>
                <input
                  value={horario}
                  onChange={ (e) => setHorario(e.target.value)}
                  type='text'
                  className='form-control'
                />
              </div>
            <div className='mb-3'>
              <label className='form-label'> CIUDAD </label>
                <select value={ciudad} onChange={(e) => setCiudad(e.target.value)} className='form-select' aria-label='Default select example'>
                  <option value='ciudades de la region ...'>seleccione una ciudad...</option>
                  <option value='coyhaique'>Coyhaique</option>
                  <option value='aysen'>Puerto Aysen</option>
                  <option value='chacabuco'>Puerto Chacabuco</option>
                  <option value='puerto_cisnes'>Puerto Cisnes</option>
                  <option value='melinka'>Melinka</option>
                  <option value='puerto_aguirre'>Puerto Aguirre</option>
                  <option value='chile_chico'>Chile Chico</option>
                  <option value='guadal'>Puerto Guadal</option>
                  <option value='puerto_betrand'>Puerto Bertrand</option>
                  <option value='tranquilo'>Puerto Rio Tranquilo</option>
                  <option value='bahia_murta'>Bahía Murta</option>
                  <option value='puerto_sanchez'>Puerto Sanchez</option>
                  <option value='cochrane'>Cochrane</option>
                  <option value='tortel'>Tortel</option>
                  <option value='villa_ohiggins'>Villar O'higgins</option>
                  <option value='balmaceda'>Balmaceda</option>
                  <option value='cerro_castillo'>Cerro_castillo</option>
                  <option value='puerto_ibañez'>Puerto Ibañez</option>
                  <option value='mañihuales'>Mañihuales</option>
                  <option value='villa_amengual'>Villa Amengual</option>
                  <option value='villa_ortega'>Villa Ortega</option>
                  <option value='lago_verde'>Lago Verde</option>
                  <option value='puyuhuapi'>Puyuhuapi</option>
                  <option value='la_junta'>La Junta</option>
                  <option value='raul_marin_balmaceda'>Raúl Marín Balmaceda</option>
                  <option value='futaleufu'>Futaleufu</option>
                  <option value='chaiten'>Chaiten</option>
              
                </select>
            </div>
        </div>
      )}

      {currentPage === 2 && (
        <div className="div-calificaciones row">
         {/** CALIFICAIONES */}
         <label className='h3'><i className="fa-solid fa-ranking-star"></i> ANTIGUEDAD Y CALIFICACIONES</label>
            <div className='mt-4 mb-3'>
              <label className='form-label'>ANTIGUEDAD DEL NEGOCIO</label>
                <input
                  value={antiguedad}
                  onChange={ (e) => setAntiguedad(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>CUENTA CON ALGUN SELLO DE CALIDAD</label>
                <input
                  value={sello_calidad}
                  onChange={ (e) => setSello_calidad(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'> CALIFICACIONES DE CLIENTES </label>
                <select value={calificacion_clientes} onChange={(e) => setCalif_clientes(e.target.value)} className='form-select' aria-label='Default select example'>
                  <option value='sin calificion'>calificaciones...</option>
                  <option value='5'>***** 5 estrellas</option>
                  <option value='4'>**** 4 estrellas</option>
                  <option value='3'>*** 3 estrellas</option>
                  <option value='2'>** 2 estrellas</option>
                </select>
            </div>
          </div>
      )}

      {currentPage === 3 && (
        <div className="row">
          {/** SERVICIOS PRESENTES */} 

          <div className='mb-3'>
              <label className='form-label'>DESCRIBA LA ACTIVIDAD QUE REALIZA SU SERVICIO</label>
                <textarea
                  value={descripcion_actividad}
                  onChange={ (e) => setDescripcion_actividad(e.target.value)}
                  type='text-area'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>¿SU EMPRESA ESTA FORMALIZADA [SI]?</label>
                <input
                  value={formalizada_si}
                  onChange={ (e) => setFormalizada_si(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>¿SU EMPRESA ESTA FORMALIZADA [NO]?</label>
                <input
                  value={formalizada_no}
                  onChange={ (e) => setFormalizada_no(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>          
            <div className='mb-3'>
              <label className='form-label'>DESCRIBA SERVICIOS COMPLEMENTARIOS</label>
                <input
                  value={extras}
                  onChange={ (e) => setExtras(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>MANEJAN OTROS IDIOMAS</label>
                <input
                  value={idiomas}
                  onChange={ (e) => setIdiomas(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            </div>
      )}

      {currentPage === 4 && (
        <div className="row">
          {/** TEMPORADA ALTA */}
          <label className='h3 mb-4'><i className="fa-regular fa-calendar-plus"></i> DATOS DE TEMPORADA ALTA </label>
          <div className='mb-3'>
              <label className='form-label'>INGRESE AQUI MESES (DESDE - HASTA)</label>
                <input
                  value={temporada_alta}
                  onChange={ (e) => setTemporada_altra(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>CAPACIDAD TEMPORADA ALTA</label>
                <input
                  value={capacidad_temp_alta}
                  onChange={ (e) => setCapacidad_temp_alta(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
          </div>
      )}

      {currentPage === 5 && (
        <div className="row">
          {/** COSTES Y MEDIOS DE PAGOS */}
          <label className='h3 mb-4'><i className="fa-solid fa-hand-holding-dollar"></i> COSTOS Y DATOS DE PAGO DEL SERVICIO </label>
            <div className='mb-3'>
              <label className='form-label'>VALOR PROMEDIO DE SUS SERVICIOS (rangos de valores relativos)</label>
              {Object.keys(valor_promedio).map((tipo) => (
                <div key={tipo}>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id={`checkbox_${tipo}`}
                      checked={valor_promedio[tipo].checked}
                      onChange={(e) => {
                        const newValor_promedio = {
                          ...valor_promedio,
                          [tipo]: {
                            ...valor_promedio[tipo],
                            checked: e.target.checked,
                          },
                        };
                        setValor_promedio(newValor_promedio);
                      }}
                    />
                    <label className='form-check-label' htmlFor={`checkbox_${tipo}`}>
                      {tipo}
                    </label>
                  </div>
                  {tipo === 'OTRO VALOR' && valor_promedio[tipo].checked && (
                   <div className='mt-2'>
                      <input
                        type='text'
                        value={valor_promedio[tipo].otro}
                        onChange={(e) => {
                          const newValor_promedio = {
                            ...valor_promedio,
                            [tipo]: {
                             ...valor_promedio[tipo],
                              otro: e.target.value,
                            },
                          };
                          setValor_promedio(newValor_promedio);
                        }}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className='mb-3'>
                <label className='form-label'> CUENTA BANCARIA DE EMPRESA </label>
                <select value={cuenta_bancaria} onChange={(e) => setCuenta_bancaria(e.target.value)} className='form-select' aria-label='Default select example'>
                  <option value='SI'>SI</option>
                  <option value='CUENTA PERSONAL'>CUENTA PERSONAL</option>
                  <option value='NO'>NO</option>
                </select>
            </div>
            <div className='mb-3'>
              <label className='mb-3'> FORMAS DE PAGO</label>

              {Object.keys(forma_pago).map((tipo) => (
                <div key={tipo}>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id={`checkbox_${tipo}`}
                      checked={forma_pago[tipo].checked}
                      onChange={(e) => {
                        const newForma_pago = {
                          ...forma_pago,
                          [tipo]: {
                            ...forma_pago[tipo],
                            checked: e.target.checked,
                          },
                        };
                        setForma_pago(newForma_pago);
                      }}
                    />
                    <label className='form-check-label' htmlFor={`checkbox_${tipo}`}>
                      {tipo}
                    </label>
                  </div>
                  {tipo === 'OTRA FORMA DE PAGO' && forma_pago[tipo].checked && (
                   <div className='mt-2'>
                      <input
                        type='text'
                        value={forma_pago[tipo].otro}
                        onChange={(e) => {
                          const newForma_pago = {
                            ...forma_pago,
                            [tipo]: {
                             ...forma_pago[tipo],
                              otro: e.target.value,
                            },
                          };
                          setForma_pago(newForma_pago);
                        }}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className='mb-3'>
              <label className='form-label'> SISTEMAS DE PAGO</label>
              {Object.keys(sistema_pago).map((tipoPago) => (
                <div key={tipoPago}>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id={`checkbox_${tipoPago}`}
                      checked={sistema_pago[tipoPago].checked}
                      onChange={(e) => {
                        const newSistema_pago = {
                          ...sistema_pago,
                          [tipoPago]: {
                            ...sistema_pago[tipoPago],
                            checked: e.target.checked,
                          },
                        };
                        setSistema_pago(newSistema_pago);
                      }}
                    />
                    <label className='form-check-label' htmlFor={`checkbox_${tipoPago}`}>
                      {tipoPago}
                    </label>
                  </div>
                  {tipoPago === 'OTRO SISTEMA' && sistema_pago[tipoPago].checked && (
                   <div className='mt-2'>
                      <input
                        type='text'
                        value={sistema_pago[tipoPago].otro}
                        onChange={(e) => {
                          const newSistema_pago = {
                            ...sistema_pago,
                            [tipoPago]: {
                             ...sistema_pago[tipoPago],
                              otro: e.target.value,
                            },
                          };
                          setSistema_pago(newSistema_pago);
                        }}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className='mb-3'>
              <label className='form-label'>¿CUENTAN CON ALGUN SOFTWARE DE GESTIÓN?</label>
                <input
                  value={software}
                  onChange={ (e) => setSoftware(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
                <label className='form-label'> ¿ESTA DISPUESTO A PAGAR EL 10%? </label>
                <select value={pagar_10} onChange={(e) => setPagar_10(e.target.value)} className='form-select' aria-label='Default select example'>
                  <option value='SI'>SI</option>
                  <option value='NO'>NO</option>
                  <option value='TAL VEZ'>TAL VEZ</option>
                </select>
            </div>
        </div>
      )}

      {currentPage === 1 && (
        <div className="d-flex justify-content-end mb-3">
        <button onClick={nextPage} className="btn btn-primary">
          Siguiente
        </button>
        </div>
      )}

      {currentPage > 1 && currentPage < 5 && (
        <>
        <div className="d-flex justify-content-center mb-3">
          <button onClick={previousPage} className="btn btn-primary">
            Anterior
          </button>
          <button onClick={nextPage} className="btn btn-primary">
            Siguiente
          </button>
        </div>
        </>
      )}

      {currentPage === 5 && (
        <>
        <div className="d-flex justify-content-center mb-3">
          <button onClick={previousPage} className="btn btn-primary">
            Anterior
          </button>
          <button onClick={store} className="btn btn-primary">
            Crear
          </button>
        </div>
        </>
      )}
    </div>
  )
}

export default ServicioOtro;