import React, { useState, useEffect } from 'react';
import {useParams} from 'react-router-dom';
import BannerServices from '../componentes/componentes_paginas/bannerServices';
import MapComponentUsr from '../componentes/maapaUsuario';
import PantallaCarga from './pantallaCarga'; // importamos la pantalla de carga
import seassonTabs from "../componentes/componentes_paginas/seassonTabs";
//import { Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from "../firebaseConfig/firebase";
import './estilos/locacionSeleccionada.css'; // Importa el archivo CSS
import { useTranslation } from "react-i18next";


const LocacionSeleccionada = () => {
	const { t } = useTranslation();
  const { id } = useParams();
  const [locacion, setLocacion] = useState(null);
	const [recomendacion, setRecomendacion] = useState('recomendaciones de vestuario para cada temporada del año ');
	const [bannerImage, setBannerImage] = useState(''); // Estado inicial con una imagen vacía

	/**------------------------------ DESCARDO DE USO DE IMAGENS -------------------------------- */
	const	[descargo, setDescargo] = useState('DESCARGO SOBRE LAS IMAGENES');
	const [mostrarNuevoTexto, setMostrarNuevoTexto] = useState(false);
	const cambiarTexto = () => {
    const emailDescargo = "hola@catarsis.cl";
    // Cambiar el texto al hacer clic
    setDescargo(
      'Uso de Imágenes: Todos los derechos de autor de las imágenes presentes en este sitio son propiedad de Catarsis Films, incluyendo derechos de reproducción, distribución, comunicación pública y transformación. Se prohíbe estrictamente la descarga, copia, modificación o uso de estas imágenes sin autorización expresa. El incumplimiento de estos términos está sujeto a las sanciones establecidas en la Ley N° 17.336 sobre Propiedad Intelectual de Chile. Para solicitudes de uso, por favor contacta a '
      + `<a href="mailto:${emailDescargo}">${emailDescargo}</a>`
      + ' para obtener la autorización correspondiente.'
    );
    // Cambiar el estado para mostrar el nuevo texto
    setMostrarNuevoTexto(true);
  };
	const resetearTexto = () => {
    setMostrarNuevoTexto(false); // Cambiar el estado de vuelta cuando el mouse sale del div
  };
	/**------------------------------------------------------------------------------------------ */

  useEffect(() => {
    const fetchLocacion = async () => {
      try {
        const locacionRef = doc(db, 'locacion', id);
        const locacionSnapshot = await getDoc(locacionRef);
        
        if (locacionSnapshot.exists()) {
          setLocacion({ ...locacionSnapshot.data(), id: locacionSnapshot.id });
        } else {
          console.log('La locación no existe');
        }
      } catch (error) {
        console.error('Error al obtener la locación:', error);
      }
    };
    //console.log('esta es la id capturada', id)

    if (id) {
      fetchLocacion();
    }
	
  }, [id]);

  if (!locacion) {
	return <PantallaCarga />; // Muestra un indicador de carga mientras se obtienen los datos
  }
  else{
			
  }

	/** ---------------CAPTURDA DE DATOS CLIMÁTICOS Y FORMATEO DE LA INFORMACIÓN---------------- */

	const datosClimaticosObjeto = JSON.stringify(locacion.datosClimaticos);
	const datClima = JSON.parse(datosClimaticosObjeto);
	function obtenerDatosFormateados(datoClima, nombreDato, valorPorDefecto) {
		const dato = datoClima[nombreDato];
		if (dato && dato.meses.length > 0) {
			if (dato.meses.length > 3) {
				// Si hay más de 3 elementos en el arreglo, devolverlos sin espacios entre '/'
				return dato.meses.join('/');
			} else {
				// Si hay 3 o menos elementos en el arreglo, devolverlos con espacios entre ' / '
				return dato.meses.join(' / ');
			}
		} else {
			return valorPorDefecto;
		}
	}
	const mesesMasLuzFormateados = obtenerDatosFormateados(datClima, 'MESES CON MÁS LUZ + ', 'DICIEMBRE / ENERO');
	const mesesMenosLuzFormateados = obtenerDatosFormateados(datClima, 'MESES CON MENOS LUZ - ', 'JUNIO / JULIO');
	const mesesMasVientoFormateados = obtenerDatosFormateados(datClima, 'MESES CON MÁS VIENTO + ', 'SEPTIEMBRE / OCTUBRE');
	const mesesMenosVientoFormateados = obtenerDatosFormateados(datClima, 'MESES CON MENOS VIENTO - ', 'MAYO / JUNIO');
	const mesesMasLluviaFormateados = obtenerDatosFormateados(datClima, 'MESES CON MÁS LLUVIA + ', 'MAYO / JUNIO');
	const mesesMenosLluviaFormateados = obtenerDatosFormateados(datClima, 'MESES CON MENOS LLUVIA - ', 'DICIEMBRE / ENERO');
	const mesesMasNieveFormateados = obtenerDatosFormateados(datClima, 'MESES CON MÁS NIEVE + ', 'JULIO / AGOSTO');
	const mesesMenosNieveFormateados = obtenerDatosFormateados(datClima, 'MESES CON MENOS NIEVE - ', 'DICIEMBRE / ENERO');

	//-----------------------------LOGICA DE TAGS -----------------------------------

	const tagsString = locacion ? locacion.tags : ''; // Si locacion es null, establece tagsString como una cadena vacía
	// Usamos una expresión regular para dividir la cadena por comas o almohadillas
	const tagsArray = tagsString.split(/[,#]/);
	// Eliminamos los espacios en blanco alrededor de cada tag
	const trimmedTags = tagsArray.map(tag => tag.trim());
	const tagsElements = trimmedTags.filter(tag => tag !== "").map((tag, index) => (
		<span key={index} className="badge rounded-pill text-bg-dark px-3 py-2 mb-1 me-1">{tag}</span>
	));

	//--------------------------LOGICA DE COSTE LOCACION------------------------------
	function formatCost(value) {
		// Verificar si el valor comienza con $
		if (!value.startsWith('$')) {
		  // Agregar $ al inicio si no está presente
		  value = `$${value}`;
		}
		// Eliminar espacios en blanco
		value = value.replace(/\s/g, '');
		// Separar la parte entera de la decimal si hay
		const [integerPart, decimalPart = ''] = value.split('.');
		// Agregar puntos para separar los miles
		const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
		// Volver a unir las partes y formatear el valor final
		const formattedValue = `${formattedIntegerPart}${decimalPart ? `.${decimalPart}` : ''}`;
	  
		return formattedValue;
	}

	//-------------------------LOGICA DE ESTACIONAMIENTOS-------------------------------
	const formattedEstacionamiento = formatEst(locacion.esta_cap);
	function formatEst(value) {
	  const num = parseInt(value, 10);
	
	  if (num >= 50) {
	    return `+${'50'.toString()}`; // Devuelve el número con un signo + delante
	  } else {
	    return '50'.toString(); // Devuelve el número como está (menor o igual a 50)
	  }
	}

	function formatearDistancia(distancia) {
		// Verificar si hay caracteres no numéricos en la cadena
		const contieneLetrasOSimbolos = /[^\d\s]/.test(distancia);
	
		// Si no hay letras ni símbolos, agregar '/km' al final
		if (!contieneLetrasOSimbolos) {
			return `${distancia}/km`;
		}
		// Verificar si el valor es un número menor a 1
		if (parseFloat(distancia) < 1) {
			// Considerar el valor como metros (multiplicar por 1000)
			return `${parseFloat(distancia) * 1000}/mts`;
		}
	
		// Si ya tiene letras o símbolos, retornar el valor original
		return distancia;
	}
	
	// Usar la función formatearDistancia con locacion.esta_distancia
	const distanciaFormateada = formatearDistancia(locacion.esta_distancia);
	

	//------------------------------CAMBIO DE BANNER-----------------------------------
	const handleImageClick = (imagen) => {
    setBannerImage(imagen); // Al hacer clic en una imagen, actualiza la imagen del banner
		window.scrollTo({
      top: 130,
      behavior: 'smooth', // Desplazamiento suave
    });
  };

	//---------------------------SET VALORES VESTTUARIO--------------------------
  const mostrarRecomendacion = (textoRecomendacion) => {
    setRecomendacion(textoRecomendacion);
  };

	//-------------------------- FORMATO DE COSTO DE PERMISO-------------------------
	const formattedCost = formatCost(locacion.permiso_coste);
	const selectedLocation = ({
    lat: locacion.latitud,
    lng: locacion.longitud,
  });

  return (
    <>
	<div className="container-fluid header-cotizar">
        <div className='container'>
          <div className='row'>
		  <div className='col-9 py-1'>
              <h3 className='pt-4 text-uppercase'><strong>{locacion.nombre}</strong></h3>
            </div>
            <div className='col-3 py-1 text-right'>
              <a href={`/cotizador/${locacion.id}/${locacion.ciudadCercana}/`} className='btn btn-primary rounded-pill px-4 text-uppercase mt-2 float-end fs-3'>
                <i className="bi bi-cart-plus"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid bg-white pb-5 mt-100'>
        <div className='container'>
          <div className="banner rounded-1 overflow-hidden">
            <img src={bannerImage || locacion.portadaLoc} alt={t('LOCACION_SELECCIONADA.IMAGEN_DE_PORTADA')} className='img-fluid' />
          </div>

          <div className="imagenes mt-1 pb-1">
            {locacion.imagenes.map((imagen, index) => (
              <img key={index} src={imagen} alt={t('LOCACION_SELECCIONADA.IMAGEN', { index: index + 1 })} className="img-fluid imagen rounded-1 overflow-hidden" onClick={() => handleImageClick(imagen)} />
            ))}
          </div>

        </div>
      </div>

      <div className='container-fluid bg-light'>
        <div className='container py-5'>
          <div className='row'>
            <div className='col-lg-7'>
              <h2 className='text-uppercase'>{t('LOCACION_SELECCIONADA.SOBRE')} <strong>{t('LOCACION_SELECCIONADA.LOCACION')}</strong></h2>
              <p className='py-4'>{locacion.descripcion}</p>
            </div>

            <div className='col-lg-5 col-xl-4 offset-xl-1'>
              <div className='card text-bg-dark'>
                <div className='card-body p-4'>
                  <h4 style={{ textTransform: 'uppercase' }}>
                    {t('LOCACION_SELECCIONADA.LOCACION')} <strong>{locacion.puplica_privada}</strong>
                  </h4>
                </div>

                <ul className="list-group bg-transparent border-0 p-2">
                  <li className="list-group-item d-flex justify-content-between align-items-start bg-transparent border-0 text-white">
                    <div className="ms-0 me-auto">
                      <p className='lead'>
                        {t('LOCACION_SELECCIONADA.COSTO_POR_DIA')}<br />
                        <small className='fs-7'>{t('LOCACION_SELECCIONADA.REQUIERE_PERMISOS')}</small>
                      </p>
                    </div>
                    <p className='lead fs-4'><strong>{formattedCost}</strong></p>
                  </li>
                </ul>
              </div>

              <h3 className='my-4'>{tagsElements}</h3>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid arrive bg-white'>
        <div className='container py-5'>
          <div className='row'>
            <div className="col-12">
              <h2 className="text-uppercase pb-4">{t('LOCACION_SELECCIONADA.COMO')} <strong>{t('LOCACION_SELECCIONADA.LLEGAR')}</strong></h2>
            </div>
            <div className='col-lg-4 mb-4'>
              <div className='card border'>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex">
                    <div className="p-2 flex-shrink-1"><i className="fa-solid fa-route"></i></div>
                    <div className="p-2 w-100">
                      {t('LOCACION_SELECCIONADA.SANTIAGO')}-{locacion.nombre}: <strong>{locacion.santiago}</strong>km
                    </div>
                  </li>

                  <li className="list-group-item d-flex">
                    <div className="p-2 flex-shrink-1"><i className="fa-solid fa-route"></i></div>
                    <div className="p-2 w-100">
                      {t('LOCACION_SELECCIONADA.AEROPUERTO')} {locacion.aeropuerto} - {locacion.nombre}: <strong>{locacion.aero_distancia}</strong>km
                    </div>
                  </li>

                  <li className="list-group-item d-flex">
                    <div className="p-2 flex-shrink-1"><i className="fa-solid fa-route"></i></div>
                    <div className="p-2 w-100">
                      {t('LOCACION_SELECCIONADA.AERODROMO')} {locacion.aerodromo} - {locacion.nombre}: <strong>{locacion.aerod_distancia}</strong>km
                    </div>
                  </li>

                  <li className="list-group-item d-flex">
                    <div className="p-2 flex-shrink-1"><i className="fa-solid fa-road"></i></div>
                    <div className="p-2 w-100">
                      {t('LOCACION_SELECCIONADA.CAMINO_ASFALTADO')}: <strong>{locacion.kilometrosAsfalto}</strong>km <br />
                      {t('LOCACION_SELECCIONADA.ESTADO')}: <strong>{locacion.estadoCaminoAsfalto}</strong>
                    </div>
                  </li>

                  <li className="list-group-item d-flex">
                    <div className="p-2 flex-shrink-1"><i className="fa-solid fa-road"></i></div>
                    <div className="p-2 w-100">
                      {t('LOCACION_SELECCIONADA.CAMINO_RIPIO')}: <strong>{locacion.kilometrosRipio}</strong>km <br />
                      {t('LOCACION_SELECCIONADA.ESTADO')}: <strong>{locacion.estadoCaminoRipio}</strong>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
				<div className='col-lg-8'>
					<MapComponentUsr className="m-0 p-0" selectedLocation={selectedLocation} />
				</div>
			</div>
		</div>
	</div>

	<div className='container-fluid bg-light'>
	<div className='container py-5'>
		<div className='row'>
			<div className="col-12">
				<h2 className="text-uppercase pb-4"><strong>{t('LOCACION_SELECCIONADA.SERVICIOS_Y_ACCESIBILIDAD')}</strong></h2>
			</div>

			<div className='col-md-6 col-lg-4 mb-4'>
				<div className='card'>
					<div className='card-body'>
						<div className="d-flex">
							<div className="p-2"><i className="fa-solid fa-car fs-4"></i></div>
							<div className="p-2 flex-grow-1">
								<p>
									{t('LOCACION_SELECCIONADA.ESTACIONAMIENTO')}<br />
									{locacion.estacionamiento && (
										<>
											<small>{t('LOCACION_SELECCIONADA.CAPACIDAD')}: {formattedEstacionamiento}</small><br />
											<small>{t('LOCACION_SELECCIONADA.ESTACIONAMIENTO_LOCACION')}: {distanciaFormateada}</small>
										</>
									)}
								</p>
							</div>
							<div className="p-2">
								{locacion.estacionamiento ? (<i className="fa-solid fa-circle-check fs-4 text-primary"></i>) : (<i className="fa-solid fa-circle-xmark fs-4 text-danger"></i>)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='col-md-6 col-lg-4 mb-4'>
				<div className='card'>
					<div className='card-body'>
						<div className="d-flex">
							<div className="p-2"><i className="fa-solid fa-faucet-drip fs-4"></i></div>
							<div className="p-2 flex-grow-1">
								<p className='pb-2'>
									{t('LOCACION_SELECCIONADA.AGUA_POTABLE')}<br />
									{locacion.agua ? (<p>{t('LOCACION_SELECCIONADA.DISPONIBLE')}</p>) : (<p>{t('LOCACION_SELECCIONADA.NO_DISPONIBLE')}</p>)}
								</p>
							</div>
							<div className="p-2">
								{locacion.agua ? (<i className="fa-solid fa-circle-check fs-4 text-primary"></i>) : (<i className="fa-solid fa-circle-xmark fs-4 text-danger"></i>)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='col-md-6 col-lg-4 mb-4'>
				<div className='card'>
					<div className='card-body'>
						<div className="d-flex">
							<div className="p-2"><i className="fa-solid fa-plug fs-4"></i></div>
							<div className="p-2 flex-grow-1">
								<p className='pb-2'>
									{t('LOCACION_SELECCIONADA.ELECTRICIDAD')}<br />
									{locacion.electricidad ? (<p>{t('LOCACION_SELECCIONADA.DISPONIBLE')}</p>) : (<p>{t('LOCACION_SELECCIONADA.NO_DISPONIBLE')}</p>)}
								</p>
							</div>
							<div className="p-2">
								{locacion.electricidad ? (<i className="fa-solid fa-circle-check fs-4 text-primary"></i>) : (<i className="fa-solid fa-circle-xmark fs-4 text-danger"></i>)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='col-md-6 col-lg-4 mb-4'>
				<div className='card'>
					<div className='card-body'>
						<div className="d-flex">
							<div className="p-2"><i className="fa-solid fa-tent fs-4"></i></div>
							<div className="p-2 flex-grow-1">
								<p className='pb-2'>
									{t('LOCACION_SELECCIONADA.REFUGIO')}<br />
									{locacion.refugio ? (<p>{t('LOCACION_SELECCIONADA.DISPONIBLE')}</p>) : (<p>{t('LOCACION_SELECCIONADA.NO_DISPONIBLE')}</p>)}
								</p>
							</div>
							<div className="p-2">
								{locacion.refugio ? (<i className="fa-solid fa-circle-check fs-4 text-primary"></i>) : (<i className="fa-solid fa-circle-xmark fs-4 text-danger"></i>)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='col-md-6 col-lg-4 mb-4'>
				<div className='card'>
					<div className='card-body'>
						<div className="d-flex">
							<div className="p-2"><i className="fa-solid fa-house-flag fs-4"></i></div>
							<div className="p-2 flex-grow-1">
								<p className='pb-2'>
									{t('LOCACION_SELECCIONADA.BASE_CAMP')}<br />
									{locacion.base_camp ? (<p>{t('LOCACION_SELECCIONADA.DISPONIBLE')}</p>) : (<p>{t('LOCACION_SELECCIONADA.NO_DISPONIBLE')}</p>)}
								</p>
							</div>
							<div className="p-2">
								{locacion.base_camp ? (<i className="fa-solid fa-circle-check fs-4 text-primary"></i>) : (<i className="fa-solid fa-circle-xmark fs-4 text-danger"></i>)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='col-md-6 col-lg-4 mb-4'>
				<div className='card'>
					<div className='card-body'>
						<div className="d-flex">
							<div className="p-2"><i className="fa-solid fa-warehouse fs-4"></i></div>
							<div className="p-2 flex-grow-1">
								<p className='pb-2'>
									{t('LOCACION_SELECCIONADA.BODEGA')}<br />
									{locacion.bodega ? (<p>{t('LOCACION_SELECCIONADA.DISPONIBLE')}</p>) : (<p>{t('LOCACION_SELECCIONADA.NO_DISPONIBLE')}</p>)}
								</p>
							</div>
							<div className="p-2">
								{locacion.bodega ? (<i className="fa-solid fa-circle-check fs-4 text-primary"></i>) : (<i className="fa-solid fa-circle-xmark fs-4 text-danger"></i>)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='col-md-6 col-lg-4 mb-4'>
				<div className='card'>
					<div className='card-body'>
						<div className="d-flex">
							<div className="p-2"><i className="fa-solid fa-globe fs-4"></i></div>
							<div className="p-2 flex-grow-1">
								<p className='pb-2'>
									{t('LOCACION_SELECCIONADA.INTERNET')}<br />
									{locacion.internet ? (<p>{t('LOCACION_SELECCIONADA.DISPONIBLE')}</p>) : (<p>{t('LOCACION_SELECCIONADA.NO_DISPONIBLE')}</p>)}
								</p>
							</div>
							<div className="p-2">
								{locacion.internet ? (<i className="fa-solid fa-circle-check fs-4 text-primary"></i>) : (<i className="fa-solid fa-circle-xmark fs-4 text-danger"></i>)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='col-md-6 col-lg-4 mb-4'>
				<div className='card'>
					<div className='card-body'>
						<div className="d-flex">
							<div className="p-2"><i className="fa-solid fa-tower-cell fs-4"></i></div>
							<div className="p-2 flex-grow-1">
								<p className='pb-2'>
									{t('LOCACION_SELECCIONADA.SENAL_TELEFONICA')}<br />
									{locacion.movil ? (<p>{t('LOCACION_SELECCIONADA.DISPONIBLE')}</p>) : (<p>{t('LOCACION_SELECCIONADA.NO_DISPONIBLE')}</p>)}
								</p>
							</div>
							<div className="p-2">
								{locacion.movil ? (<i className="fa-solid fa-circle-check fs-4 text-primary"></i>) : (<i className="fa-solid fa-circle-xmark fs-4 text-danger"></i>)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='col-md-6 col-lg-4 mb-4'>
				<div className='card'>
					<div className='card-body'>
						<div className="d-flex">
							<div className="p-2"><i className="fa-solid fa-satellite fs-4"></i></div>
							<div className="p-2 flex-grow-1">
								<p className='pb-2'>
									{t('LOCACION_SELECCIONADA.COBERTURA_SATELITAL')}<br />
									{locacion.satelite ? (<p>{t('LOCACION_SELECCIONADA.DISPONIBLE')}</p>) : (<p>{t('LOCACION_SELECCIONADA.NO_DISPONIBLE')}</p>)}
								</p>
							</div>
							<div className="p-2">
								{locacion.satelite ? (<i className="fa-solid fa-circle-check fs-4 text-primary"></i>) : (<i className="fa-solid fa-circle-xmark fs-4 text-danger"></i>)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div className='container py-5'>
		<div className='row'>
			<div className="col-12">
				<h2 className="text-uppercase pb-4">{t('CONDICIONES_CLIMATICAS.TITULO')}</h2>
			</div>
			
			<div className='col-lg-6 mb-4'>
				<div className='card'>
					<div className='card-body'>
						<div className="d-flex align-items-center">
							<div className="p-2"><i className="fa-solid fa-sun fs-1"></i></div>
							<div className="p-2 flex-grow-1">
								<p>
									{t('CONDICIONES_CLIMATICAS.ELEVACION_DEL_SOL')}<br />
									<small><i className="fa-solid fa-plus"></i> {mesesMasLuzFormateados}<br />
									<i className="fa-solid fa-minus"></i> {mesesMenosLuzFormateados}</small>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='col-lg-6 mb-4'>
				<div className='card'>
					<div className='card-body'>
						<div className="d-flex align-items-center">
							<div className="p-2"><i className="fa-solid fa-cloud-rain fs-1"></i></div>
							<div className="p-2 flex-grow-1">
								<p>
									{t('CONDICIONES_CLIMATICAS.LLUVIAS')}<br />
									<small><i className="fa-solid fa-plus"></i> {mesesMasLluviaFormateados}<br />
									<i className="fa-solid fa-minus"></i> {mesesMenosLluviaFormateados}</small>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='col-lg-6 mb-4'>
				<div className='card'>
					<div className='card-body'>
						<div className="d-flex align-items-center">
							<div className="p-2"><i className="fa-solid fa-cloud-rain fs-1"></i></div>
							<div className="p-2 flex-grow-1">
								<p>
									{t('CONDICIONES_CLIMATICAS.VIENTOS')}<br />
									<small><i className="fa-solid fa-plus"></i> {mesesMasVientoFormateados}<br />
									<i className="fa-solid fa-minus"></i> {mesesMenosVientoFormateados}</small>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='col-lg-6 mb-4'>
				<div className='card'>
					<div className='card-body'>
						<div className="d-flex align-items-center">
							<div className="p-2"><i className="fa-solid fa-snowflake fs-1"></i></div>
							<div className="p-2 flex-grow-1">
								<p>
									{t('CONDICIONES_CLIMATICAS.NEVADAS')}<br />
									<small><i className="fa-solid fa-plus"></i> {mesesMasNieveFormateados}<br />
									<i className="fa-solid fa-minus"></i> {mesesMenosNieveFormateados}</small>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>



		<div className='container py-5'>
		<div className='row'>
        <div className="col-12">
            <h2 className="text-uppercase pb-4">{t('RECOMENDACIONES.RECOMENDACIONES_TEMPO')}</h2>
        </div>

        <div className='col-3'>
            <button className="btn btn-summer btn-lg w-100" type="button" onClick={() => mostrarRecomendacion(locacion.vestuario_verano)}>
                <i className="fa-solid fa-umbrella-beach"></i> <span className='d-none d-md-block'>{t('RECOMENDACIONES.VERANO')}</span>
            </button>
        </div>

        <div className='col-3'>
            <button className="btn btn-autumn btn-lg w-100" type="button" onClick={() => mostrarRecomendacion(locacion.vestuario_otoño)}>
                <i className="fa-solid fa-umbrella"></i> <span className='d-none d-md-block'>{t('RECOMENDACIONES.OTOÑO')}</span>
            </button>
        </div>

        <div className='col-3'>
            <button className="btn btn-winter btn-lg w-100" type="button" onClick={() => mostrarRecomendacion(locacion.vestuario_invierno)}>
                <i className="fa-solid fa-snowman"></i> <span className='d-none d-md-block'>{t('RECOMENDACIONES.INVIERNO')}</span>
            </button>
        </div>

        <div className='col-3'>
            <button className="btn btn-spring btn-lg w-100" type="button" onClick={() => mostrarRecomendacion(locacion.vestuario_primavera)}>
                <i className="fa-brands fa-pagelines"></i> <span className='d-none d-md-block'>{t('RECOMENDACIONES.PRIMAVERA')}</span>
            </button>
        </div>

				<div className='col-12'>
					<div className='card mt-4'>
						<div className='card-body' dangerouslySetInnerHTML={{ __html: recomendacion }}></div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<BannerServices />

  	</>
  );
}

export default LocacionSeleccionada;
