import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig/firebase';
import { useNavigate } from 'react-router-dom';
import './estilos/blog.css'; // estilo de la pagina blog
function Blog() {
  const [blogEntries, setBlogEntries] = useState([]);

  const navigate = useNavigate(); // Agrega esto para habilitar la navegación

  const verEntrada = (id) => {
    navigate(`/blog_vista/${id}`);
  };


  useEffect(() => {
    // Función asincrónica para cargar las entradas del blog
    const loadBlogEntries = async () => {
      const blogCollection = collection(db, 'blog_entrada');
      const snapshot = await getDocs(blogCollection);
      const entries = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Ordena las entradas por fecha de publicación (de la más reciente a la más antigua)
      entries.sort((a, b) => b.fechaPublicacion - a.fechaPublicacion);

      setBlogEntries(entries);
    };

    loadBlogEntries();
  }, []);

  return (
    <div>
      <h1>Blog de Shootinpatagonia</h1>
      <ul className="blog-list">
        {blogEntries.map((entry) => (
          <li key={entry.id} className="blog-entry" onClick={() => verEntrada(entry.id)} >
            <div
              className="blog-entry-image"
              style={{ backgroundImage: `url(${entry.imagen})` }}
            >
              <h2 className="blog-entry-title">{entry.titulo}</h2>
            </div>
            {/** <div
              className="blog-entry-content"
              dangerouslySetInnerHTML={{ __html: entry.contenido }}
            />
            <p>Fecha de publicación: {entry.fechaPublicacion.toDate().toLocaleDateString()}</p>*/}
          </li>
        ))}
      </ul>
    </div>
  );
}


export default Blog;
