
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "@firebase/storage";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import {v4} from 'uuid';

/* datos del proyecto shootinpatagonia OLD
const firebaseConfig = {
  apiKey: "AIzaSyDEoYq5IM60CDO3haQL1IfyP2UGduYbiLQ",
  authDomain: "bd-shootinpatagonia0.firebaseapp.com",
  projectId: "bd-shootinpatagonia0",
  storageBucket: "bd-shootinpatagonia0.appspot.com",
  messagingSenderId: "183145421615",
  appId: "1:183145421615:web:c075df797678e8f7979fda",
  measurementId: "G-K61N8YLPKH"
}; */

// datos del proyecto shootinpatagonia noviembre 2023
const firebaseConfig = {
  apiKey: "AIzaSyBX0IoK7wxm0fK96nk5ZYN6QpYL7x0PtSE",
  authDomain: "shoot-in-patagonia.firebaseapp.com",
  projectId: "shoot-in-patagonia",
  storageBucket: "shoot-in-patagonia.appspot.com",
  messagingSenderId: "1063465965867",
  appId: "1:1063465965867:web:f4ff8faa2e9597b60f4d6e"
};

// inicializamos Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);

export async function subirArchivo(file) {
  const storageRef = ref(storage, `${'gs://shoot-in-patagonia.appspot.com/admin/servicios/alojamientos/img_negocio'}/${v4()}`);
  await uploadBytes(storageRef, file);
  const urlImagen = await getDownloadURL(storageRef); // aqui se recoge la URL de la imagen subida y se retorna como string 
  return urlImagen;
}

export async function subirArchivo2(file) {
  const storageRef = ref(storage, `${'gs://shoot-in-patagonia.appspot.com/admin/servicios/transpotes/img_negocio'}/${v4()}`);
  await uploadBytes(storageRef, file);
  const urlImagen = await getDownloadURL(storageRef); // aqui se recoge la URL de la imagen subida y se retorna como string 
  return urlImagen;
}

export async function subirArchivo3(file) {
  const storageRef = ref(storage, `${'gs://shoot-in-patagonia.appspot.com/admin/servicios/gastronomicos/img_negocio'}/${v4()}`);
  await uploadBytes(storageRef, file);
  const urlImagen = await getDownloadURL(storageRef); // aqui se recoge la URL de la imagen subida y se retorna como string 
  return urlImagen;
}

export async function subirArchivo4(file) {
    const storageRef = ref(storage, `${'gs://shoot-in-patagonia.appspot.com/admin/servicios/otros/img_negocio'}/${v4()}`);
    await uploadBytes(storageRef, file);
    const urlImagen = await getDownloadURL(storageRef); // aqui se recoge la URL de la imagen subida y se retorna como string 
    return urlImagen;
}

export async function subirArchivo5(file) {
  const storageRef = ref(storage, `${'gs://shoot-in-patagonia.appspot.com/admin/blog/entradas/img_entradas_blog'}/${v4()}`);
  await uploadBytes(storageRef, file);
  const urlImagen = await getDownloadURL(storageRef); // aqui se recoge la URL de la imagen subida y se retorna como string 
  return urlImagen;
}

export async function subirArchivo6(files) {
  const urls = [];

  for (const file of files) {
    const storageRef = ref(storage, `gs://shoot-in-patagonia.appspot.com/admin/locacion/ingresadas/img_loc_ingresadas/${v4()}`);
    await uploadBytes(storageRef, file);
    const urlImagen = await getDownloadURL(storageRef);
    urls.push(urlImagen);
  }

  return urls;
}

export async function subirArchivo7(files) {
  const urls = [];

  for (const file of files) {
    const storageRef = ref(storage, `gs://shoot-in-patagonia.appspot.com/admin/locacion/ingresadas/portadas/${v4()}`);
    await uploadBytes(storageRef, file);
    const urlImagen = await getDownloadURL(storageRef);
    urls.push(urlImagen);
  }

  return urls;
}

// FORMATO PARA INGRESAR CABECERA A METADATOS DE LAS IMAGENES 
/* export async function subirArchivo6(files) {
  const urls = [];
 
  for (const file of files) {
     const storageRef = ref(storage, `gs://shoot-in-patagonia.appspot.com/admin/locacion/ingresadas/img_loc_ingresadas/${v4()}`);
 
     // Establece el encabezado 'Content-Type' aquí
     const metadata = { contentType: 'image/jpeg' };
     await uploadBytes(storageRef, file, metadata);
 
     const urlImagen = await getDownloadURL(storageRef);
     urls.push(urlImagen);
  }
 
  return urls;
 } */


// Obtén la instancia de autenticación
const auth = getAuth(app);

// Función para registrar un usuario
const signUp = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

// Función para iniciar sesión
const signIn = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export { auth, signUp, signIn };

// Función para recuperar contraseña
export async function resetPassword(email) {
  try {
    await sendPasswordResetEmail(auth, email);
    return true; // Éxito al enviar el correo de restablecimiento
  } catch (error) {
    console.error('Error al enviar el correo de restablecimiento:', error);
    return false; // Error al enviar el correo de restablecimiento
  }
}