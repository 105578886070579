import React, { useState } from 'react';
import Swal from 'sweetalert2';
import '../estilos/tarjeta_servicio_a.css';
import { DirectionsService } from '@react-google-maps/api';

const ServiceCard = ({ name, vacancies, unitCost, totalPersonas, onHire, blocked }) => {
  const [quantity, setQuantity] = useState(1);

  const handleHire = () => {
    if (isNaN(unitCost) || unitCost <= 0 || isNaN(quantity) || quantity <= 0) {
      Swal.fire('Error', 'El costo unitario y la cantidad deben ser números válidos y positivos', 'error');
      return;
    }

    if (quantity > vacancies) {
      Swal.fire('Error', 'La cantidad no puede ser mayor que las vacantes disponibles', 'error');
      return;
    }

    const CostoServicio = unitCost * quantity;

    Swal.fire({
      title: `¿Deseas agregar el servicio de ${name} por un valor de $${CostoServicio} para alojar a ${quantity} personas?`,
      showCancelButton: true,
      confirmButtonText: 'Sí, agregar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        onHire(CostoServicio, name, quantity);
        Swal.fire('Agregado!', 'El servicio ha sido agregado al carrito.', 'success');
      }
    });
  };

  const handleChangeQuantity = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setQuantity(value);
    } else {
      setQuantity(1);
    }
  };

  const costoUnitario = typeof unitCost === 'object' ? unitCost.props.costo_noche : unitCost;

  return (
    <div className={`card service-card mb-4 ${blocked ? 'blocked' : ''}`}>
      <div className='card-body'>
        <div className="row">
          <div className='col-xl-5'>
            <h5>{name}</h5>
            <p>Comuna</p>
          </div>

          <div className='col-xl-5'>
            <p>Vacantes: {vacancies}</p>
            <p>Costo unitario: ${costoUnitario}</p>
            <label htmlFor="quantity">Cantidad de personas:</label>
            <input className='form-control' type="number" id="quantity" value={quantity} onChange={handleChangeQuantity} min={1} max={totalPersonas} />
          </div>

          <div className='col-xl-2'>
            <button onClick={handleHire} className='btn btn-primary mt-4 mt-xl-0' disabled={blocked}>Agregar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
