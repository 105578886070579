import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig/firebase';
import './estilos/blogVista.css';

function BlogVista() {
  const [entrada, setEntrada] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const loadEntrada = async () => {
      const entradaDocRef = doc(db, 'blog_entrada', id);
      const entradaDoc = await getDoc(entradaDocRef);
      if (entradaDoc.exists()) {
        setEntrada(entradaDoc.data());
      } else {
        // Manejar la entrada no encontrada, por ejemplo, redirigir a una página de error.
      }
    };

    loadEntrada();
  }, [id]);

  if (!entrada) {
    return <p>Cargando...</p>;
  }

  return (
    <div>
      {/**<h1>Entrada de Blog</h1>*/}
      <div className="blog-vista">
        <div className="blog-vista-imagen">
          <img src={entrada.imagen} alt="Imagen de la entrada" style={{ maxWidth: '100%', maxHeight: '480px' }}/>
        </div>
        <div className="blog-vista-informacion">
          <h2 className="blog-vista-titulo">{entrada.titulo}</h2>
          <p className="blog-vista-fecha">
            Fecha de publicación: {entrada.fechaPublicacion.toDate().toLocaleDateString()}
          </p>
        </div>
      </div>
      <div
        className="blog-vista-contenido"
        dangerouslySetInnerHTML={{ __html: entrada.contenido }}
      />
    </div>
  );
}

export default BlogVista;
