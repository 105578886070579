import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../estilos/carruselVideo.css';
import videoSource1 from '../../videos/carruselInicio/SHP_PUERTO_AYSEN.mov';
import videoSource2 from '../../videos/carruselInicio/SHP_ESTANCIA_ÑIREGUAO.mov';
import videoSource3 from '../../videos/carruselInicio/SHP_VALLE_LUNAR.mov';
import videoSource4 from '../../videos/carruselInicio/SHP_COCHRANE.mov';
import videoSource5 from '../../videos/carruselInicio/SHP_COYHAIQUE_ALTO.mov';

export const CarouselComponent4 = () => {
  return (
    <div className="carousel-container">
      <Carousel autoPlay infiniteLoop showThumbs={true} showArrows={true} showStatus={false} showIndicators={false} transitionEffect="fade" emulateTouch >
        <div className="video-slide">
          <video controls className="video-player">
            <source src={videoSource1} type="video/quicktime" />
            <source src={videoSource1} type="video/mp4" />
            <source src={videoSource1} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="video-slide">
          <video controls className="video-player">
            <source src={videoSource2} type="video/quicktime" />
            <source src={videoSource2} type="video/mp4" />
            <source src={videoSource2} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="video-slide">
          <video controls className="video-player">
            <source src={videoSource3} type="video/quicktime" />
            <source src={videoSource3} type="video/mp4" />
            <source src={videoSource3} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="video-slide">
          <video controls className="video-player">
            <source src={videoSource4} type="video/quicktime" />
            <source src={videoSource4} type="video/mp4" />
            <source src={videoSource4} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="video-slide">
          <video controls className="video-player">
            <source src={videoSource5} type="video/quicktime" />
            <source src={videoSource5} type="video/mp4" />
            <source src={videoSource5} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>
      </Carousel>
    </div>
  );
};

export default CarouselComponent4;