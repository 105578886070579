import React, { useState, useEffect } from "react";
import { collection, getDocs } from 'firebase/firestore';
import { db } from "../../firebaseConfig/firebase";
import ServiceCard from "./tarjeta_servicio_g";
import Comidas, { mapeoTipoComida } from "./comidas";
//import '../estilos/cotizadorAlojamiento.css';
import CostoxServicio from "./CostoServicio";
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

const CotizadorGastronomico = ({ onNextStep, id, ciudadCercana, totalPersonas, onDataFromChild }) => {
  const { t } = useTranslation();
  const [servGastronomico, setServGastronomico] = useState([]);
  const [searchTerm, setSearchTerm] = useState(ciudadCercana);
  const [costoTotal, setCostoTotal] = useState(0);
  const [nombreServicio, setNombreServicio] = useState('');
  const [cantidadPersonas, setCantidadPersonas] = useState(0);
  const [serviciosCotizados, setServiciosCotizados] = useState([]);
  const [tipoServicioCotizado] = useState('gastronomico');
  const [selectedTipos, setSelectedTipos] = useState([]);

  const servGastronomicoCollection = collection(db, 'servicio_gastronomico');

  const getServGastronomico = async () => {
    const data = await getDocs(servGastronomicoCollection);
    setServGastronomico(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  useEffect(() => {
    getServGastronomico();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddService = (CostoServicio, NombreServicio, CantidadPersonas) => {
    setCostoTotal(prevTotal => prevTotal + CostoServicio);
    setNombreServicio(NombreServicio);
    setCantidadPersonas(CantidadPersonas);
    onDataFromChild({ tipoServicioCotizado, CostoServicio, NombreServicio, CantidadPersonas });

    setServiciosCotizados(prevServicios => [
      ...prevServicios,
      { NombreServicio, CostoServicio, CantidadPersonas }
    ]);
  };

  const handleTipoChange = (tipo) => {
    setSelectedTipos(prevSelected =>
      prevSelected.includes(tipo)
        ? prevSelected.filter(t => t !== tipo)
        : [...prevSelected, tipo]
    );
  };

  const filteredLocaciones = servGastronomico
    .filter((item) =>
      item.empresa.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.rut.includes(searchTerm) ||
      item.ciudad.includes(searchTerm)
    )
    .filter(item => {
      const allFalse = Object.values(item.valor_promedio).every(obj => obj.checked === false);
      return !allFalse;
    })
    .filter(item => {
      if (selectedTipos.length === 0) return true;
      return selectedTipos.some(tipo => item.definicion_negocio[tipo]?.checked);
    });

  return (
    <div className='services-container'> 
      <h3 className='pb-4'>{t('COTIZADOR_G.SERVICIO_DE_GASTRONOMIA')}</h3>
      <div className='search-bar'>
        <input
          type='text'
          placeholder='Buscar por nombre, ciudad o RUT'
          className='form-control'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className='row'>
      <Accordion className="p-0 pb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t('COTIZADOR_G.FILTRAR')}</Accordion.Header>
          <Accordion.Body>
          {[
            "ALMUERZOS", "AMASANDERIA", "ASADOS", "CAFÉTERIA", "CATERING", "CATERING ITINERANTE", "CENAS",
            "COCINA CHILENA", "COCINA DE AUTOR", "COCINA REGIONAL", "COMIDA CASERA", "COMIDA GOURMET", "COMIDA RÁPIDA",
            "COMIDA VEGANA", "COMIDA VEGETARIANA", "DELIVERY", "DESAYUNOS", "EMPANADAS", "ESPECIALIDAD EN CARNES",
            "HAMBURGUESAS", "OTRO", "PASTELERIA", "PIZZERIA", "RESTORAN", "SANDWICHERIA", "SUSHI"
          ].map(tipo => (
          <div key={tipo} className="badge p-0">
            <input
              type="checkbox"
              id={tipo}
              name={tipo}
              value={tipo}
              checked={selectedTipos.includes(tipo)}
              onChange={() => handleTipoChange(tipo)}
              style={{ display: 'none' }}
            />
            <label
              htmlFor={tipo}
              style={{
                padding: '4px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                cursor: 'pointer',
                backgroundColor: selectedTipos.includes(tipo) ? '#28a745' : '#fff',
                color: selectedTipos.includes(tipo) ? '#fff' : '#000',
                transition: 'background-color 0.3s ease',
                borderColor: selectedTipos.includes(tipo) ? '#28a745' : '#ccc',
                fontSize: '13px',
                float: 'left',
                marginLeft: '5px',
                display: 'block-inline',
              }}
            >
              {tipo}
            </label>
          </div>
          ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      
      </div>


      <div className='row service-list'>
        {filteredLocaciones.map((item) => {
          const unitCost = CostoxServicio({ costo_servicio: item.valor_promedio });
          const isCotizado = serviciosCotizados.some(serv => serv.NombreServicio === item.empresa); // Verificar si el servicio está cotizado
          const totalVacancies = Object.entries(item.capacidad_local)
            .filter(([tipo, info]) => info.checked && mapeoTipoComida[tipo] !== undefined)
            .reduce((total, [tipo, info]) => total + (info.cantidad * mapeoTipoComida[tipo]), 0);

          return (
            <ServiceCard
              key={item.id}
              name={item.empresa}
              Comidas={totalVacancies}
              tipoGastronomia={item.definicion_negocio}
              unitCost={unitCost}
              totalPersonas={totalPersonas}
              onHire={handleAddService}
              blocked={isCotizado} // Pasar el estado de bloqueo como prop
            />
          );
        })}
      </div>
    </div>
  );
};

export default CotizadorGastronomico;
