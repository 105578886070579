import React from 'react';
import '../estilos/barraProgreso.css';

const BarraProgreso = ({ currentStep, totalSteps, goToStep }) => {
    return (
        <div className="progress-bar">
            {Array.from({ length: totalSteps }, (_, index) => (
                <div
                    key={index}
                    className={`progress-step ${index < currentStep ? 'completed' : ''} ${index === currentStep ? 'current' : ''}`}
                    onClick={() => goToStep(index)}
                >
                    {index + 1}
                </div>
            ))}
        </div>
    );
    };

export default BarraProgreso;
