import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../paginas/estilos/previewCotizador.css';
import { ContactForm } from '../componentes/componentes_paginas/contacto2';

const PreviewCotizador = () => {
  const { state } = useLocation();
  const { t } = useTranslation(); // Importar la función de traducción
  const { nombreLocacion, totalPersonas, arregloServicios, costoLocacion, fechaInicio, fechaFin } = state;

  // Filtrar los servicios por tipo
  const serviciosAlojamiento = arregloServicios.filter(servicio => servicio.tipoServicioCotizado === 'alojamiento');
  const serviciosTransporte = arregloServicios.filter(servicio => servicio.tipoServicioCotizado === 'transporte');
  const serviciosGastronomia = arregloServicios.filter(servicio => servicio.tipoServicioCotizado === 'gastronomico');
  const serviciosOtros = arregloServicios.filter(servicio => servicio.tipoServicioCotizado === 'otros');

  // Convertir costoLocacion a número
  const costoLocacionNumber = parseFloat(costoLocacion.replace(/\./g, '').replace(/,/g, '.'));

  // Calcular el número de días entre fechaInicio y fechaFin
  const startDate = new Date(fechaInicio);
  const endDate = new Date(fechaFin);
  const diffTime = Math.abs(endDate - startDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24) + 1); // se agrega un día más para integrar el mismo día de la toma de servicio y no solo la diferencia entre inicio y término

  // Calcular el total de la cotización sumando los precios de todos los servicios multiplicados por el número de días
  const totalCostAlojamiento = serviciosAlojamiento.reduce((total, servicio) => total + servicio.CostoServicio, 0);
  const totalCostTransporte = serviciosTransporte.reduce((total, servicio) => total + servicio.CostoServicio, 0);
  const totalCostGastronomia = serviciosGastronomia.reduce((total, servicio) => total + servicio.CostoServicio, 0);
  const totalCostOtros = serviciosOtros.reduce((total, servicio) => total + servicio.CostoServicio, 0);
  const totalCost = (totalCostAlojamiento * diffDays) + (totalCostTransporte * diffDays) + (totalCostGastronomia * diffDays) + (totalCostOtros) + (costoLocacionNumber * diffDays);

  const cotizacion = {
    // datos generales de cotización
    nombreLocacion,
    totalPersonas,
    fechaInicio,
    fechaFin,
    diffDays,
    // capturados en servicios cotizados
    serviciosAlojamiento,
    serviciosTransporte,
    serviciosGastronomia,
    serviciosOtros,
    totalCostAlojamiento,
    totalCostTransporte,
    totalCostGastronomia,
    totalCostOtros,
    costoLocacionNumber,
    // costo total
    totalCost
  };

  return (
    <div className="preview">
      <div className="container-fluid bg-white">
        <div className='container pt-5'>
          <div className='row pt-4'>
            <div className='col-12 py-4'>
              <h1 className='pt-5'>{t('PREVIEW_COTIZADOR.PREVISUALIZACION_COTIZACION')}</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-3">
              <ul className="list-group mb-4">
                <li className="list-group-item">{t('PREVIEW_COTIZADOR.LOCACION')} <br></br><strong>{nombreLocacion}</strong></li>
                <li className="list-group-item">{t('PREVIEW_COTIZADOR.FECHA_INICIO')} <br></br><strong>{new Date(fechaInicio).toLocaleDateString()}</strong></li>
                <li className="list-group-item">{t('PREVIEW_COTIZADOR.FECHA_TERMINO')} <br></br><strong>{new Date(fechaFin).toLocaleDateString()}</strong></li>
                <li className="list-group-item">{t('PREVIEW_COTIZADOR.PARTICIPANTES')} <br></br><strong>{totalPersonas}</strong></li>
                <li className="list-group-item">{t('PREVIEW_COTIZADOR.DIAS')} <br></br><strong>{diffDays}</strong></li>
              </ul>
            </div>

            <div className='col-lg-8'>
              <div className='card mb-4'>
                <div className='card-body'>
                  <p className='fs-3 mb-0'>
                    {t('PREVIEW_COTIZADOR.COSTO_LOCACION')} <span className='float-end'>$<strong>{(costoLocacionNumber * diffDays).toLocaleString()}</strong></span><br></br>
                  </p>
                  <small>{t('PREVIEW_COTIZADOR.TOTAL_POR')}{ diffDays} {t('PREVIEW_COTIZADOR.DIAS')}</small>
                </div>
              </div>

              <div className='card mb-4'>
                <div className='card-body'>
                  {/* Tabla de Alojamiento */}
                  {serviciosAlojamiento.length > 0 && (
                    <div>
                      <h5>{t('PREVIEW_COTIZADOR.SERVICIO_ALOJAMIENTO')}</h5>
                      <table className="table mb-5">
                        <thead className="thead-dark">
                          <tr>
                            <th>{t('PREVIEW_COTIZADOR.PRESTADOR')}</th>
                            <th className="text-center">{t('PREVIEW_COTIZADOR.PERSONAS')}</th>
                            <th className="text-end">{t('PREVIEW_COTIZADOR.COSTO_DIARIO')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {serviciosAlojamiento.map((servicio, index) => (
                            <tr key={index}>
                              <td className='text-capitalize'>{servicio.NombreServicio}</td>
                              <td className="text-center">{servicio.CantidadPersonas}</td>
                              <td className="text-end">${servicio.CostoServicio.toLocaleString()}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr className="">
                            <td colSpan="2">{t('PREVIEW_COTIZADOR.SUB_TOTAL_POR')}{ diffDays } {t('PREVIEW_COTIZADOR.JORNADAS')}</td>
                            <td className="text-end">$<strong>{(totalCostAlojamiento * diffDays).toLocaleString()}</strong></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  )}

                  {/* Tabla de Transporte */}
                  {serviciosTransporte.length > 0 && (
                    <div>
                      <h5>{t('PREVIEW_COTIZADOR.SERVICIO_TRANSPORTE')}</h5>
                      <table className="table mb-5">
                        <thead>
                          <tr>
                            <th>{t('PREVIEW_COTIZADOR.SERVICIO')}</th>
                            <th>{t('PREVIEW_COTIZADOR.TIPO_VEHICULO')}</th>
                            <th className="text-center">{t('PREVIEW_COTIZADOR.CANTIDAD')}</th>
                            <th className="text-end">{t('PREVIEW_COTIZADOR.COSTO_DIARIO')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {serviciosTransporte.map((servicio, index) => (
                            <tr key={index}>
                              <td>{servicio.NombreServicio}</td>
                              <td>{servicio.TipoVehiculo}</td>
                              <td className="text-center">{servicio.CantidadPersonas}</td>
                              <td className="text-end">${servicio.CostoServicio.toLocaleString()}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr className="">
                            <td colSpan="3">{t('PREVIEW_COTIZADOR.SUB_TOTAL_POR')}{ diffDays } {t('PREVIEW_COTIZADOR.JORNADAS')}</td>
                            <td className="text-end">$<strong>{(totalCostTransporte * diffDays).toLocaleString()}</strong></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  )}

                  {/* Tabla de Gastronomía */}
                  {serviciosGastronomia.length > 0 && (
                    <div>
                      <h5>{t('PREVIEW_COTIZADOR.SERVICIO_GASTRONOMIA')}</h5>
                      <table className="table mb-5">
                        <thead>
                          <tr>
                            <th>{t('PREVIEW_COTIZADOR.SERVICIO')}</th>
                            <th className="text-center">{t('PREVIEW_COTIZADOR.COMENSALES')}</th>
                            <th className="text-end">{t('PREVIEW_COTIZADOR.COSTO_DIARIO')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {serviciosGastronomia.map((servicio, index) => (
                            <tr key={index}>
                              <td>{servicio.NombreServicio}</td>
                              <td className="text-center">{servicio.CantidadPersonas}</td>
                              <td className="text-end">${servicio.CostoServicio.toLocaleString()}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr className="">
                            <td colSpan="2">{t('PREVIEW_COTIZADOR.SUB_TOTAL_POR')}{ diffDays } {t('PREVIEW_COTIZADOR.JORNADAS')}</td>
                            <td className="text-end">$<strong>{(totalCostGastronomia * diffDays).toLocaleString()}</strong></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  )}

                  {/* Tabla de Otros Servicios */}
                  {serviciosOtros.length > 0 && (
                    <div>
                      <h5>{t('PREVIEW_COTIZADOR.OTROS_SERVICIOS')}</h5>
                      <table className="table mb-5">
                        <thead>
                          <tr>
                            <th>{t('PREVIEW_COTIZADOR.SERVICIO')}</th>
                            <th className="text-center">{t('PREVIEW_COTIZADOR.DIAS')}</th>
                            <th className="text-end">{t('PREVIEW_COTIZADOR.COSTO')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {serviciosOtros.map((servicio, index) => (
                            <tr key={index}>
                              <td>{servicio.NombreServicio}</td>
                              <td className="text-center">{servicio.CantidadPersonas}</td>
                              <td className="text-end">${servicio.CostoServicio.toLocaleString()}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr className="">
                            <td colSpan="2">{t('PREVIEW_COTIZADOR.SUB_TOTAL')}</td>
                            <td className="text-end">$<strong>{totalCostOtros.toLocaleString()}</strong></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  )}
                </div>
              </div>

              <div className='card mb-4 text-bg-dark'>
                <div className='card-body p-5'>
                  <p className='fs-3 mb-0'>
                    {t('PREVIEW_COTIZADOR.COSTO_TOTAL')} <span className='float-end'>$<strong>{totalCost.toLocaleString()}</strong></span><br></br>
                  </p>
                  <small>{t('PREVIEW_COTIZADOR.TOTAL_x')}{ diffDays } {t('PREVIEW_COTIZADOR.DIAS')} {t('PREVIEW_COTIZADOR.Y')} { totalPersonas } {t('PREVIEW_COTIZADOR.PERSONAS')}</small>
                </div>
              </div>

              <div className="alert alert-warning mb-4" role="alert">
                <h5 className="alert-heading"><i className="bi bi-exclamation-diamond-fill"></i> {t('PREVIEW_COTIZADOR.INFORMACION_IMPORTANTE')}</h5>
                <small>{t('PREVIEW_COTIZADOR.VALORES_PRESENTADOS')}</small>
                <hr></hr>
                <small className="mb-0">{t('PREVIEW_COTIZADOR.INFORMACION_DETALLADA')}</small>
              </div>

              <div className="card mb-4 p-4">
                <div className='card-body'>
                  <h3>{t('PREVIEW_COTIZADOR.CONSIGUE_PRESUPUESTO')}</h3>
                  <p className='lead pb-4'>{t('PREVIEW_COTIZADOR.RECIBE_COTIZACION')}</p>
                  <ContactForm cotizacion={cotizacion} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-white">
        <div className="container py-5">
          <div className="row">
            <div className="col">
              <div className="card text-bg-light border-light-subtle mb-4 mb-lg-0">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="p-2">
                      <div className="bg-black rounded-4 text-white icon-box text-center pt-3 fs-1"><i className="bi bi-geo"></i></div>
                    </div>
                    <div className="p-2 flex-grow-1">
                      <h3><strong>{t('PREVIEW_COTIZADOR.LOCATIONS')}</strong></h3>
                      <p>{t('PREVIEW_COTIZADOR.LOCATIONS_DESC')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col">
              <div className="card text-bg-light border-light-subtle mb-4 mb-lg-0">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="p-2">
                      <div className="bg-black rounded-4 text-white icon-box text-center pt-3 fs-1"><i className="bi bi-box-seam"></i></div>
                    </div>
                    <div className="p-2 flex-grow-1">
                      <h3><strong>{t('PREVIEW_COTIZADOR.LOGISTICS')}</strong></h3>
                      <p>{t('PREVIEW_COTIZADOR.LOGISTICS_DESC')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card text-bg-light border-light-subtle">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="p-2">
                      <div className="bg-black rounded-4 text-white icon-box text-center pt-3 fs-1"><i className="bi bi-camera-reels"></i></div>
                    </div>
                    <div className="p-2 flex-grow-1">
                      <h3><strong>{t('PREVIEW_COTIZADOR.FIXERS')}</strong></h3>
                      <p>{t('PREVIEW_COTIZADOR.FIXERS_DESC')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewCotizador;
