import React from 'react';
import './estilos/nosotros.css';
import BannerServices from '../componentes/componentes_paginas/bannerServices';
import { ContactForm } from '../componentes/componentes_paginas/contacto3';
import { useTranslation } from 'react-i18next';

function Nosotros() {
  const { t } = useTranslation();

  return (
    <div className="nosotros">
      <div className="container-fluid bg-white">
        <div className='container pt-5'>
          <div className='row pt-4'>
            <div className='col-12 py-4'>
              <h1 className='pt-5'>{t('NOSOTROS.SOBRE')} <strong>{t('NOSOTROS.NOSOTROS')}</strong></h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-7">
              <h2 className='pb-4'>{t('NOSOTROS.MISION_TITULO')}</h2>
              <p>{t('NOSOTROS.MISION_DESCRIPCION')}</p>

              <h2 className='py-4'>{t('NOSOTROS.VISION_TITULO')}</h2>
              <p>{t('NOSOTROS.VISION_DESCRIPCION')}</p>

              <h2 className='py-4'>{t('NOSOTROS.HISTORIA_TITULO')}</h2>
              <p>{t('NOSOTROS.HISTORIA_DESCRIPCION')} <a href='https://www.catarsis.cl/' target='blank'>Catarsis Films</a>.</p>
            </div>

            <div className="col-md-5 contact-about-us">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>

      <BannerServices/>
    </div>
  );
}

export default Nosotros;
