import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
//import { useParams } from 'react-router-dom';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from "../firebaseConfig/firebase";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

const VerLocacion = () => {
 const [locacion, setLocacion] = useState( [] );   
 const [buscador, setBuscador] = useState('');   
 //const { id } = useParams(); // Obtiene el id de la locación desde la URL
 const locacionCollection = collection(db,'locacion');

 const getLocacion = async () => {
   const data = await getDocs(locacionCollection);
   setLocacion(
       data.docs.map( (doc) => ( {...doc.data(),id:doc.id} ))
   )
	 console.log("RENDERIZA ESTO");
   console.log(locacion);
}

useEffect( () => {
   getLocacion()
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [] )

    //4.- creamos una funcion para eliminar un documento
    const deleteLocacion = async (id) => {
			const locacionDoc = doc(db, 'locacion', id)
			await deleteDoc(locacionDoc)
			getLocacion();
		}

 //5.- creamos una funcion de confirmacion para sweetAlert
 const confirmarDelete = (id, tipoServicio) => {
	let deleteFunc;

	switch (tipoServicio) {
		case 'locacion':
			deleteFunc = deleteLocacion;
			break;
		default:
			console.log('Tipo de servicio no válido');
			return;
	}

	MySwal.fire({
		title: 'Estas seguro?',
		text: "no se podrá revertir esto!",
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: 'Si, Borrarlo!'
	}).then((result) => {
		if (result.isConfirmed) {
			deleteFunc(id)
			Swal.fire(
				'Borrado!',
				'La locacion a sido borrada de la base de datos.',
				'success'
			)
		}
	})
}

 const renderTableRows = (data, tipoServicio) => {
	return data
		.filter((item) =>
			item.nombre.toLowerCase().includes(buscador.toLowerCase()) ||
			item.ecosistema.includes(buscador)  
		)
		.map((item) => (
			<tr key={item.id}>
				<td>{tipoServicio}</td>
				<td>{item.nombre}</td>
				<td>{item.ecosistema}</td>
				<td>
					{/* Mostrar la imagen */}
					<img src={item.portadaLoc} alt={`Imagen de ${item.nombre}`} style={{ maxWidth: '100px', maxHeight: '100px' }} />
				</td>
				<td>
          <Link to={`/editar_locacion/${item.id}/${tipoServicio}`} className="btn btn-light"><i className="fas fa-pencil-alt"></i></Link>
          <button onClick={() => confirmarDelete(item.id, tipoServicio)} className="btn btn-danger"><i className="fa-regular fa-trash-can"></i></button>
        </td>
			</tr>
	));
}

 // A partir de aquí, puedes renderizar la información de la locación según lo que necesites
 return(
	<div className='container'>
        <div className='row pt-5'>
        	<div className='col-12 py-5'>
                <h1 className='pt-5'>Admin <strong>Locaciones</strong></h1>
            </div>
        </div>
	
		<div className='row'>
			<div className='col'>
				<div className='d-grid gap-2'>
           			<Link to='/crear' className='btn btn-secondary mt-2 mb-2'><i class="fa-solid fa-images"></i>	CREAR LOCACION</Link>
              	</div>
				
				<div className='d-flex justify-content-between mb-3'>
					<input type='text' placeholder='Buscar por nombre o ecosistema' className='form-control' value={buscador} onChange={(e) => setBuscador(e.target.value)} />
				</div>
				
				{buscador !== "" && (
				<table className='table table-dark table-hover '>
					<thead>
						<tr>
							<th>TIPO</th>
							<th>NOMBRE</th>
							<th>ECOSISTEMA</th>
							<th>PORTADA</th>
							<th>Acciones</th>
						</tr>
					</thead>
					<tbody>
						{renderTableRows(locacion, 'locacion')}

					</tbody>
				</table>
				)}
			</div>
		</div>  
	</div>
)
};

export default VerLocacion;