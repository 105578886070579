import React from 'react';

const Vehiculos = ({ vehiculos_tipo_cantidad }) => {
  // Filtrar los vehículos disponibles con una cantidad mayor a cero
  const vehiculosDisponibles = Object.entries(vehiculos_tipo_cantidad)
    .filter(([tipo, info]) => info.cantidad > 0)
    .map(([tipo, info]) => ({ tipo, cantidad: info.cantidad }));

  return (
    <ul>
      {vehiculosDisponibles.map(({ tipo, cantidad }) => (
        <li key={tipo}>
          {tipo}: {cantidad}
        </li>
      ))}
    </ul>
  );
};

export default Vehiculos;
