import React from 'react';

// Define un mapeo para los tipos de comida
export const mapeoTipoComida = {
  ALMUERZOS: 'Almuerzos',
  AMASANDERIA: 'Amasandería',
  ASADOS: 'Asados',
  CAFETERIA: 'Cafetería',
  CATERING: 'Catering',
  CATERING_ITINERANTE: 'Catering Itinerante',
  CENAS: 'Cenas',
  COCINA_CHILENA: 'Cocina Chilena',
  COCINA_DE_AUTOR: 'Cocina de Autor',
  COCINA_REGIONAL: 'Cocina Regional',
  COMIDA_CASERA: 'Comida Casera',
  COMIDA_GOURMET: 'Comida Gourmet',
  COMIDA_RAPIDA: 'Comida Rápida',
  COMIDA_VEGANA: 'Comida Vegana',
  COMIDA_VEGETARIANA: 'Comida Vegetariana',
  DELIVERY: 'Delivery',
  DESAYUNOS: 'Desayunos',
  EMPANADAS: 'Empanadas',
  ESPECIALIDAD_EN_CARNES: 'Especialidad en Carnes',
  HAMBURGUESAS: 'Hamburguesas',
  OTRO: 'Otro',
  PASTELERIA: 'Pastelería',
  PIZZERIA: 'Pizzería',
  RESTORAN: 'Restorán',
  SANDWICHERIA: 'Sandwichería',
  SUSHI: 'Sushi'
};

const Comidas = ({ comida_tipo_cantidad }) => {
  // Filtrar los tipos de comida disponibles con cantidad mayor a cero
  const comidasDisponibles = Object.entries(comida_tipo_cantidad)
    .filter(([tipo, info]) => info.checked && info.cantidad > 0)
    .map(([tipo, info]) => ({ tipo: mapeoTipoComida[tipo] || tipo, cantidad: info.cantidad }));

  return (
    <ul>
      {comidasDisponibles.map(({ tipo, cantidad }) => (
        <li key={tipo}>
          {tipo}: {cantidad}
        </li>
      ))}
    </ul>
  );
};

export default Comidas;
