import React, { useState, useEffect } from 'react';
import FechaVisita from './fechaVisita.js';
import CotizadorAlojamiento from './cotizadorAlojamiento.js';
import Calculadora from './calculadora.js';
import Carrito from './carrito.js';
import { useParams, useNavigate } from 'react-router-dom';
import PantallaCarga from '../../paginas/pantallaCarga.js';
import BarraProgreso from './barraProgreso.js';
import { doc, getDoc } from 'firebase/firestore';
import { db } from "../../firebaseConfig/firebase";
import '../estilos/cotizador.css';
import CotizadorTransporte from './cotizadorTransporte.js';
import CotizadorGastronomico from './cotizadorGastronomico.js';
import CotizadorOtros from './cotizadorOtros.js';
import { Data } from '@react-google-maps/api';
import { useTranslation } from "react-i18next";


const Cotizador = () => {
  const { t } = useTranslation();
  const { id, ciudadCercana } = useParams();
  const [step, setStep] = useState(0);
  const [locacion, setLocacion] = useState(null);
  const [formData, setFormData] = useState(null);
  
  const [CostoServicio, setCostoServicio] = useState(0);
  const [nombreServicio, setNombreServicio] = useState('');
  const [cantidadPersonas, setCantidadPersonas] = useState(0);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [serviciosCotizados, setServiciosCotizados] = useState([]);
  const [tipoServicio, setTipoServicio] = useState('');
  const [arregloServicios, setArregloServicio] = useState([]);

  const navigate = useNavigate();
  
  const [showStep1, setShowStep1] = useState(true);
  const [showSidebar, setShowSidebar] = useState(true);

  const handleDataFromChild = (data) => {
    console.log('Datos recibidos del hijo:', data);
    setNombreServicio(data.NombreServicio);
    setCostoServicio(data.CostoServicio);
    setCantidadPersonas(data.CantidadPersonas);
    setTipoServicio(data.tipoServicioCotizado);
    setArregloServicio(prevArreglo => [...prevArreglo, data]);
  };

  const handleRemoveService = (serviceName) => {
    setServiciosCotizados(prevServices => prevServices.filter(serv => serv !== serviceName));
  };

  useEffect(() => {
    const fetchLocacion = async () => {
      try {
        const locacionRef = doc(db, 'locacion', id);
        const locacionSnapshot = await getDoc(locacionRef);
        if (locacionSnapshot.exists()) {
          setLocacion({ ...locacionSnapshot.data(), id: locacionSnapshot.id });
        } else {
          console.log('La locación no existe');
        }
      } catch (error) {
        console.error('Error al obtener la locación:', error);
      }
    };
    if (id) {
      fetchLocacion();
    }
  }, [id]);

  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  const handleNextStep = (data) => {
    if (!formData) {
      setFormData(data);
    }
    setStep(prevStep => {
      const newStep = prevStep + 1;
      if (newStep > 0) {
        setShowStep1(false);
        setShowSidebar(true);
      }
      if(newStep > 4) {
        handleEnviar(); // Llama a la función handleEnviar
      }
      return newStep;
    });
  };

  const handleGoToStep = (stepIndex) => {
    setStep(stepIndex);
  };
  
  if (!locacion) {
    return <PantallaCarga />;
  }

  const handleEnviar = () => {
    navigate('../../../previewCotizador', {
      state: {
        nombreLocacion: locacion.nombre,
        totalPersonas: formData.personas,
        arregloServicios: arregloServicios,
        costoLocacion: locacion.permiso_coste,
        fechaInicio: formData.startDate,
        fechaFin: formData.endDate
      }
    });
  };

  function formatCost(value) {
    if (!value.startsWith('$')) {
      value = `$${value}`;
    }
    value = value.replace(/\s/g, '');
    const [integerPart, decimalPart = ''] = value.split('.');
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const formattedValue = `${formattedIntegerPart}${decimalPart ? `.${decimalPart}` : ''}`;
    return formattedValue;
  }
  
  const formattedCost = formatCost(locacion.permiso_coste);

  return (
    <div className="cotizador-container">
      <div className="container-fluid bg-white">
        <div className='container pt-5'>
          <div className='row pt-4'>
            <div className='col-12 py-4'>
              <h1 className='pt-5'>{t('COTIZADOR.TITULO')} {locacion.nombre}</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light">
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 py-4'>
              <div className='card p-4'>
                <div className='card-body'>
                  <div className='row pb-5'>
                    <div className='col-6'>
                      <BarraProgreso currentStep={step} totalSteps={5} goToStep={handleGoToStep} />
                    </div>

                    <div className='col-6'>
                    {step > 0 && (
                        <button onClick={handleNextStep} className='btn btn-primary rounded-pill float-end'>{t('COTIZADOR.SIGUIENTE')}</button>
                      )}
                    </div>
                  </div>
                  
                  {showStep1 && ( <FechaVisita onNextStep={handleNextStep} setFormData={setFormData} /> )}

                  {step === 1 && formData && (
                  <CotizadorAlojamiento
                    id={id}
                    ciudadCercana={ciudadCercana}
                    totalPersonas={formData.personas}
                    onNextStep={handleNextStep}
                    onDataFromChild={handleDataFromChild}
                    serviciosCotizados={serviciosCotizados}
                  />
                  )}

                  {step === 2 && formData && (
                  <CotizadorTransporte
                    id={id}
                    ciudadCercana={ciudadCercana}
                    totalPersonas={formData.personas}
                    onNextStep={handleNextStep}
                    onDataFromChild={handleDataFromChild}
                    serviciosCotizados={serviciosCotizados}
                  />
                  )}

                  {step === 3 && formData && (
                  <CotizadorGastronomico
                    id={id}
                    ciudadCercana={ciudadCercana}
                    totalPersonas={formData.personas}
                    onNextStep={handleNextStep}
                    onDataFromChild={handleDataFromChild}
                    serviciosCotizados={serviciosCotizados}
                  />
                  )}

                  {step === 4 && formData && (
                    <CotizadorOtros
                      id={id}
                      ciudadCercana={ciudadCercana}
                      totalPersonas={formData.personas}
                      onNextStep={handleNextStep}
                      onDataFromChild={handleDataFromChild}
                      serviciosCotizados={serviciosCotizados}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className='col-lg-4 py-4'>
              <div className='card'>
                  {showSidebar && (
                  <div className={`${isSidebarExpanded ? 'expanded' : 'collapsed'}`}>
                    {isSidebarExpanded && (
                      <>
                      {formData && (
                      <div>
                        <h3 className='p-3 pt-4'>{t('COTIZADOR.COTIZADOR')}</h3>
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">{t('COTIZADOR.INICIO')}: {formData.startDate ? formData.startDate.toLocaleDateString() : ''}</li>
                          <li className="list-group-item">{t('COTIZADOR.TERMINO')}: {formData.endDate ? formData.endDate.toLocaleDateString() : ''}</li>
                          <li className="list-group-item">{t('COTIZADOR.TOTAL_PERSONAS')}: {formData.personas}</li>
                        </ul>
                        
                        <Carrito
                          CostoServicio={CostoServicio}
                          NombreServicio={nombreServicio}
                          CantidadPersonas={cantidadPersonas}
                          onRemoveService={handleRemoveService}
                          arregloServicio={arregloServicios}
                          tipoServicio={tipoServicio}
                          fechaInicio={formData.startDate}
                          fechaFin={formData.endDate}
                        />
                        
                        <Calculadora 
                          CostoServicio={CostoServicio}
                          tipoServicio={tipoServicio} 
                          PermisoLocacion={formattedCost}
                          fechaInicio={formData.startDate}
                          fechaFin={formData.endDate}
                        />
                        {/* <button onClick={handleEnviar}>{t('COTIZADOR.ENVIAR')}</button> */}
                      </div>
                      )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

  <div className="container-fluid bg-white">
    <div className="container py-5">
      <div className="row">
        <div className="col">
          <div className="card text-bg-light border-light-subtle mb-4 mb-lg-0">
            <div className="card-body">
              <div className="d-flex">
                <div className="p-2"><div className="bg-black rounded-4 text-white icon-box text-center pt-3 fs-1"><i className="bi bi-geo"></i></div></div>
                <div className="p-2 flex-grow-1">
                  <h3><strong>{t('COTIZADOR.LOCATIONS')}</strong></h3>
                  <p>{t('COTIZADOR.LOCATIONS_DESC')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="col">
          <div className="card text-bg-light border-light-subtle mb-4 mb-lg-0">
            <div className="card-body">
              <div className="d-flex">
                <div className="p-2"><div className="bg-black rounded-4 text-white icon-box text-center pt-3 fs-1"><i className="bi bi-box-seam"></i></div></div>
                <div className="p-2 flex-grow-1">
                  <h3><strong>{t('COTIZADOR.LOGISTICS')}</strong></h3>
                  <p>{t('COTIZADOR.LOGISTICS_DESC')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="card text-bg-light border-light-subtle">
            <div className="card-body">
              <div className="d-flex">
                <div className="p-2"><div className="bg-black rounded-4 text-white icon-box text-center pt-3 fs-1"><i className="bi bi-camera-reels"></i></div></div>
                <div className="p-2 flex-grow-1">
                  <h3><strong>{t('COTIZADOR.FIXERS')}</strong></h3>
                  <p>{t('COTIZADOR.FIXERS_DESC')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  );
};

export default Cotizador;
