import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {collection, addDoc} from 'firebase/firestore';
import { db, subirArchivo } from "../firebaseConfig/firebase";

import Swal from 'sweetalert2';

//5.- creamos una funcion de confirmacion para sweetAlert


const Servicio = () => {

  const [currentPage, setCurrentPage] = useState(1);
  //seteo de datos DESCIPTIVOS para el servicio
  //const [fecha_inscripcion, setFecha_inscripcion] = useState('');
  const [email, setEmail] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [encargado, setEncargado] = useState(''); 
  const [rut, setRut] = useState('');
  const [email_encargado, setEmail_encargado] = useState('');
  const [telefono, setTelefono] = useState('');
  const [celular, setCelular] = useState('');
  const [presencia_red, setPresencia_red] = useState('');
  const [direccion, setDireccion] = useState('');
  const [ciudad, setCiudad] = useState('ciudades de la region ...');
  //const [definicion_negocio, setDef_negocio] = useState('');
  const [definicion_negocio, setDef_negocio] = useState({
    HOTEL: false,
    LODGE: false,
    CABANAS: false,
    RESIDENCIAL: false,
    CAMPING: false,
    REFUGIO_ALBERGUE: false,
    GLAMPING: false,
    TINY_HOUSE: false,
    APARTHOTEL: false,
    DEPARTAMENTO: false,
    HOSTAL:false
  });
  
  const [antiguedad, setAntiguedad] = useState('');
  const [sello_calidad, setSello_calidad] = useState('');
  const [imagen, setImagen] = useState(null);
  //const [img_referencia, setImg_referencia] = useState('');

  //seteo de datos OPERATIVOS del servicio
  const [camas_tipo_cantidad, setCama_tipo_cantidad] = useState({
    UNA_PLAZA: { checked: false, cantidad: 0},
    UNAYMEDIA_PLAZA: { checked: false, cantidad: 0},
    DOS_PLAZA: { checked: false, cantidad: 0},
    KING: { checked: false, cantidad: 0},
    SUPER_KING: { checked: false, cantidad: 0},
    LITERA: { checked: false, cantidad: 0},
  });
  const [calidad, setCalidad] = useState('');
  const [internet, setInternet] = useState(false);
  const [desayuno, setDesayuno] = useState(false);
  const [almuerzo, setAlmuerzo] = useState(false);
  const [cena, setCena] = useState(false);
  const [otra_alimentacion, setOtra_alimentacion] = useState('');
  const [servicio_limpieza, setServicio_limpieza] = useState(false);
  const [extras, setExtras] = useState('');
  const [calificacion_clientes, setCalif_clientes] = useState('');

  //seteo datos si tiene CAMPING
  const [camping_internet, setCamping_internet] = useState(false);
  const [camping_ducha, setCamping_ducha] = useState(false);
  const [camping_cocina, setCamping_cocina] = useState(false);
  const [camping_lavanderia, setCamping_lavanderia] = useState(false);
  const [camping_electricidad, setCamping_electricidad] = useState(false);
  const [camping_estacionamiento, setCamping_estacionamiento] = useState(false);
  const [camping_baño, setCamping_baño] = useState(false);
  const [camping_valor, setCamping_valor] = useState('');
  const [camping_servicios_extra, setCamping_extra] = useState('');

  //seteo más datos OPERATIVOS del servicio
  const [idiomas, setIdiomas] = useState('');
  const [valor_noche, setValor_noche] = useState({
    '25.000 35.000': { checked: false},
    '35.000 50.000': { checked: false},
    '50.000 75.000': { checked: false},
    '75.000 100.000': { checked: false},
    '100.000 150.000': { checked: false},
    '150.000 200.000': { checked: false},
    'OTRO': { checked: false, otro:''},
  });
  const [temporada_alta, setTemporada_altra] = useState('');
  const [capacidad_temp_alta, setCapacidad_temp_alta] = useState('');
  const [cuenta_bancaria, setCuenta_bancaria] = useState('');
  const [forma_pago, setForma_pago] = useState({
    'CONTADO': { checked: false},
    '50% A LA RESERVA, 50% AL CHECK-IN ': { checked: false},
    '50% ANTICIPO, SALDO FACTURA A 30 DIAS': { checked: false},
    'OTRO': { checked: false, otro:''},
  });
  const [sistema_pago, setSistema_pago] = useState({
    'TRASFERENCIA ELECTRONICA': { checked: false},
    'TARJETA DE CREDITO': { checked: false},
    'DEBITO': { checked: false},
    'CHEQUES': { checked: false},
    'EFECTIVO': { checked: false},
    'PAYPAL': { checked: false},
    'CRYPTO MONEDAS': {checked: false},
    'OTRO': { checked: false, otro:''},
  });
  const [software, setSoftware] = useState('');
  const [pagar_10, setPagar_10] = useState('');

  const navigate = useNavigate();

  // DEFINIENDO CAPTUAS DE ESTADOS 

  //HANDLER DE CHECKBOX TIPO DE NEGOCIO
  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setDef_negocio((prevDefinicion) => ({
      ...prevDefinicion,
      [id]: checked,
    }));
  };

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const previousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const serv_alojamientoCollection = collection(db, 'servicio_alojamiento');
  const store = async (e) => {
    e.preventDefault();
    try{

      const camposAValidar = [
        { campo: empresa, nombreCampo: 'Nombre Empresa' },
        { campo: direccion, nombreCampo: 'Dirección' },
        { campo: ciudad, nombreCampo: 'Ciudad' },
        // Agrega más campos aquí
    ];

    for (const campoInfo of camposAValidar) {
        if (!campoInfo.campo || campoInfo.campo.trim() === "" ) {
            throw Swal.fire(`El campo ${campoInfo.nombreCampo} no puede estar vacío o debe seleccionar al menos un valor`);
        }
    }
      const resultado = await subirArchivo(imagen); //aqui se sube la imagen de referencia del negocio al storage firebase : consultar firebase.js
      console.log(resultado)
      await addDoc (serv_alojamientoCollection, {

      //almacenamiento de datos DESCRIPTIVOS 

      email:email,
      empresa:empresa,
      encargado:encargado,
      rut:rut,
      email_encargado:email_encargado,
      telefono:telefono,
      celular:celular,
      presencia_red:presencia_red,
      direccion:direccion,
      ciudad:ciudad,
      definicion_negocio:definicion_negocio,
      antiguedad:antiguedad,
      sello_calidad:sello_calidad,
      imagen:resultado,
      

      //almacenamiento de datos OPERATIVOS
      camas_tipo_cantidad:camas_tipo_cantidad,
      calidad:calidad,
      desayuno:desayuno,
      almuerzo:almuerzo,
      cena:cena,
      otra_alimentacion:otra_alimentacion,
      servicio_limpieza:servicio_limpieza,
      extras:extras,
      calificacion_clientes:calificacion_clientes,

      //almacenamiento de datos CAMPING
      camping_internet:camping_internet,
      camping_ducha:camping_ducha,
      camping_cocina:camping_cocina,
      camping_lavanderia:camping_lavanderia,
      camping_electricidad:camping_electricidad,
      camping_estacionamiento:camping_estacionamiento,
      camping_baño:camping_baño,
      camping_valor:camping_valor,
      camping_servicios_extra:camping_servicios_extra,

      //almacenamiento de datos OPERATIVOS Y COSTOS
      idiomas:idiomas,
      valor_noche:valor_noche,
      temporada_alta:temporada_alta,
      capacidad_temp_alta:capacidad_temp_alta,
      cuenta_bancaria:cuenta_bancaria,
      forma_pago:forma_pago,
      sistema_pago:sistema_pago,
      software:software,
      pagar_10:pagar_10

    })
    Swal.fire('Se Agrego un nuevo servicio de alojamiento')
    navigate('/ver');

    }catch (error){
      console.error(error);
      alert('fallo al subir imagen , intente nuevamente ');
    }

  }
  return(
    <div className="container">
      <div className='row pt-5'>
        <div className='col-12 py-5'>
          <h1 className='pt-5'>Admin <strong>alojamiento</strong></h1>
        </div>
      </div>

      {currentPage === 1 && (
        <div className="div-descriptivos row">
          {/** DATOS DESCRIPTIVOS DEL SERVICIO*/}
          <label className='h3 mb-5'>DATOS SOBRE EL SERVICIO</label>
            <div className='mb-3'>
              <label className='form-label'>INGRESE EL NOMBRE DE LA EMPRESA</label>
                <input
                  value={empresa}
                  onChange={ (e) => setEmpresa(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'><i className="fa-solid fa-at"></i> E-MAIL DE LA EMPRESA</label>
                <input
                  value={email}
                  onChange={ (e) => setEmail(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className="form-label">¿COMO SE DEFINE EL NEGOCIO?</label>
              <div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="HOTEL"
                  checked={definicion_negocio.HOTEL}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox1">HOTEL</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="LODGE"
                  checked={definicion_negocio.LODGE}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox2">LODGE</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="CABANAS"
                  checked={definicion_negocio.CABANAS}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox3">CABAÑAS </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="RESIDENCIAL"
                  checked={definicion_negocio.RESIDENCIAL}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox3">RESIDENCIAL </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="CAMPING"
                  checked={definicion_negocio.CAMPING}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox3">CAMPING </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="REFUGIO_ALBERGUE"
                  checked={definicion_negocio.REFUGIO_ALBERGUE}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox3">REFUGIO /ALBERGUE </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="GLAMPING"
                  checked={definicion_negocio.GLAMPING}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox3">GLAMPING </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="TINY_HOUSE"
                  checked={definicion_negocio.TINY_HOUSE}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox3">TINY HOUSE </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="APARTHOTEL"
                  checked={definicion_negocio.APARTHOTEL}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox3">APARTHOTEL </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="DEPARTAMENTO"
                  checked={definicion_negocio.DEPARTAMENTO}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox3">DEPARTAMENTO </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="HOSTAL"
                  checked={definicion_negocio.HOSTAL}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox3">HOSTAL </label>
              </div>
              </div>
            </div>

            <div className='mb-3'>
              <label className='form-label'>NOMBRE DE ENCARGADO O ADMINITRADOR</label>
                <input
                  value={encargado}
                  onChange={ (e) => setEncargado(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>RUT</label>
                <input
                  value={rut}
                  onChange={ (e) => setRut(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'><i className="fa-solid fa-at"></i> E-MAIL ENCARGADO</label>
                <input
                  value={email_encargado}
                  onChange={ (e) => setEmail_encargado(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'><i className="fa-solid fa-phone"></i> TELEFONO FIJO (copiar/pegar)</label>
              <input
                value={telefono}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.startsWith("+67 ")) {
                    setTelefono(inputValue);
                  } else {
                    setTelefono("+67 " + inputValue);
                  }
                }}
                type='text'
                className='form-control'
              />
            </div>
            <div className='mb-3'>
              <label className='form-label'><i className="fa-solid fa-mobile-screen-button"></i> TELEFONO CELULAR (copiar/pegar)</label>
              <input
                value={celular}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.startsWith("+569 ")) {
                    setCelular(inputValue);
                  } else {
                    setCelular("+569 " + inputValue);
                  }
                }}
                type='text'
                className='form-control'
              />
            </div>

            <div className='mb-3'>
              <label className='form-label'><i className="fa-solid fa-image"></i> FOTO O IMAGEN REFERENCIAL DEL NEGOCIO</label>
                <input
                  files={[0]}
                  onChange={ (e) => setImagen(e.target.files[0])}
                  type='file'
                  className='form-control'
                />
            </div>

            <div className='mb-3'>
              <label className="form-label">¿TIENE PRESENCIA EN INTERNET?</label>
                <input
                  value={presencia_red}
                  onChange={ (e) => setPresencia_red(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>DIRECCION COMERCIAL</label>
                <input
                  value={direccion}
                  onChange={ (e) => setDireccion(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'> CIUDAD </label>
                <select value={ciudad} onChange={(e) => setCiudad(e.target.value)} className='form-select' aria-label='Default select example'>
                  <option value='ciudades de la region ...'>seleccione una ciudad...</option>
                  <option value='coyhaique'>Coyhaique</option>
                  <option value='aysen'>Puerto Aysen</option>
                  <option value='chacabuco'>Puerto Chacabuco</option>
                  <option value='puerto_cisnes'>Puerto Cisnes</option>
                  <option value='melinka'>Melinka</option>
                  <option value='puerto_aguirre'>Puerto Aguirre</option>
                  <option value='chile_chico'>Chile Chico</option>
                  <option value='guadal'>Puerto Guadal</option>
                  <option value='puerto_betrand'>Puerto Bertrand</option>
                  <option value='tranquilo'>Puerto Rio Tranquilo</option>
                  <option value='bahia_murta'>Bahía Murta</option>
                  <option value='puerto_sanchez'>Puerto Sanchez</option>
                  <option value='cochrane'>Cochrane</option>
                  <option value='tortel'>Tortel</option>
                  <option value='villa_ohiggins'>Villar O'higgins</option>
                  <option value='balmaceda'>Balmaceda</option>
                  <option value='cerro_castillo'>Cerro_castillo</option>
                  <option value='puerto_ibañez'>Puerto Ibañez</option>
                  <option value='mañihuales'>Mañihuales</option>
                  <option value='villa_amengual'>Villa Amengual</option>
                  <option value='villa_ortega'>Villa Ortega</option>
                  <option value='lago_verde'>Lago Verde</option>
                  <option value='puyuhuapi'>Puyuhuapi</option>
                  <option value='la_junta'>La Junta</option>
                  <option value='raul_marin_balmaceda'>Raúl Marín Balmaceda</option>
                  <option value='futaleufu'>Futaleufu</option>
                  <option value='chaiten'>Chaiten</option>
              
                </select>
            </div>
        </div>
      )}

      {currentPage === 2 && (
        <div className="div-calificaciones row">
         {/** CALIFICAIONES */}
         <label className='h3'><i className="fa-solid fa-ranking-star"></i> ANTIGUEDAD Y CALIFICACIONES</label>
            <div className='mt-4 mb-3'>
              <label className='form-label'>ANTIGUEDAD DEL NEGOCIO</label>
                <input
                  value={antiguedad}
                  onChange={ (e) => setAntiguedad(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>CUENTA CON ALGUN SELLO DE CALIDAD</label>
                <input
                  value={sello_calidad}
                  onChange={ (e) => setSello_calidad(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'> CALIFICACIONES DE CLIENTES </label>
                <select value={calificacion_clientes} onChange={(e) => setCalif_clientes(e.target.value)} className='form-select' aria-label='Default select example'>
                  <option value='sin calificion'>calificaciones...</option>
                  <option value='5'>***** 5 estrellas</option>
                  <option value='4'>**** 4 estrellas</option>
                  <option value='3'>*** 3 estrellas</option>
                  <option value='2'>** 2 estrellas</option>
                </select>
            </div>
          </div>
      )}

      {currentPage === 3 && (
        <div className="row">
          {/** SERVICIOS PRESENTES */}    
          <div className='mb-3'>
            <label className='h3'><i className="fa-solid fa-bed"></i> TIPOS DE CAMA DISPONIBLES Y CANTIDAD</label>

            {Object.keys(camas_tipo_cantidad).map((tipo) => (
            <div key={tipo}>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id={`checkbox_${tipo}`}
                  checked={camas_tipo_cantidad[tipo].checked}
                  onChange={(e) => {
                    const newCamasTipoCantidad = {
                      ...camas_tipo_cantidad,
                        [tipo]: {
                          ...camas_tipo_cantidad[tipo],
                          checked: e.target.checked,
                        },
                    };
                    setCama_tipo_cantidad(newCamasTipoCantidad);
                  }}
                />
                <label className='form-check-label' htmlFor={`checkbox_${tipo}`}>
                  {tipo}
                </label>
                {camas_tipo_cantidad[tipo].checked && (
                  <input
                    type='number'
                    value={camas_tipo_cantidad[tipo].cantidad}
                    onChange={(e) => {
                      const newCamasTipoCantidad = {
                        ...camas_tipo_cantidad,
                        [tipo]: {
                          ...camas_tipo_cantidad[tipo],
                          cantidad: parseInt(e.target.value),
                        },
                      };
                        setCama_tipo_cantidad(newCamasTipoCantidad);
                    }}
                  />
                )}
          </div>
        </div>
        ))}
      </div>
            <div className='mb-3'>
              <label className='form-label'>CALIDAD DE LAS CAMAS</label>
                <input
                  value={calidad}
                  onChange={ (e) => setCalidad(e.target.value)}
                  type='text'
                  className='form-control'
                />
      
            </div>
            <label className='h3'><i className="fa-solid fa-hotel"></i> EL ALOJAMINETO INCLUYE</label>
            <div className='form-check form-switch'>
              <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>¿HAY ACCESO A INTERNET?</label>
                <input
                  checked={internet}
                  onChange={ (e) => setInternet(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
            </div>
            <div className='form-check form-switch'>
              <label className="form-check-label" htmlFor="flexSwitchCheckDefault">¿SERVICIO DE LIMPIEZA?</label>
                <input
                  checked={servicio_limpieza}
                  onChange={ (e) => setServicio_limpieza(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
            </div>
            <div className='form-check form-switch'>
              <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>¿HAY DESAYUNO?</label>
                <input
                  checked={desayuno}
                  onChange={ (e) => setDesayuno(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
            </div>
            <div className='form-check form-switch'>
              <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>¿HAY ALMUERZO?</label>
                <input
                  checked={almuerzo}
                  onChange={ (e) => setAlmuerzo(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
            </div>
            <div className='form-check form-switch'>
              <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>¿HAY CENA?</label>
                <input
                  checked={cena}
                  onChange={ (e) => setCena(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
            </div>
            <div className='mt-4 mb-3'>
              <label className='form-label'>OTRO SERVICIO DE ALIMENTACIÓN</label>
                <input
                  value={otra_alimentacion}
                  onChange={ (e) => setOtra_alimentacion(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>DESCRIBA SERVICIOS COMPLEMENTARIOS</label>
                <input
                  value={extras}
                  onChange={ (e) => setExtras(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>MANEJAN OTROS IDIOMAS</label>
                <input
                  value={idiomas}
                  onChange={ (e) => setIdiomas(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            </div>
      )}

      {currentPage === 4 && (
        <div className="row">
          {/** SERVICIOS CAMPING */}
          <label className='h3 mb-4'><i className="fa-solid fa-campground"></i> SERVICIOS DE CAMPING </label>    
            <div className='form-check form-switch'>
              <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>¿HAY INTERNET EN EL CAMPING?</label>
                <input
                  checked={camping_internet}
                  onChange={ (e) => setCamping_internet(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
            </div>
            <div className='form-check form-switch'>
              <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>¿HAY DUCHA EN EL CAMPING?</label>
                <input
                  checked={camping_ducha}
                  onChange={ (e) => setCamping_ducha(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
            </div>
            <div className='form-check form-switch'>
              <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>¿HAY COCINA EN EL CAMPING?</label>
                <input
                  checked={camping_cocina}
                  onChange={ (e) => setCamping_cocina(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
            </div>
            <div className='form-check form-switch'>
              <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>¿HAY BAÑO EN EL CAMPING?</label>
                <input
                  checked={camping_baño}
                  onChange={ (e) => setCamping_baño(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
            </div>
            <div className='form-check form-switch'>
              <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>¿HAY ELECTRICIDAD EN EL CAMPING?</label>
                <input
                  checked={camping_electricidad}
                  onChange={ (e) => setCamping_electricidad(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
            </div>
            <div className='form-check form-switch'>
              <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>¿HAY LAVANDERIA EN EL CAMPING?</label>
                <input
                  checked={camping_lavanderia}
                  onChange={ (e) => setCamping_lavanderia(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
            </div>
            <div className='form-check form-switch'>
              <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>¿HAY ESTACIONAMIENTO EN EL CAMPING?</label>
                <input
                  checked={camping_estacionamiento}
                  onChange={ (e) => setCamping_estacionamiento(e.target.checked)}
                  type='checkbox'
                  className='form-check-input'
                />
            </div>
            <div className='mt-4 mb-3'>
            <label className='form-label'>SERVICIOS EXTRAS EN EL CAMPING</label>
                <input
                  value={camping_servicios_extra}
                  onChange={ (e) => setCamping_extra(e.target.checked)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mt-4 mb-3'>
              <label className='form-label'>VALOR DEL CAMPING POR NOCHE</label>
                <input
                  value={camping_valor}
                  onChange={ (e) => setCamping_valor(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
        </div>
      )}
      {currentPage === 5 && (
        <div className="row">
          {/** TEMPORADA ALTA */}
          <label className='h3 mb-4'><i className="fa-regular fa-calendar-plus"></i> DATOS DE TEMPORADA ALTA </label>
          <div className='mb-3'>
              <label className='form-label'>INGRESE AQUI MESES (DESDE - HASTA)</label>
                <input
                  value={temporada_alta}
                  onChange={ (e) => setTemporada_altra(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
              <label className='form-label'>CAPACIDAD TEMPORADA ALTA</label>
                <input
                  value={capacidad_temp_alta}
                  onChange={ (e) => setCapacidad_temp_alta(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
          </div>
      )}

      {currentPage === 6 && (
        <div className="row">
          {/** COSTES Y MEDIOS DE PAGOS */}
          <label className='h3 mb-4'><i className="fa-solid fa-hand-holding-dollar"></i> COSTOS Y DATOS DE PAGO DEL SERVICIO </label>
            <div className='mb-3'>
              <label className='form-label'>VALOR DEL ALOJAMIENTO (rangos de valores relativos)</label>

              {Object.keys(valor_noche).map((tipo) => (
                <div key={tipo}>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id={`checkbox_${tipo}`}
                      checked={valor_noche[tipo].checked}
                      onChange={(e) => {
                        const newValor_noche = {
                          ...valor_noche,
                          [tipo]: {
                            ...valor_noche[tipo],
                            checked: e.target.checked,
                          },
                        };
                        setValor_noche(newValor_noche);
                      }}
                    />
                    <label className='form-check-label' htmlFor={`checkbox_${tipo}`}>
                      {tipo}
                    </label>
                  </div>
                  {tipo === 'OTRO' && valor_noche[tipo].checked && (
                   <div className='mt-2'>
                      <input
                        type='text'
                        value={valor_noche[tipo].otro}
                        onChange={(e) => {
                          const newValor_noche = {
                            ...valor_noche,
                            [tipo]: {
                             ...valor_noche[tipo],
                              otro: e.target.value,
                            },
                          };
                          setValor_noche(newValor_noche);
                        }}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className='mb-3'>
                <label className='form-label'> CUENTA BANCARIA DE EMPRESA </label>
                <select value={cuenta_bancaria} onChange={(e) => setCuenta_bancaria(e.target.value)} className='form-select' aria-label='Default select example'>
                  <option value='SI'>SI</option>
                  <option value='CUENTA PERSONAL'>CUENTA PERSONAL</option>
                  <option value='NO'>NO</option>
                </select>
            </div>
            <div className='mb-3'>
              <label className='mb-3'> FORMAS DE PAGO</label>

              {Object.keys(forma_pago).map((tipo) => (
                <div key={tipo}>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id={`checkbox_${tipo}`}
                      checked={forma_pago[tipo].checked}
                      onChange={(e) => {
                        const newForma_pago = {
                          ...forma_pago,
                          [tipo]: {
                            ...forma_pago[tipo],
                            checked: e.target.checked,
                          },
                        };
                        setForma_pago(newForma_pago);
                      }}
                    />
                    <label className='form-check-label' htmlFor={`checkbox_${tipo}`}>
                      {tipo}
                    </label>
                  </div>
                  {tipo === 'OTRO' && forma_pago[tipo].checked && (
                   <div className='mt-2'>
                      <input
                        type='text'
                        value={forma_pago[tipo].otro}
                        onChange={(e) => {
                          const newForma_pago = {
                            ...forma_pago,
                            [tipo]: {
                             ...forma_pago[tipo],
                              otro: e.target.value,
                            },
                          };
                          setForma_pago(newForma_pago);
                        }}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className='mb-3'>
              <label className='form-label'> SISTEMAS DE PAGO</label>
              {Object.keys(sistema_pago).map((tipo) => (
                <div key={tipo}>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id={`checkbox_${tipo}`}
                      checked={sistema_pago[tipo].checked}
                      onChange={(e) => {
                        const newSistema_pago = {
                          ...sistema_pago,
                          [tipo]: {
                            ...sistema_pago[tipo],
                            checked: e.target.checked,
                          },
                        };
                        setSistema_pago(newSistema_pago);
                      }}
                    />
                    <label className='form-check-label' htmlFor={`checkbox_${tipo}`}>
                      {tipo}
                    </label>
                  </div>
                  {tipo === 'OTRO' && sistema_pago[tipo].checked && (
                   <div className='mt-2'>
                      <input
                        type='text'
                        value={sistema_pago[tipo].otro}
                        onChange={(e) => {
                          const newSistema_pago = {
                            ...sistema_pago,
                            [tipo]: {
                             ...sistema_pago[tipo],
                              otro: e.target.value,
                            },
                          };
                          setSistema_pago(newSistema_pago);
                        }}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className='mb-3'>
              <label className='form-label'>¿CUENTAN CON ALGUN SOFTWARE DE GESTIÓN?</label>
                <input
                  value={software}
                  onChange={ (e) => setSoftware(e.target.value)}
                  type='text'
                  className='form-control'
                />
            </div>
            <div className='mb-3'>
                <label className='form-label'> ¿ESTA DISPUESTO A PAGAR EL 10%? </label>
                <select value={pagar_10} onChange={(e) => setPagar_10(e.target.value)} className='form-select' aria-label='Default select example'>
                  <option value='SI'>SI</option>
                  <option value='NO'>NO</option>
                  <option value='TAL VEZ'>TAL VEZ</option>
                </select>
            </div>
        </div>
      )}

      {currentPage === 1 && (
        <div className="d-flex justify-content-end mb-3">
        <button onClick={nextPage} className="btn btn-primary">
          Siguiente
        </button>
        </div>
      )}

      {currentPage > 1 && currentPage < 6 && (
        <>
        <div className="d-flex justify-content-center mb-3">
          <button onClick={previousPage} className="btn btn-primary">
            Anterior
          </button>
          <button onClick={nextPage} className="btn btn-primary">
            Siguiente
          </button>
        </div>
        </>
      )}

      {currentPage === 6 && (
        <>
        <div className="d-flex justify-content-center mb-3">
          <button onClick={previousPage} className="btn btn-primary">
            Anterior
          </button>
          <button onClick={store} className="btn btn-primary">
            Crear
          </button>
        </div>
        </>
      )}
    </div>
  )
}

export default Servicio;