import React from "react";
import './estilos/inicio.css';
import BannerServices from "../componentes/componentes_paginas/bannerServices";
import CarouselComponent from "../componentes/componentes_paginas/carrusel";
import CarouselLocations from "../componentes/componentes_paginas/carrusel-locations"; // carusel banner
//import CarouselComponent4 from "../componentes/componentes_paginas/carruselVideo";
//import { CarouselComponent5 } from "../componentes/componentes_paginas/carruselVideo2";
//import {Novedades, Novedades2} from "./novedes";
//import Pasos from "./pasos";
//import { ImageGallery } from "./ecosistema";
import Button from 'react-bootstrap/Button';
//import VideoPlayer from "../componentes/componentes_paginas/promocion";
import { ContactForm } from "../componentes/componentes_paginas/contacto";
import mapHome from '../imagenes/banners/map-home.png';
import { useTranslation } from "react-i18next";

const Inicio = () => {
  const { t } = useTranslation();
  return (
    <div className="inicio">
      <div className="container-fluid seccionCarousel px-0">
        <CarouselComponent />

        <div className="boxTitle d-flex align-items-end">
          <div className="container">
          <h1><strong>{t('INICIO.FILMA')}</strong> {t('INICIO.TU PROXIMO')} <strong>{t('INICIO.BLOCKBUSTER')}</strong> {t('INICIO.EN')} <strong>{t('INICIO.PATAGONIA')}</strong></h1>
            <Button href="/locaciones" variant="primary" size="lg" className="rounded-pill px-5 text-uppercase my-4">{t('INICIO.EXPLORE_LOCATIONS')}</Button>
            <h1 className="my-xl-5 d-none d-xxl-block"><i className="bi bi-chevron-double-down"></i></h1>
          </div>
        </div>
      </div>

      <BannerServices />

      <div className="container-fluid bg-black py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center text-white mb-3 pt-md-5">
              <h2 className="text-uppercase">{t('INICIO.ESTIMA_LOS')} <strong>{t('INICIO.COSTOS')}</strong></h2>
              <p className="lead">{t('INICIO.PROVEEMOS_ESTIMACION')}</p>
            </div>

            <div className="col-12 col-lg-4 mb-4">
              <div className="card bg-dark">
                <div className="card-body text-white p-4">
                  <span className="badge text-bg-success rounded-circle py-2 fs-5"><strong>1</strong></span>
                  <h3 className="pt-3 text-uppercase">{t('INICIO.ELIGE_LOCACION')}</h3>
                  <p>{t('INICIO.SELECCIONA_LOCACION')}</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4 mb-4">
              <div className="card bg-dark">
                <div className="card-body text-white p-4">
                  <span className="badge text-bg-success rounded-circle py-2 fs-5"><strong>2</strong></span>
                  <h3 className="pt-3 text-uppercase">{t('INICIO.ANADIR_FECHA')}</h3>
                  <p>{t('INICIO.DEFINE_FECHA')}</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4 mb-4">
              <div className="card bg-dark">
                <div className="card-body text-white p-4">
                  <span className="badge text-bg-success rounded-circle py-2 fs-5"><strong>3</strong></span>
                  <h3 className="pt-3 text-uppercase">{t('INICIO.AGREGA_SERVICIOS')}</h3>
                  <p>{t('INICIO.TRANSPORTE_LOGISTICO')}</p>
                </div>
              </div>
            </div>

            <div className="col-12 text-center text-white mt-5 mb-md-5">
              <h2 className="text-uppercase">{t('INICIO.OBTEN_COTIZACION')}</h2>
              <p className="lead">{t('INICIO.ENVIAREMOS_PRESUPUESTO')}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid locations py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-12 pt-5">
              <h2 className="text-uppercase">{t('INICIO.EXPLORA_LOCACIONES')}</h2>
              <p className="lead">{t('INICIO.ESCENARIOS_NATURALES')}</p>
            </div>
            
            <div className="col-12">
              <CarouselLocations />
            </div>

            <div className="col-12">
              <Button href="/locaciones" variant="dark" size="lg" className="rounded-pill px-5 text-uppercase my-5">{t('INICIO.TODAS_LAS_LOCACIONES')}</Button>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid local py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-12 pt-5">
              <h2 className="text-uppercase">{t('INICIO.TRABAJA_COMO_CASA')}</h2>
              <p className="lead">{t('INICIO.ACCESO_SERVICIOS')}</p>
            </div>
            
            <div className="col-md-5">
              <ul>
                <li><i className="bi bi-check-all text-primary"></i> {t('INICIO.TRANSPORTE')}</li>
                <li><i className="bi bi-check-all text-primary"></i> {t('INICIO.ALOJAMIENTO')}</li>
                <li><i className="bi bi-check-all text-primary"></i> {t('INICIO.ALIMENTACION')}</li>
                <li><i className="bi bi-check-all text-primary"></i> {t('INICIO.INFRAESTRUCTURA')}</li>
                <li><i className="bi bi-check-all text-primary"></i> {t('INICIO.Y_MUCHO_MAS')}</li>
              </ul>
            </div>

            <div className="col-md-7">
              <img src={mapHome} alt="shootinpatagonia_banner0" className="img-fluid pt-5 pb-5"/>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid fixers bg-white py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-12 py-5">
              <h2 className="text-uppercase">{t('INICIO.ENCUENTRA_PARTNERS')}</h2>
              <p className="lead">{t('INICIO.CONECTAMOS_PROFESIONALES')}</p>
            </div>
            
            <div className="col-6 col-lg-2 mb-4">
              <div className="card text-bg-light border-light-subtle mb-4 mb-lg-0">
                <div className="card-body">
                  <h1><i className="bi bi-camera-reels"></i></h1>
                </div>
              </div>
              <h4>{t('INICIO.MAKING_OF')}</h4>
            </div>

            <div className="col-6 col-lg-2 mb-4">
              <div className="card text-bg-light border-light-subtle mb-4 mb-lg-0">
                <div className="card-body">
                  <h1><i className="bi bi-person-video"></i></h1>
                </div>
              </div>
              <h4>{t('INICIO.CASTING_AUDITIONS')}</h4>
            </div>

            <div className="col-6 col-lg-2 mb-4">
              <div className="card text-bg-light border-light-subtle mb-4 mb-lg-0">
                <div className="card-body">
                  <h1><i className="bi bi-hdd-stack"></i></h1>
                </div>
              </div>
              <h4>{t('INICIO.DOWNLOAD_MANAGER')}</h4>
            </div>

            <div className="col-6 col-lg-2 mb-4">
              <div className="card text-bg-light border-light-subtle mb-4 mb-lg-0">
                <div className="card-body">
                  <h1><i className="bi bi-cup-straw"></i></h1>
                </div>
              </div>
              <h4>{t('INICIO.RUNNER_ASSISTANT')}</h4>
            </div>

            <div className="col-6 col-lg-2 mb-4">
              <div className="card text-bg-light border-light-subtle mb-4 mb-lg-0">
                <div className="card-body">
                  <h1><i className="bi bi-laptop"></i></h1>
                </div>
              </div>
              <h4>{t('INICIO.ASSEMBLY_EDITING')}</h4>
            </div>

            <div className="col-6 col-lg-2 mb-4">
              <div className="card text-bg-light border-light-subtle mb-4 mb-lg-0">
                <div className="card-body">
                  <h1><i className="bi bi-camera"></i></h1>
                </div>
              </div>
              <h4>{t('INICIO.AERIAL_PHOTO')}</h4>
            </div>

            <div className="col-12 text-center">
              <Button href="/locaciones" variant="dark" size="lg" className="rounded-pill px-5 text-uppercase my-5">{t('INICIO.CONOCER_SERVICIOS')}</Button>
            </div>
          </div>
        </div>
      </div>
      
      <ContactForm />
    </div>
  );
};

export default Inicio;
