import React, { useState, /* useRef */ } from 'react';
import { sendCustomEmail } from '../../resendConfig/resend'; // Importar la función sendEmail desde resend.js
import logo from '../../imagenes/logos/logo cuadradon blanco png SHP.png'
import Button from 'react-bootstrap/Button';
//import logoCuadrado from '../../imagenes/logos/logo cuadradon blanco png SHP.png'
//import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next'; // Importa el hook de traducción


export const ContactForm = () => {
  const { t } = useTranslation(); // Usa el hook de traducción
  const [formData, setFormData] = useState({
    nombre: '',
    correo: '',
    texto: '',
  });

  //const [setCaptchaToken] = useState(null); // Estado para almacenar el token de reCAPTCHA
  const [isButtonDisabled, /* setIsButtonDisabled */] = useState(false); // Estado para deshabilitar el botón de envío


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  function handleSubmit(e) {
		e.preventDefault();
		let nombre = e.target.nombre.value;
		let correo = e.target.correo.value;
		let texto = e.target.texto.value;
		sendCustomEmail(nombre, correo, texto);
		e.target.nombre.value = e.target.correo.value = e.target.texto.value = '';
	}

  /* const captcha = useRef(null);
  const onChange = () => {
    const token = captcha.current.getValue();
    console.log(token);
    setCaptchaToken(token);
    setIsButtonDisabled(false);
  }; */

  return (
    <div className="card bg-dark text-white">
      <div className='card-body p-5'>
        <h3 className='pb-4'>{t('CONTACTO.TITULO')}</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <label htmlFor="nombre">{t('CONTACTO.NOMBRE_LABEL')}</label>
            <input type="text" className="form-control" id="nombre" name="nombre" value={formData.nombre} onChange={handleInputChange} />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="correo">{t('CONTACTO.CORREO_LABEL')}</label>
            <input type="email" className="form-control" id="correo" name="correo" value={formData.correo} onChange={handleInputChange} />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="texto">{t('CONTACTO.MENSAJE_LABEL')}</label>
            <textarea className="form-control" id="texto" name="texto" value={formData.texto} onChange={handleInputChange} />
          </div>

          <Button type="submit" variant="primary" className="rounded-pill px-5 text-uppercase my-4">{t('CONTACTO.BOTON_ENVIAR')}</Button>
        </form>
      </div>
    </div>
  );
};
