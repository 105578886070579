import React, { useState } from 'react';
import Swal from 'sweetalert2';
import '../estilos/tarjeta_servicio_t.css';

const ServiceCard = ({ name, vacancies, unitCost, onHire, blocked }) => {
  const [selectedVehicles, setSelectedVehicles] = useState({});

  const handleChangeQuantity = (vehicleType, maxQuantity, e) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value >= 0 && value <= maxQuantity) {
      setSelectedVehicles(prevState => ({
        ...prevState,
        [vehicleType]: value
      }));
    }
  };

  const handleHire = () => {
    if (isNaN(unitCost) || unitCost <= 0) {
      console.error('El costo unitario debe ser un número válido y mayor a 0');
      return;
    }

    const totalCost = Object.keys(selectedVehicles).reduce((total, vehicleType) => {
      const quantity = selectedVehicles[vehicleType] || 0;
      return total + (unitCost * quantity);
    }, 0);

    if (totalCost <= 0) {
      Swal.fire('Seleccione al menos un vehículo para contratar.');
      return;
    }

    Swal.fire({
      title: `¿Deseas agregar el servicio de ${name} por un valor de $${totalCost}?`,
      showCancelButton: true,
      confirmButtonText: 'Sí, agregar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        Object.keys(selectedVehicles).forEach(vehicleType => {
          const quantity = selectedVehicles[vehicleType] || 0;
          if (quantity > 0) {
            onHire(unitCost * quantity, `${name} - ${vehicleType}`, quantity, vehicleType);
          }
        });
        Swal.fire('Agregado!', 'El servicio ha sido agregado al carrito.', 'success');
      }
    });
  };

  // Filtrar los vehículos para mostrar solo aquellos que tengan más de uno disponible
  const filteredVacancies = Object.entries(vacancies.props.vehiculos_tipo_cantidad).filter(([vehicleType, info]) => info.cantidad > 0);

  return (
    <div className={`card transport-service-card mb-4 ${blocked ? 'blocked' : ''}`}>
      <div className='card-body'>
        <div className="row">
          <div className='col-xl-5'>
            <h5>{name}</h5>
          </div>

          <div className='col-xl-5'>
            <p>Vacantes:</p>
            {filteredVacancies.map(([vehicleType, info]) => (
              <div key={vehicleType}>
                <label htmlFor={`quantity-${vehicleType}`}>{`${vehicleType}: ${info.cantidad}`}</label>
                <input
                  type="number"
                  id={`quantity-${vehicleType}`}
                  min="0"
                  max={info.cantidad}
                  value={selectedVehicles[vehicleType] || 0}
                  onChange={(e) => handleChangeQuantity(vehicleType, info.cantidad, e)}
                  className='form-control'
                />
              </div>
            ))}
            <p>Costo unitario: ${unitCost}</p>
          </div>

          <div className='col-xl-2'>
            <button onClick={handleHire} disabled={blocked} className='btn btn-primary mt-4 mt-xl-0'>Agregar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
