import React, { useState, useEffect } from "react";
import { collection, getDocs } from 'firebase/firestore';
import { db } from "../../firebaseConfig/firebase";
import ServiceCard from "./tarjeta_servicio_t";
import Vehiculos from "./vehiculos";
import CostoxNoche from "./costoNoche";
import '../estilos/cotizadorAlojamiento.css';
import { useTranslation } from "react-i18next";

const CotizadorTransporte = ({ onNextStep, id, ciudadCercana, totalPersonas, onDataFromChild }) => {
  const { t } = useTranslation();
  const [servTransporte, setServTransporte] = useState([]);
  const [searchTerm, setSearchTerm] = useState(ciudadCercana);
  const [costoTotal, setCostoTotal] = useState(0);
  const [nombreServicio, setNombreServicio] = useState('');
  const [cantidadPersonas, setCantidadPersonas] = useState(0);
  const [serviciosCotizados, setServiciosCotizados] = useState([]);
  const [tipoServicioCotizado] = useState('transporte');

  const servTransporteCollection = collection(db, 'servicio_transporte');

  const getServTransporte = async () => {
    const data = await getDocs(servTransporteCollection);
    setServTransporte(
      data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    );
  };

  useEffect(() => {
    getServTransporte();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddService = (CostoServicio, NombreServicio, CantidadPersonas, TipoVehiculo) => {
    setCostoTotal(prevTotal => prevTotal + CostoServicio);
    setNombreServicio(NombreServicio);
    setCantidadPersonas(CantidadPersonas);
    onDataFromChild({ tipoServicioCotizado, CostoServicio, NombreServicio, CantidadPersonas, TipoVehiculo });

    setServiciosCotizados(prevServicios => [...prevServicios, { NombreServicio, CostoServicio, CantidadPersonas, TipoVehiculo }]);
  };

  return (
    <div className='services-container'>
      <h3 className='pb-5'>{t('COTIZADOR_T.SERVICIO_DE_TRANSPORTE')}</h3>
      <div className='search-bar'>
        <input
          type='text'
          placeholder='Buscar por nombre, ciudad o RUT'
          className='form-control'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className='service-list'>
        {servTransporte
          .filter((item) =>
            item.empresa.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.rut.includes(searchTerm) ||
            item.ciudad.includes(searchTerm)
          )
          .filter(item => {
            const unitCost = CostoxNoche({ costo_noche: item.valor_noche });
            return unitCost > 0;
          })
          .map((item) => {
            const unitCost = CostoxNoche({ costo_noche: item.valor_noche });
            const isCotizado = serviciosCotizados.some(serv => serv.NombreServicio === item.empresa);
            return (
              <ServiceCard
                key={item.id}
                name={item.empresa}
                vacancies={<Vehiculos vehiculos_tipo_cantidad={item.vehiculos_tipo_cantidad} />}
                unitCost={unitCost}
                onHire={handleAddService}
                blocked={isCotizado}
              />
            );
          })}
      </div>
    </div>
  );
};

export default CotizadorTransporte;
