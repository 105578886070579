import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import '../estilos/fechaVisita.css';
import 'react-datepicker/dist/react-datepicker.css';
import es from "date-fns/locale/es";
import { t } from 'i18next';

const FechaVisita = ({ onNextStep }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [personas, setPersonas] = useState(1);
  const [costoTotal] = useState(0);

  const handleChangePersonas = (e) => {
    const cantidad = parseInt(e.target.value, 10);
    setPersonas(cantidad);
  };

  const handleNextStep = () => {
    const formData = { startDate, endDate, personas, costoTotal };
    onNextStep(formData);
  };

  return (
    <div className='row pb-5'>
      <div className='col-12'>
        <h3 className='pb-5'>{t('FECHA_VISITA.CUANDO_GRABAR')}</h3>
      </div>

      <div className='col-4'>
        <div className="mb-3">
          <label className="form-label">{t('FECHA_VISITA.INICIO_RODAJE')}:</label>
          <ReactDatePicker
            className="form-control w-100"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            locale={es}
          />
        </div>
      </div>

      <div className='col-4'>
        <div className="mb-3">
          <label className="form-label">{t('FECHA_VISITA.TERMINO_RODAJE')}:</label>
          <ReactDatePicker
            className="form-control"
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            locale={es}
          />
        </div>
      </div>

      <div className='col-4'>
        <div className="mb-3">
          <label className="form-label">{t('FECHA_VISITA.CANTIDAD_PERSONAS')}:</label>
          <input className="form-control" type="number" value={personas} onChange={handleChangePersonas} min={1} />
        </div>
      </div>
      <div className='col-12 mt-4'>
        <button onClick={handleNextStep} className='btn btn-primary rounded-pill float-end'>{t('FECHA_VISITA.COMENZAR')}</button>
      </div>
    </div>
  );
};

export default FechaVisita;
