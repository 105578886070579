import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import { useParams } from "react-router-dom";
import {doc, getDoc, updateDoc} from 'firebase/firestore';
import { db, subirArchivo2 } from "../firebaseConfig/firebase";

import Swal from 'sweetalert2';

const EditarServicioTransporte = () => {
    const { id, tipoServicio } = useParams();
    const [servicio, setServicio] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    //seteo de datos DESCIPTIVOS para el servicio
    const [email, setEmail] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [encargado, setEncargado] = useState(''); 
    const [rut, setRut] = useState('');
    const [email_encargado, setEmail_encargado] = useState('');
    const [telefono, setTelefono] = useState('');
    const [celular, setCelular] = useState('');
    const [horario, setHorario] = useState(''); // se agrega un horario de atencion para servicio de transporte
    const [presencia_red, setPresencia_red] = useState('');
    const [direccion, setDireccion] = useState('');
    const [ciudad, setCiudad] = useState('ciudades de la region ...');
    const [definicion_negocio, setDef_negocio] = useState({
      'EMPRESA DE TRASNPORTE DE PASAJEROS': {checked: false},
      'MEDIANO TRANSPORTISTA DE PASAJEROS': {checked: false},
      'PEQUEÑO TRANSPORTISTA DE PASAJEROS': {checked: false},
      'AGENCIA DE VIAJES ': {checked: false},
      'LOGISTICA Y OPERACIONES': {checked: false},
      'SERVICIO DE TRANSPORTE MENOR': {checked: false},
      'SERVICIO DE ECOTOURS': {checked: false},
      'TOUR OPERADOR': {checked: false},
      'SERVICIO DE CARGA MENOR': {checked: false},
      'TRANSPORTE DE CARGA PARA SERVICIOS PORTUARIOS': {checked: false},
      'TRANSPORTE DE CARGA PESADA ': {checked: false},
      'OTRO': { checked: false, otro:''},
    });
    
    const [antiguedad, setAntiguedad] = useState('');
    const [sello_calidad, setSello_calidad] = useState('');
    const [imagen, setImagen] = useState(null);
  
    //seteo de datos OPERATIVOS del servicio
    const [vehiculos_tipo_cantidad, setVehiculos_tipo_cantidad] = useState({
      'CITY CAR': { checked: false, cantidad: 0},
      'TRANSPORTE DE CARGA': { checked: false, cantidad: 0},
      'MOTOCICLETA BAJA CILINDRADA < 750CC' : { checked: false, cantidad: 0},
      'MOTOCICLETA ALTA CILINDRADA > 750CC': { checked: false, cantidad: 0},
      'TAXI': { checked: false, cantidad: 0},
      'MINI BUS 6 PASAJEROS': { checked: false, cantidad: 0},
      'MINI BUS 12 PASAJEROS': { checked: false, cantidad: 0},
      'MINI BUS 15 PASAJEROS': { checked: false, cantidad: 0},
      'MINI BUS 17 PASAJEROS': { checked: false, cantidad: 0},
      'BUS 25 A 30 PASAJEROS ': { checked: false, cantidad: 0},
      'BUS MÁS 30 PASAJEROS ': { checked: false, cantidad: 0},
      'CAMIONETA 4X2': { checked: false, cantidad: 0},
      'CAMIONETA 4X4': { checked: false, cantidad: 0},
      'SUV 4X2': { checked: false, cantidad: 0},
      'SUV 4X4': { checked: false, cantidad: 0},
      'OTRO TRANSPORTE': {checked: false, cantidad: 0}
    });
    const [tarjeta_transporte, setTarjeta_transporte] = useState('');
    const [vehiculos_antiguedad, setVehiculos_antiguedad] = useState('');
    const [vehiculos_asegurados, setVehiculos_asegurados] = useState(false);
    const [asistencia_ruta, setAsistencia_ruta] = useState(false);
    const [chofer_calificado, setChofer_calificado] = useState(false);
    const [extras, setExtras] = useState('');
    const [calificacion_clientes, setCalif_clientes] = useState('');
  
    //seteo más datos OPERATIVOS del servicio
    const [idiomas, setIdiomas] = useState('');
    const [valor_noche, setValor_noche] = useState({
      '25.000 35.000': { checked: false},
      '35.000 50.000': { checked: false},
      '50.000 75.000': { checked: false},
      '75.000 100.000': { checked: false},
      '100.000 150.000': { checked: false},
      '150.000 200.000': { checked: false},
      'OTRO': { checked: false, otro:''},
    });
    const [temporada_alta, setTemporada_altra] = useState('');
    const [capacidad_temp_alta, setCapacidad_temp_alta] = useState('');
    const [cuenta_bancaria, setCuenta_bancaria] = useState('');
    const [forma_pago, setForma_pago] = useState({
      'CONTADO': { checked: false},
      '50% A LA RESERVA, 50% AL CHECK-IN ': { checked: false},
      '50% ANTICIPO, SALDO FACTURA A 30 DIAS': { checked: false},
      'OTRO': { checked: false, otro:''},
    });
    const [sistema_pago, setSistema_pago] = useState({
      'TRASFERENCIA ELECTRONICA': { checked: false},
      'TARJETA DE CREDITO': { checked: false},
      'DEBITO': { checked: false},
      'CHEQUES': { checked: false},
      'EFECTIVO': { checked: false},
      'PAYPAL': { checked: false},
      'CRYPTO MONEDAS': {checked: false},
      'OTRO': { checked: false, otro:''},
    });
    const [software, setSoftware] = useState('');
    const [pagar_10, setPagar_10] = useState('');
  
    const navigate = useNavigate();
  
    const nextPage = () => {
      setCurrentPage((prevPage) => prevPage + 1);
    };
  
    const previousPage = () => {
      setCurrentPage((prevPage) => prevPage - 1);
    };
  
    const serv_transporteCollection = doc(db, 'servicio_transporte', id);
    const update = async (e) => {
      e.preventDefault();
      try{
  
        const camposAValidar = [
          { campo: empresa, nombreCampo: 'Nombre Empresa' },
          { campo: direccion, nombreCampo: 'Dirección' },
          { campo: ciudad, nombreCampo: 'Ciudad' },
          // Agrega más campos aquí
        ];
  
        for (const campoInfo of camposAValidar) {
          if (!campoInfo.campo || campoInfo.campo.trim() === "" ) {
              throw Swal.fire(`El campo ${campoInfo.nombreCampo} no puede estar vacío o debe seleccionar al menos un valor`);
          }
        }
        const resultado = await subirArchivo2(imagen); //aqui se sube la imagen de referencia del negocio al storage firebase : consultar firebase.js
        console.log(resultado)
        await updateDoc (serv_transporteCollection, {
  
        //almacenamiento de datos DESCRIPTIVOS 
        //fecha_inscripcion:fecha_inscripcion,
        email:email,
        empresa:empresa,
        encargado:encargado,
        rut:rut,
        email_encargado:email_encargado,
        telefono:telefono,
        celular:celular,
        horario:horario,
        presencia_red:presencia_red,
        direccion:direccion,
        ciudad:ciudad,
        definicion_negocio:definicion_negocio,
        antiguedad:antiguedad,
        sello_calidad:sello_calidad,
        imagen:resultado,
        
  
        //almacenamiento de datos OPERATIVOS
        vehiculos_tipo_cantidad:vehiculos_tipo_cantidad,
        tarjeta_transporte:tarjeta_transporte,
        vehiculos_antiguedad:vehiculos_antiguedad,
        vehiculos_asegurados:vehiculos_asegurados,
        asistencia_ruta:asistencia_ruta,
        chofer_calificado:chofer_calificado,
        extras:extras,
        calificacion_clientes:calificacion_clientes,
  
        //almacenamiento de datos OPERATIVOS Y COSTOS
        idiomas:idiomas,
        valor_noche:valor_noche,
        temporada_alta:temporada_alta,
        capacidad_temp_alta:capacidad_temp_alta,
        cuenta_bancaria:cuenta_bancaria,
        forma_pago:forma_pago,
        sistema_pago:sistema_pago,
        software:software,
        pagar_10:pagar_10
  
      })
      Swal.fire(`Se actualizo el servicio de transporte ${empresa}`)
      navigate('/ver');
      }catch (error){
        console.error(error);
        alert('fallo al subir imagen , intente nuevamente ');
      }
    }

    // DEFINIENDO CAPTUAS Y SETEO DE DATOS ACTUALES DEL SERVICIO
  
    const fetchServicio = async () => {
      const collectionName = (() => {
        switch (tipoServicio) {
          case 'alojamiento':
            return 'servicio_alojamiento';
          case 'transporte':
            return 'servicio_transporte';
          case 'gastronomico':
            return 'servicio_gastronomico';
          case 'otro':
            return 'servicio_otro';
          default:
            return 'servicio_transporte'; // Valor por defecto si no coincide con ninguna de las opciones anteriores.
        }
      })();
        const servicioDoc = doc(db, collectionName, id);
  
        const servicioSnapshot = await getDoc(servicioDoc);
        console.log(servicioSnapshot)
        if (servicioSnapshot.exists()) {
          setServicio(servicioSnapshot.data());
          
            setRut(servicioSnapshot.data().rut);
            setEmpresa(servicioSnapshot.data().empresa);
            setEmail(servicioSnapshot.data().email);
            setEncargado(servicioSnapshot.data().encargado);
            setEmail_encargado(servicioSnapshot.data().email_encargado);
            setDef_negocio(servicioSnapshot.data().definicion_negocio);
            setTelefono(servicioSnapshot.data().telefono);
            setCelular(servicioSnapshot.data().celular);
            setImagen(servicioSnapshot.data().imagen);
            setPresencia_red(servicioSnapshot.data().presencia_red);
            setDireccion(servicioSnapshot.data().direccion);
            setCiudad(servicioSnapshot.data().ciudad);
  
            setAntiguedad(servicioSnapshot.data().antiguedad);
            setSello_calidad(servicioSnapshot.data().sello_calidad);
            setCalif_clientes(servicioSnapshot.data().calificacion_clientes);
  
            setVehiculos_tipo_cantidad(servicioSnapshot.data().vehiculos_tipo_cantidad);
            setTarjeta_transporte(servicioSnapshot.data().tarjeta_transporte);
            setVehiculos_antiguedad(servicioSnapshot.data().vehiculos_antiguedad);
            setVehiculos_asegurados(servicioSnapshot.data().vehiculos_asegurados);
            setAsistencia_ruta(servicioSnapshot.data().asistencia_ruta);
            setChofer_calificado(servicioSnapshot.data().chofer_calificado);
            setExtras(servicioSnapshot.data().extras);
  
            setTemporada_altra(servicioSnapshot.data().temporada_alta);
            setCapacidad_temp_alta(servicioSnapshot.data().capacidad_temp_alta);
  
            setValor_noche(servicioSnapshot.data().valor_noche);
            setCuenta_bancaria(servicioSnapshot.data().cuenta_bancaria);
            setForma_pago(servicioSnapshot.data().forma_pago);
            setSistema_pago(servicioSnapshot.data().sistema_pago);
            setSoftware(servicioSnapshot.data().software);
            setPagar_10(servicioSnapshot.data().pagar_10);
          
  
        } else {
          console.log("El servicio no existe");
        }
      };
  
      useEffect(() => {
        fetchServicio(id, tipoServicio, servicio);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
  
      if (!servicio) {
      return <div>Cargando...</div>;
      }

    return(
        <div className="cajaCrear d-flex flex-column justify-content-center align-items-center min-vh-100">
          {currentPage === 1 && (
            <div className="div-descriptivos row">
              {/** DATOS DESCRIPTIVOS DEL SERVICIO*/}
              <label className='h3 mb-5'>DATOS SOBRE EL SERVICIO</label>
                <div className='mb-3'>
                  <label className='form-label'>INGRESE EL NOMBRE DE LA EMPRESA</label>
                    <input
                      value={empresa}
                      onChange={ (e) => setEmpresa(e.target.value)}
                      type='text'
                      className='form-control'
                    />
                </div>
                <div className='mb-3'>
                  <label className='form-label'><i className="fa-solid fa-at"></i> E-MAIL DE LA EMPRESA</label>
                    <input
                      value={email}
                      onChange={ (e) => setEmail(e.target.value)}
                      type='text'
                      className='form-control'
                    />
                </div>
                <div className='mb-3'>
                  <label className='form-label'> COMO SE DEFINE EL NEGOCIO</label>
                  {Object.keys(definicion_negocio).map((tipo) => (
                    <div key={tipo}>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id={`checkbox_${tipo}`}
                          checked={definicion_negocio[tipo].checked}
                          onChange={(e) => {
                            const newDefinicion_negocio = {
                              ...definicion_negocio,
                              [tipo]: {
                                ...definicion_negocio[tipo],
                                checked: e.target.checked,
                              },
                            };
                            setDef_negocio(newDefinicion_negocio);
                          }}
                        />
                        <label className='form-check-label' htmlFor={`checkbox_${tipo}`}>
                          {tipo}
                        </label>
                      </div>
                      {tipo === 'OTRO' && definicion_negocio[tipo].checked && (
                       <div className='mt-2'>
                          <input
                            type='text'
                            value={definicion_negocio[tipo].otro}
                            onChange={(e) => {
                              const newDefinicion_negocio = {
                                ...definicion_negocio,
                                [tipo]: {
                                 ...definicion_negocio[tipo],
                                  otro: e.target.value,
                                },
                              };
                              setDef_negocio(newDefinicion_negocio);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
    
                <div className='mb-3'>
                  <label className='form-label'>NOMBRE DE ENCARGADO O ADMINITRADOR</label>
                    <input
                      value={encargado}
                      onChange={ (e) => setEncargado(e.target.value)}
                      type='text'
                      className='form-control'
                    />
                </div>
                <div className='mb-3'>
                  <label className='form-label'>RUT</label>
                    <input
                      value={rut}
                      onChange={ (e) => setRut(e.target.value)}
                      type='text'
                      className='form-control'
                    />
                </div>
                <div className='mb-3'>
                  <label className='form-label'><i className="fa-solid fa-at"></i> E-MAIL ENCARGADO</label>
                    <input
                      value={email_encargado}
                      onChange={ (e) => setEmail_encargado(e.target.value)}
                      type='text'
                      className='form-control'
                    />
                </div>
                <div className='mb-3'>
                  <label className='form-label'><i className="fa-solid fa-phone"></i> TELEFONO FIJO (copiar/pegar)</label>
                  <input
                    value={telefono}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue.startsWith("+67 ")) {
                        setTelefono(inputValue);
                      } else {
                        setTelefono("+67 " + inputValue);
                      }
                    }}
                    type='text'
                    className='form-control'
                  />
                </div>
                <div className='mb-3'>
                  <label className='form-label'><i className="fa-solid fa-mobile-screen-button"></i> TELEFONO CELULAR (copiar/pegar)</label>
                  <input
                    value={celular}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue.startsWith("+569 ")) {
                        setCelular(inputValue);
                      } else {
                        setCelular("+569 " + inputValue);
                      }
                    }}
                    type='text'
                    className='form-control'
                  />
                </div>
    
                <div className='mb-3'>
                  <label className='form-label'><i className="fa-solid fa-image"></i> FOTO O IMAGEN REFERENCIAL DEL NEGOCIO</label>
                    <input
                      files={[0]}
                      onChange={ (e) => setImagen(e.target.files[0])}
                      type='file'
                      className='form-control'
                    />
                </div>
    
                <div className='mb-3'>
                  <label className="form-label">¿TIENE PRESENCIA EN INTERNET?</label>
                    <input
                      value={presencia_red}
                      onChange={ (e) => setPresencia_red(e.target.value)}
                      type='text'
                      className='form-control'
                    />
                </div>
                <div className='mb-3'>
                  <label className='form-label'>DIRECCION COMERCIAL</label>
                    <input
                      value={direccion}
                      onChange={ (e) => setDireccion(e.target.value)}
                      type='text'
                      className='form-control'
                    />
                </div>
                <div className='mb-3'>
                    <div className='form-label'><label >HORARIO DE ATENCIÓN</label></div>
                    <input
                      value={horario}
                      onChange={ (e) => setHorario(e.target.value)}
                      type='text'
                      className='form-control'
                    />
                  </div>
                <div className='mb-3'>
                  <label className='form-label'> CIUDAD </label>
                    <select value={ciudad} onChange={(e) => setCiudad(e.target.value)} className='form-select' aria-label='Default select example'>
                      <option value='ciudades de la region ...'>seleccione una ciudad...</option>
                      <option value='coyhaique'>Coyhaique</option>
                      <option value='aysen'>Puerto Aysen</option>
                      <option value='chacabuco'>Puerto Chacabuco</option>
                      <option value='puerto_cisnes'>Puerto Cisnes</option>
                      <option value='melinka'>Melinka</option>
                      <option value='puerto_aguirre'>Puerto Aguirre</option>
                      <option value='chile_chico'>Chile Chico</option>
                      <option value='guadal'>Puerto Guadal</option>
                      <option value='puerto_betrand'>Puerto Bertrand</option>
                      <option value='tranquilo'>Puerto Rio Tranquilo</option>
                      <option value='bahia_murta'>Bahía Murta</option>
                      <option value='puerto_sanchez'>Puerto Sanchez</option>
                      <option value='cochrane'>Cochrane</option>
                      <option value='tortel'>Tortel</option>
                      <option value='villa_ohiggins'>Villar O'higgins</option>
                      <option value='balmaceda'>Balmaceda</option>
                      <option value='cerro_castillo'>Cerro_castillo</option>
                      <option value='puerto_ibañez'>Puerto Ibañez</option>
                      <option value='mañihuales'>Mañihuales</option>
                      <option value='villa_amengual'>Villa Amengual</option>
                      <option value='villa_ortega'>Villa Ortega</option>
                      <option value='lago_verde'>Lago Verde</option>
                      <option value='puyuhuapi'>Puyuhuapi</option>
                      <option value='la_junta'>La Junta</option>
                      <option value='raul_marin_balmaceda'>Raúl Marín Balmaceda</option>
                      <option value='futaleufu'>Futaleufu</option>
                      <option value='chaiten'>Chaiten</option>
                  
                    </select>
                </div>
            </div>
          )}
    
          {currentPage === 2 && (
            <div className="div-calificaciones row">
             {/** CALIFICAIONES */}
             <label className='h3'><i className="fa-solid fa-ranking-star"></i> ANTIGUEDAD Y CALIFICACIONES</label>
                <div className='mt-4 mb-3'>
                  <label className='form-label'>ANTIGUEDAD DEL NEGOCIO</label>
                    <input
                      value={antiguedad}
                      onChange={ (e) => setAntiguedad(e.target.value)}
                      type='text'
                      className='form-control'
                    />
                </div>
                <div className='mb-3'>
                  <label className='form-label'>CUENTA CON ALGUN SELLO DE CALIDAD</label>
                    <input
                      value={sello_calidad}
                      onChange={ (e) => setSello_calidad(e.target.value)}
                      type='text'
                      className='form-control'
                    />
                </div>
                <div className='mb-3'>
                  <label className='form-label'> CALIFICACIONES DE CLIENTES </label>
                    <select value={calificacion_clientes} onChange={(e) => setCalif_clientes(e.target.value)} className='form-select' aria-label='Default select example'>
                      <option value='sin calificion'>calificaciones...</option>
                      <option value='5'>***** 5 estrellas</option>
                      <option value='4'>**** 4 estrellas</option>
                      <option value='3'>*** 3 estrellas</option>
                      <option value='2'>** 2 estrellas</option>
                    </select>
                </div>
              </div>
          )}
    
          {currentPage === 3 && (
            <div className="row">
              {/** SERVICIOS PRESENTES */}    
              <div className='mb-3'>
                <label className='h3'><i className="fa-solid fa-car"></i> <i className="fa-solid fa-motorcycle"></i> TIPOS DE TRANSPORTE Y CANTIDAD</label>
    
                {Object.keys(vehiculos_tipo_cantidad).map((tipo) => (
                <div key={tipo}>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id={`checkbox_${tipo}`}
                      checked={vehiculos_tipo_cantidad[tipo].checked}
                      onChange={(e) => {
                        const newVehiculosTipoCantidad = {
                          ...vehiculos_tipo_cantidad,
                            [tipo]: {
                              ...vehiculos_tipo_cantidad[tipo],
                              checked: e.target.checked,
                            },
                        };
                        setVehiculos_tipo_cantidad(newVehiculosTipoCantidad);
                      }}
                    />
                    <label className='form-check-label' htmlFor={`checkbox_${tipo}`}>
                      {tipo}
                    </label>
                    {vehiculos_tipo_cantidad[tipo].checked && (
                      <input
                        type='number'
                        value={vehiculos_tipo_cantidad[tipo].cantidad}
                        onChange={(e) => {
                          const newVehiculosTipoCantidad = {
                            ...vehiculos_tipo_cantidad,
                            [tipo]: {
                              ...vehiculos_tipo_cantidad[tipo],
                              cantidad: parseInt(e.target.value),
                            },
                          };
                          setVehiculos_tipo_cantidad(newVehiculosTipoCantidad);
                        }}
                      />
                    )}
              </div>
            </div>
            ))}
          </div>
                <div className='mb-3'>
                  <label className='form-label'>¿TIENE TARJETA DE TRANSPORTE?</label>
                    <input
                      value={tarjeta_transporte}
                      onChange={ (e) => setTarjeta_transporte(e.target.value)}
                      type='text'
                      className='form-control'
                    />
                </div>
                <div className='mb-3'>
                  <label className='form-label'>ANTIGUEDAD DE LOS VEHICULOS</label>
                    <input
                      value={vehiculos_antiguedad}
                      onChange={ (e) => setVehiculos_antiguedad(e.target.value)}
                      type='text'
                      className='form-control'
                    />
                </div>
                <div className='form-check form-switch'>
                  <label className="form-check-label" htmlFor="flexSwitchCheckDefault">¿LOS VEHICULOS ESTAN ASEGURADOS?</label>
                    <input
                      checked={vehiculos_asegurados}
                      onChange={ (e) => setVehiculos_asegurados(e.target.checked)}
                      type='checkbox'
                      className='form-check-input'
                    />
                </div>
                <div className='form-check form-switch'>
                  <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>¿TIENE ASISTENCIA EN RUTA?</label>
                    <input
                      checked={asistencia_ruta}
                      onChange={ (e) => setAsistencia_ruta(e.target.checked)}
                      type='checkbox'
                      className='form-check-input'
                    />
                </div>
                <div className='form-check form-switch mb-3'>
                  <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>¿DISPONE DE UN CHOFER CALIFICADO PARA SUS TRANSPORTES?</label>
                    <input
                      checked={chofer_calificado}
                      onChange={ (e) => setChofer_calificado(e.target.checked)}
                      type='checkbox'
                      className='form-check-input'
                    />
                </div>
                
                <div className='mb-3'>
                  <label className='form-label'>DESCRIBA SERVICIOS COMPLEMENTARIOS</label>
                    <input
                      value={extras}
                      onChange={ (e) => setExtras(e.target.value)}
                      type='text'
                      className='form-control'
                    />
                </div>
                <div className='mb-3'>
                  <label className='form-label'>MANEJAN OTROS IDIOMAS</label>
                    <input
                      value={idiomas}
                      onChange={ (e) => setIdiomas(e.target.value)}
                      type='text'
                      className='form-control'
                    />
                </div>
                </div>
          )}
    
          {currentPage === 4 && (
            <div className="row">
              {/** TEMPORADA ALTA */}
              <label className='h3 mb-4'><i className="fa-regular fa-calendar-plus"></i> DATOS DE TEMPORADA ALTA </label>
              <div className='mb-3'>
                  <label className='form-label'>INGRESE AQUI MESES (DESDE - HASTA)</label>
                    <input
                      value={temporada_alta}
                      onChange={ (e) => setTemporada_altra(e.target.value)}
                      type='text'
                      className='form-control'
                    />
                </div>
                <div className='mb-3'>
                  <label className='form-label'>CAPACIDAD TEMPORADA ALTA</label>
                    <input
                      value={capacidad_temp_alta}
                      onChange={ (e) => setCapacidad_temp_alta(e.target.value)}
                      type='text'
                      className='form-control'
                    />
                </div>
              </div>
          )}
    
          {currentPage === 5 && (
            <div className="row">
              {/** COSTES Y MEDIOS DE PAGOS */}
              <label className='h3 mb-4'><i className="fa-solid fa-hand-holding-dollar"></i> COSTOS Y DATOS DE PAGO DEL SERVICIO </label>
                <div className='mb-3'>
                  <label className='form-label'>VALOR DEL ALOJAMIENTO (rangos de valores relativos)</label>
    
                  {Object.keys(valor_noche).map((tipo) => (
                    <div key={tipo}>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id={`checkbox_${tipo}`}
                          checked={valor_noche[tipo].checked}
                          onChange={(e) => {
                            const newValor_noche = {
                              ...valor_noche,
                              [tipo]: {
                                ...valor_noche[tipo],
                                checked: e.target.checked,
                              },
                            };
                            setValor_noche(newValor_noche);
                          }}
                        />
                        <label className='form-check-label' htmlFor={`checkbox_${tipo}`}>
                          {tipo}
                        </label>
                      </div>
                      {tipo === 'OTRO' && valor_noche[tipo].checked && (
                       <div className='mt-2'>
                          <input
                            type='text'
                            value={valor_noche[tipo].otro}
                            onChange={(e) => {
                              const newValor_noche = {
                                ...valor_noche,
                                [tipo]: {
                                 ...valor_noche[tipo],
                                  otro: e.target.value,
                                },
                              };
                              setValor_noche(newValor_noche);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
    
                <div className='mb-3'>
                    <label className='form-label'> CUENTA BANCARIA DE EMPRESA </label>
                    <select value={cuenta_bancaria} onChange={(e) => setCuenta_bancaria(e.target.value)} className='form-select' aria-label='Default select example'>
                      <option value='SI'>SI</option>
                      <option value='CUENTA PERSONAL'>CUENTA PERSONAL</option>
                      <option value='NO'>NO</option>
                    </select>
                </div>
                <div className='mb-3'>
                  <label className='mb-3'> FORMAS DE PAGO</label>
    
                  {Object.keys(forma_pago).map((tipo) => (
                    <div key={tipo}>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id={`checkbox_${tipo}`}
                          checked={forma_pago[tipo].checked}
                          onChange={(e) => {
                            const newForma_pago = {
                              ...forma_pago,
                              [tipo]: {
                                ...forma_pago[tipo],
                                checked: e.target.checked,
                              },
                            };
                            setForma_pago(newForma_pago);
                          }}
                        />
                        <label className='form-check-label' htmlFor={`checkbox_${tipo}`}>
                          {tipo}
                        </label>
                      </div>
                      {tipo === 'OTRO' && forma_pago[tipo].checked && (
                       <div className='mt-2'>
                          <input
                            type='text'
                            value={forma_pago[tipo].otro}
                            onChange={(e) => {
                              const newForma_pago = {
                                ...forma_pago,
                                [tipo]: {
                                 ...forma_pago[tipo],
                                  otro: e.target.value,
                                },
                              };
                              setForma_pago(newForma_pago);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
    
                <div className='mb-3'>
                  <label className='form-label'> SISTEMAS DE PAGO</label>
                  {Object.keys(sistema_pago).map((tipoPago) => (
                    <div key={tipoPago}>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id={`checkbox_${tipoPago}`}
                          checked={sistema_pago[tipoPago].checked}
                          onChange={(e) => {
                            const newSistema_pago = {
                              ...sistema_pago,
                              [tipoPago]: {
                                ...sistema_pago[tipoPago],
                                checked: e.target.checked,
                              },
                            };
                            setSistema_pago(newSistema_pago);
                          }}
                        />
                        <label className='form-check-label' htmlFor={`checkbox_${tipoPago}`}>
                          {tipoPago}
                        </label>
                      </div>
                      {tipoPago === 'OTRO' && sistema_pago[tipoPago].checked && (
                       <div className='mt-2'>
                          <input
                            type='text'
                            value={sistema_pago[tipoPago].otro}
                            onChange={(e) => {
                              const newSistema_pago = {
                                ...sistema_pago,
                                [tipoPago]: {
                                 ...sistema_pago[tipoPago],
                                  otro: e.target.value,
                                },
                              };
                              setSistema_pago(newSistema_pago);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
    
                <div className='mb-3'>
                  <label className='form-label'>¿CUENTAN CON ALGUN SOFTWARE DE GESTIÓN?</label>
                    <input
                      value={software}
                      onChange={ (e) => setSoftware(e.target.value)}
                      type='text'
                      className='form-control'
                    />
                </div>
                <div className='mb-3'>
                    <label className='form-label'> ¿ESTA DISPUESTO A PAGAR EL 10%? </label>
                    <select value={pagar_10} onChange={(e) => setPagar_10(e.target.value)} className='form-select' aria-label='Default select example'>
                      <option value='SI'>SI</option>
                      <option value='NO'>NO</option>
                      <option value='TAL VEZ'>TAL VEZ</option>
                    </select>
                </div>
            </div>
          )}
    
          {currentPage === 1 && (
            <div className="d-flex justify-content-end mb-3">
            <button onClick={nextPage} className="btn btn-primary">
              Siguiente
            </button>
            </div>
          )}
    
          {currentPage > 1 && currentPage < 5 && (
            <>
            <div className="d-flex justify-content-center mb-3">
              <button onClick={previousPage} className="btn btn-primary">
                Anterior
              </button>
              <button onClick={nextPage} className="btn btn-primary">
                Siguiente
              </button>
            </div>
            </>
          )}
    
          {currentPage === 5 && (
            <>
            <div className="d-flex justify-content-center mb-3">
              <button onClick={previousPage} className="btn btn-primary">
                Anterior
              </button>
              <button onClick={update} className="btn btn-primary">
                Actualizar Datos
              </button>
            </div>
            </>
          )}
        </div>
      )
    }

export default EditarServicioTransporte;