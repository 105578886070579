import React from "react";
import { useTranslation } from 'react-i18next'; // Importa el hook de traducción

const BannerServices = () => {
  const { t } = useTranslation();

  return (
    <div className="container-fluid bg-white bannerServices">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-4">
              <div className="card border-light-subtle mb-4 mb-lg-0">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="p-2"><div className="bg-black rounded-4 text-white icon-box text-center pt-3 fs-1"><i className="bi bi-geo"></i></div></div>
                    <div className="p-2 flex-grow-1">
                      <h3><strong>{t('BANNER_SERVICES.LOCACIONES_TITULO')}</strong></h3>
                      <p>{t('BANNER_SERVICES.LOCACIONES_DESCRIPCION')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-lg-4">
              <div className="card border-light-subtle mb-4 mb-lg-0">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="p-2"><div className="bg-black rounded-4 text-white icon-box text-center pt-3 fs-1"><i className="bi bi-box-seam"></i></div></div>
                    <div className="p-2 flex-grow-1">
                      <h3><strong>{t('BANNER_SERVICES.LOGISTICA_TITULO')}</strong></h3>
                      <p>{t('BANNER_SERVICES.LOGISTICA_DESCRIPCION')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="card border-light-subtle">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="p-2"><div className="bg-black rounded-4 text-white icon-box text-center pt-3 fs-1"><i className="bi bi-camera-reels"></i></div></div>
                    <div className="p-2 flex-grow-1">
                      <h3><strong>{t('BANNER_SERVICES.FIXERS_TITULO')}</strong></h3>
                      <p>{t('BANNER_SERVICES.FIXERS_DESCRIPCION')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default BannerServices;
