import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom';
//import ReactMarkdown from 'react-markdown';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dropzone from 'react-dropzone';

import {collection, addDoc} from 'firebase/firestore';
import { db, subirArchivo5 } from "../firebaseConfig/firebase";

import Swal from 'sweetalert2';


const BlogEntrada = () => {
  const [titulo, setTitulo] = useState('');
  const [contenido, setContenido] = useState('');
  const [fechaPublicacion, setFechaPublicacion] = useState(new Date());
  const [imagen, setImagen] = useState(null);

  const [isEnviando, setIsEnviando] = useState(false); // Nuevo estado para controlar el envío


  const handleFechaChange = (date) => {
    setFechaPublicacion(date);
  };

  const handleImagenDrop = (files) => {
    const file = files[0];
    setImagen(file);
  };

  const navigate = useNavigate();
  const blog_entrada = collection(db, 'blog_entrada');
  const store = async (e) => {
    e.preventDefault();
    if (isEnviando) {
      // Evita que se envíe múltiples veces mientras se está procesando
      return;
    }

    setIsEnviando(true); // Deshabilita el botón

    try{

      const camposAValidar = [
        { campo: titulo, nombreCampo: 'Titulo' },
        { campo: contenido, nombreCampo: 'Contenido' }
        // Agrega más campos aquí
      ];

      for (const campoInfo of camposAValidar) {
        if (!campoInfo.campo || campoInfo.campo.trim() === "" ) {
            throw Swal.fire(`El campo ${campoInfo.nombreCampo} no puede estar vacío o debe seleccionar al menos un valor`);
        }
      }
      const resultado = await subirArchivo5(imagen); //aqui se sube la imagen de referencia del negocio al storage firebase : consultar firebase.js
      console.log(resultado)
      await addDoc (blog_entrada, {

      titulo:titulo,
      contenido:contenido,
      fechaPublicacion:fechaPublicacion,
      imagen:resultado

    })
    Swal.fire('Se Agrego una nueva entrada al Blog de Shootinpatagonia')
    navigate('/ver');
    }catch (error){
      console.error(error);
      alert('fallo al subir imagen , intente nuevamente ');
    } finally {
      setIsEnviando(false); // Reactiva el botón después de completar la subida
    }
  };
  return (
    <div className='container'>
      <div className='row pt-5'>
        <div className='col-12 py-5'>
          <h1 className='pt-5'>Admin <strong>blog</strong></h1>
        </div>
      </div>

      <div className='row pb-5 mb-5'>
        <div className='col'>
          <h1 className='pb-4'>Crear Entrada de Blog</h1>
          
          <input className='form-control' type="text" placeholder="Título" value={titulo} onChange={(e) => setTitulo(e.target.value)} />

          <ReactQuill value={contenido} onChange={setContenido} className='py-5'/>

          <DatePicker selected={fechaPublicacion} onChange={handleFechaChange} />

              <Dropzone onDrop={handleImagenDrop}>
                {({ getRootProps, getInputProps }) => (
                  <div className='card pointer rounded-4 border-4 dashed my-4' {...getRootProps()}>
                    <div className='card-body'>
                      <input className='form-control' {...getInputProps()} />
                      <p>Arrastra una imagen o haz clic para seleccionar una.</p>
                    </div>
                  </div>
                )}
              </Dropzone>

          {imagen && <img src={URL.createObjectURL(imagen)} alt="Vista previa de la imagen" style={{ maxWidth: '640px', maxHeight: '480px' }}/>}
          
          <div>
            <button className="btn btn-primary rounded-pill px-4" onClick={store} disabled={isEnviando}>Publicar</button>
          </div>
        </div>
      </div>      
    </div>
  );
};

export default BlogEntrada;
