import React from 'react';
import './estilos/nosotros.css';
import fixer from '../imagenes/banners/fixer.jpg';
import produce from '../imagenes/banners/produce.jpg';
import explore from '../imagenes/banners/explore.jpg';
import CarouselComponent4 from "../componentes/componentes_paginas/carruselVideo";
import { ContactForm } from '../componentes/componentes_paginas/contacto3';
import { useTranslation } from 'react-i18next';

function Nosotros() {
  const { t } = useTranslation();

  return (
    <div className="servicios">
      <div className="container-fluid bg-white">
        <div className='container pt-5'>
          <div className='row pt-4'>
            <div className='col-12 py-4'>
            <h1 className='pt-5'>{t('SERVICIOS.NUESTROS')}<strong> {t('SERVICIOS.SERVICIOS')}</strong></h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-7 col-xl-5 offset-xl-1">
              <h2 className='pb-4'>{t('SERVICIOS.FIXERS_TITULO')}</h2>
              <p className='lead'>{t('SERVICIOS.FIXERS_DESCRIPCION')}</p>

              <div className="row fixers">            
                <div className="col-6 col-lg-4 mb-4">
                  <div className="card text-bg-light border-light-subtle mb-4 mb-lg-0 bg-white">
                    <div className="card-body">
                      <h2 className="text-center"><i className="bi bi-camera-reels"></i></h2>
                    </div>
                  </div>
                  <h4>{t('SERVICIOS.MAKING_OF')}</h4>
                </div>

                <div className="col-6 col-lg-4 mb-4">
                  <div className="card text-bg-light border-light-subtle mb-4 mb-lg-0 bg-white">
                    <div className="card-body">
                      <h2 className="text-center"><i className="bi bi-person-video"></i></h2>
                    </div>
                  </div>
                  <h4>{t('SERVICIOS.CASTING_AUDITIONS')}</h4>
                </div>

                <div className="col-6 col-lg-4 mb-4">
                  <div className="card text-bg-light border-light-subtle mb-4 mb-lg-0 bg-white">
                    <div className="card-body">
                      <h2 className="text-center"><i className="bi bi-hdd-stack"></i></h2>
                    </div>
                  </div>
                  <h4>{t('SERVICIOS.DOWNLOAD_MANAGER')}</h4>
                </div>

                <div className="col-6 col-lg-4 mb-4">
                  <div className="card text-bg-light border-light-subtle mb-4 mb-lg-0 bg-white">
                    <div className="card-body">
                      <h2 className="text-center"><i className="bi bi-cup-straw"></i></h2>
                    </div>
                  </div>
                  <h4>{t('SERVICIOS.RUNNER_ASSISTANT')}</h4>
                </div>

                <div className="col-6 col-lg-4 mb-4">
                  <div className="card text-bg-light border-light-subtle mb-4 mb-lg-0 bg-white">
                    <div className="card-body">
                      <h2 className="text-center"><i className="bi bi-laptop"></i></h2>
                    </div>
                  </div>
                  <h4>{t('SERVICIOS.ASSEMBLY_EDITING')}</h4>
                </div>

                <div className="col-6 col-lg-4 mb-4">
                  <div className="card text-bg-light border-light-subtle mb-4 mb-lg-0 bg-white">
                    <div className="card-body">
                      <h2 className="text-center"><i className="bi bi-camera"></i></h2>
                    </div>
                  </div>
                  <h4>{t('SERVICIOS.AERIAL_PHOTO')}</h4>
                </div>
              </div>
            </div>

            <div className="col-md-5 col-xl-4 offset-xl-1">
              <img src={fixer} alt="shootinpatagonia" className="img-fluid mt-5 mb-5 img-thumbnail"/>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-dark text-white">
        <div className="container py-5">
          <div className="row d-flex align-items-center">
            <div className="col-md-5 col-xl-4 offset-xl-1">
              <img src={produce} alt="shootinpatagonia" className="img-fluid mt-5 mb-5 img-thumbnail"/>
            </div>
            <div className="col-md-7 col-xl-5 offset-xl-1">
              <h2 className='pb-4'>{t('SERVICIOS.PRODUCCION_TITULO')}</h2>
              <p className='lead'>{t('SERVICIOS.PRODUCCION_DESCRIPCION')}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-primary text-white">
        <div className="container py-5">
          <div className="row d-flex align-items-center">
            <div className="col-md-7 col-xl-5 offset-xl-1">
              <h2 className='pb-4'>{t('SERVICIOS.EXPLORAR_TITULO')}</h2>
              <p className='lead'>{t('SERVICIOS.EXPLORAR_DESCRIPCION')}</p>
            </div>

            <div className="col-md-5 col-xl-4 offset-xl-1">
              <img src={explore} alt="shootinpatagonia" className="img-fluid mt-5 mb-5 img-thumbnail"/>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-black text-white">
        <div className="container py-5">
          <div className="row d-flex align-items-center">
            <div className="col-12">
              <h2 className='pb-4 text-center'>{t('SERVICIOS.TOMAS_TITULO')}</h2>
            </div>

            <div className="col-12">
              <CarouselComponent4 />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-dark text-white">
        <div className="container py-5">
          <div className="row d-flex align-items-center">
            <div className="col-md-6 col-lg-5 offset-lg-1">
              <h2 className='pb-4'>{t('SERVICIOS.CONVERSEMOS_TITULO')}</h2>
              <p className='lead'>{t('SERVICIOS.CONVERSEMOS_DESCRIPCION')}</p>
            </div>

            <div className="col-md-6 col-lg-5">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nosotros;
