import React from 'react';
import './estilos/pantallaCarga.css'; // Archivo CSS para estilos

const PantallaCarga = () => {
  return (
    <div className="loading-screen">
      <div className="loading-spinner"></div>
      <p>Cargando...</p>
    </div>
  );
};

export default PantallaCarga;
