import React, { useState, useEffect } from "react";
//import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebase";

const VerServicioAlojamiento = () => {
  const { id, tipoServicio } = useParams();
  const [servicio, setServicio] = useState(null);

  useEffect(() => {
    const fetchServicio = async () => {
      const collectionName = (() => {
        switch (tipoServicio) {
          case 'alojamiento':
            return 'servicio_alojamiento';
          case 'transporte':
            return 'servicio_transporte';
          case 'gastronomico':
            return 'servicio_gastronomico';
          case 'otro':
            return 'servicio_otro';
          default:
            return 'servicio_alojamiento'; // Valor por defecto si no coincide con ninguna de las opciones anteriores.
        }
      })();
      const servicioDoc = doc(db, collectionName, id);

      const servicioSnapshot = await getDoc(servicioDoc);
      if (servicioSnapshot.exists()) {
        setServicio(servicioSnapshot.data());
      } else {
        console.log("El servicio no existe");
      }
    };

    fetchServicio();
  }, [id, tipoServicio]);

  if (!servicio) {
    return <div>Cargando...</div>;
  }

  const filteredService = Object.keys(servicio).reduce((acc, key) => {
    if (Array.isArray(servicio[key])) {
      const filteredArray = servicio[key].filter(item => item === true);
      if (filteredArray.length > 0) {
        acc[key] = filteredArray;
      }
    } else {
      acc[key] = servicio[key];
    }
    return acc;
  }, {});

  //const servicioEntries = Object.entries(filteredService);

  return (
		<>

			<div className="locacion-seleccionada">
				<div className="banner">
      	  <div className="text-container">
      	    <h3 style={{ textTransform: 'uppercase' }}>{filteredService.empresa}</h3>
      	    <h4 style={{ textTransform: 'uppercase' }}>{tipoServicio}</h4>
      	  </div>
      	  <img src={filteredService.imagen} alt="Imagen de portada" /> {/* La imagen del banner se actualiza aquí */}
      	</div>
  		</div>

			</>
	);
	
};

export default VerServicioAlojamiento;

			/* <div className="cajaCrear mt-4">
			<h2>Datos de contacto {filteredService.empresa} <Link to={`/editar_servicio${tipoServicio === 'alojamiento' ? 'Alojamiento' : tipoServicio === 'transporte' ? 'Transporte' : tipoServicio === 'gastronomico' ? 'Gastronomico' : tipoServicio === 'otro' ? 'Otro' : ''}/${id}/${tipoServicio}`} className="btn btn-light"><i className="fas fa-pencil-alt"></i></Link></h2>

				{typeof filteredService.definicion_negocio === 'object' ? (
  				<div className="mt-3">
  				  <label>Como se define este Negocio: </label>
  				  <ul>
  				    {Object.entries(filteredService.definicion_negocio).map(([key, value]) => (
  				      <li key={key}>
  				        <label>{key}:</label> {value ? 'true' : 'false'}
  				      </li>
  				    ))}
  				  </ul>
  				</div>
					) : (
  				<div>La definición de negocio no es un objeto.</div>
				)}

				<h2>Detalles Operativos de {filteredService.empresa}</h2>
				<div className="row">
						<label>antiguedad : {filteredService.antiguedad}</label>
						<label>Sello de calidad : {filteredService.sello_calidad}</label>
						<label>Calificacion de los clientes : {filteredService.calificacion_clientes}</label>
				</div>
				{typeof filteredService.camas_tipo_cantidad === 'object' ? (
  				<div className="mt-3">
  				  <label>Capacidad de camas tipos y cantidades: </label>
  				  <ul>
  				    {Object.entries(filteredService.camas_tipo_cantidad).map(([key, value]) => (
  				      <li key={key}>
  				        <label>{key}:</label> {value.checked ? 'true' : 'false'} {value.cantidad}
  				      </li>
  				    ))}
  				  </ul>
  				</div>
					) : (
  				<div>La definición de tipo de cama y cantidad no es un objeto.</div>
				)}

				<div className="row">
						<label>Calidad de las camas : {filteredService.calidad}</label>
						<label>Acceso a internet: {filteredService.internet ? 'true' : 'false'}</label>
						<label>Desayuno: {filteredService.desayuno ? 'true' : 'false'}</label>
						<label>Almuerzo: {filteredService.almuerzo ? 'true' : 'false'}</label>
						<label>Cena: {filteredService.cena ? 'true' : 'false'}</label>
						<label>Otro servicio de alimentación: {filteredService.otra_alimentacion}</label>
						<label>Servicios de aseo y limpieza: {filteredService.servicio_limpieza ? 'true' : 'false'}</label>
						<label>Servicios complementarios: {filteredService.extras}</label>
						<label>Manejo de idiomas : {filteredService.idiomas}</label>
				</div>
				<div className="row">
						<label>Fechas de temporada alta : {filteredService.temporada_alta}</label>
						<label>Capacidad en temporada alta : {filteredService.capacidad_temp_alta}</label>
				</div>

				<h2>Detalles comerciales de {filteredService.empresa}</h2>
				{typeof filteredService.valor_noche === 'object' ? (
  				<div className="mt-3">
  				  <label>Valor de alojamiento por noche: </label>
  				  <ul>
  				    {Object.entries(filteredService.valor_noche).map(([key, value]) => (
  				      <li key={key}>
  				        <label>{key}:</label> {value.checked ? 'true' : 'false'} {value.cantidad}
  				      </li>
  				    ))}
  				  </ul>
  				</div>
					) : (
  				<div>La definición de valor por noche no es un objeto.</div>
				)}
				<div className="row">
						<label>Tipo de cuenta bancaria : {filteredService.cuenta_bancaria}</label>
				</div>

				{typeof filteredService.forma_pago === 'object' ? (
  				<div className="mt-3">
  				  <label>Formas de pago: </label>
  				  <ul>
  				    {Object.entries(filteredService.forma_pago).map(([key, value]) => (
  				      <li key={key}>
  				        <label>{key}:</label> {value.checked ? 'true' : 'false'} {value.forma}
  				      </li>
  				    ))}
  				  </ul>
  				</div>
					) : (
  				<div>La definición de formas de pago no es un objeto.</div>
				)}

				{typeof filteredService.sistema_pago === 'object' ? (
  				<div className="mt-3">
  				  <label>Sistemas de pago: </label>
  				  <ul>
  				    {Object.entries(filteredService.sistema_pago).map(([key, value]) => (
  				      <li key={key}>
  				        <label>{key}:</label> {value.checked ? 'true' : 'false'} {value.forma}
  				      </li>
  				    ))}
  				  </ul>
  				</div>
					) : (
  				<div>La definición de siterma de pago no es un objeto.</div>
				)}	

				<h2>Detalles Camping en {filteredService.empresa}</h2>
				<div className="row ">
						<label>Camping con baño : {filteredService.camping_baño ? 'true' : 'false'}</label>
						<label>Camping con ducha : {filteredService.camping_ducha ? 'true' : 'false'}</label>
						<label>Camping con electricidad : {filteredService.camping_electricidad ? 'true' : 'false'}</label>
						<label>Camping con cocina: {filteredService.camping_cocina ? 'true' : 'false'}</label>
						<label>Camping con lavanderia : {filteredService.camping_lavanderia ? 'true' : 'false'}</label>
						<label>Camping con internet : {filteredService.camping_internet ? 'true' : 'false'}</label>
						<label>Camping con estacionamiento : {filteredService.camping_estacionamiento ? 'true' : 'false'}</label>
						<label>Servicios extra en el camping : {filteredService.camping_servicios_extra}</label>
						<label>Valor del camping por noche : {filteredService.camping_valor}</label>
				</div>
			</div> */
