import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Calculadora = ({ CostoServicio, PermisoLocacion, fechaInicio, fechaFin, tipoServicio }) => {
  const { t } = useTranslation();
  const [costoTotal, setCostoTotal] = useState(0);
  
  function formatCostSimple(value) {
    // Eliminar el símbolo '$' si está presente
    value = value.replace('$', '');
    // Eliminar cualquier carácter que no sea un número
    value = value.replace(/\D/g, '');
    // Convertir el valor resultante a un número entero
    const integerValue = parseInt(value, 10);
    return integerValue;
  }

  // Calcular el número de días entre fechaInicio y fechaFin
  const startDate = new Date(fechaInicio);
  const endDate = new Date(fechaFin);
  const diffTime = Math.abs(endDate - startDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24) + 1); //se agrega un dia mas para integrar el mismo dia de la toma de servicio y no solo la diferencia entre inicio y termino 

  const permisoLocacion = formatCostSimple(PermisoLocacion);

  useEffect(() => {
    if (tipoServicio !== 'otros') {
      setCostoTotal(costoTotal + (CostoServicio * diffDays));
    }
    else{
      setCostoTotal(costoTotal + CostoServicio);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CostoServicio, tipoServicio]);

  useEffect(() => {
    setCostoTotal(costoTotal => costoTotal + (permisoLocacion * diffDays)); //esta suma se realiza solo una vez a diferencia de la que esta arriba OJO con eso 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permisoLocacion, diffDays]);

  const formatNumberWithCommas = (number) => {
    return number.toLocaleString('es-ES', { minimumFractionDigits: 0 });
  };

  return (
    <>
      <div className='card-body'>
        <p className='py-2'>{t('CALCULADORA.COSTO_TOTAL')}: CLP $<strong>{formatNumberWithCommas(costoTotal)}</strong></p>
        <div class="alert alert-info" role="alert">
          <small>{t('CALCULADORA.INFO')}</small>
        </div>
      </div>
    </>
  );
};

export default Calculadora;
