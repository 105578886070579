// resend.js
import { db } from '../firebaseConfig/firebase';
import { collection, addDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';

const cuerpoCorreoHTML = (nombre, correo, cotizacion) => `
<!DOCTYPE html>
<html lang="es">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<style type="text/css">
  /* estilos omitidos para brevedad */
</style>
<title>Cotización</title>
</head>
<body style="font-family: 'Poppins', Arial, sans-serif">
    <table width="100%" border="0" cellspacing="0" cellpadding="0">
        <tr>
            <td align="center" style="padding: 20px;">
                <table class="content" width="600" border="0" cellspacing="0" cellpadding="0" style="border-collapse: collapse; border: 1px solid #cccccc;">
                    <!-- Header -->
                    <tr>
                        <td class="header" style="background-color: #345C72; padding: 0px; text-align: center; color: white; font-size: 24px;">
                            <img src="https://firebasestorage.googleapis.com/v0/b/shoot-in-patagonia.appspot.com/o/web%2Frecursos_correos%2FBanner%20mailing%20SHP.jpg?alt=media&token=68fdc93a-75f1-4d46-8f88-89737b3fd444" alt="banner mail" width="100%">
                        </td>
                    </tr>

                    <!-- Body -->
                    <tr>
                        <td class="body" style="padding: 40px; text-align: left; font-size: 16px; line-height: 1.6;">
                        Hola, ${nombre}! <br>
                        A continuación te mostramos el detalle de la cotización de ${cotizacion.nombreLocacion}
                        </td>
                    </tr>
                    <tr>
                        <td class="body" style="padding: 40px; padding-top:0; text-align: left; font-size: 16px; line-height: 1.6;">
                            <table width="100%" border="0" style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0; margin-bottom: 40px;">
                                <tr style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                    <td>Locación</td>
                                    <td style="text-align: right;"><strong>${cotizacion.nombreLocacion}</strong></td>
                                </tr>
                                <tr style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                    <td>Fecha inicio</td>
                                    <td style="text-align: right;"><strong>${new Date(cotizacion.fechaInicio).toLocaleDateString()}</strong></td>
                                </tr>
                                <tr style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                    <td>Fecha termino</td>
                                    <td style="text-align: right;"><strong>${new Date(cotizacion.fechaFin).toLocaleDateString()}</strong></td>
                                </tr>
                                <tr style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                    <td>Participantes</td>
                                    <td style="text-align: right;"><strong>${cotizacion.totalPersonas}</strong></td>
                                </tr>
                                <tr style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                    <td>Días de rodaje</td>
                                    <td style="text-align: right;"><strong>${cotizacion.diffDays}</strong></td>
                                </tr>
                            </table>

                            ${cotizacion.serviciosAlojamiento.length > 0 ? `
                            <p><strong>Servicio de alojamiento</strong></p>
                            
                            <table width="100%" border="0" style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                <thead>
                                    <tr style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                        <td>Servicio</td>
                                        <td>Personas</td>
                                        <td style="text-align: right;">Costo diario</td>
                                    </tr>
                                </thead>

                                <tbody>
                                    ${cotizacion.serviciosAlojamiento.map(servicio => `
                                    <tr style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                        <td>${servicio.NombreServicio}</td>
                                        <td>${servicio.CantidadPersonas}</td>
                                        <td style="text-align: right;"><strong>${servicio.CostoServicio.toLocaleString()}</strong></td>
                                    </tr>
                                    `).join('')}
                                    <tr style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                        <td colspan="2">Sub Total por ${cotizacion.diffDays} días</td>
                                        <td style="text-align: right;"><strong>${(cotizacion.totalCostAlojamiento * cotizacion.diffDays).toLocaleString()}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                            ` : ''}


                            ${cotizacion.serviciosTransporte.length > 0 ? `
                            <p><strong>Servicio de transporte</strong></p>
                            
                            <table width="100%" border="0" style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                <thead>
                                    <tr style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                        <td>Servicio</td>
                                        <td>Vehículo</td>
                                        <td>Cantidad</td>
                                        <td style="text-align: right;">Costo diario</td>
                                    </tr>
                                </thead>

                                <tbody>
                                    ${cotizacion.serviciosTransporte.map(servicio => `
                                    <tr style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                        <td>${servicio.NombreServicio}</td>
                                        <td>${servicio.TipoVehiculo}</td>
                                        <td>${servicio.CantidadPersonas}</td>
                                        <td style="text-align: right;"><strong>${servicio.CostoServicio.toLocaleString()}</strong></td>
                                    </tr>
                                    `).join('')}
                                    <tr style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                        <td colspan="3">Sub Total por ${cotizacion.diffDays} días</td>
                                        <td style="text-align: right;"><strong>${(cotizacion.totalCostTransporte * cotizacion.diffDays).toLocaleString()}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                            ` : ''}

                            ${cotizacion.serviciosGastronomia.length > 0 ? `
                            <p><strong>Servicio de gastronomía</strong></p>
                                
                            <table width="100%" border="0" style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                <thead>
                                    <tr style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                        <td>Servicio</td>
                                        <td>Cantidad</td>
                                        <td style="text-align: right;">Costo diario</td>
                                    </tr>
                                </thead>
    
                                <tbody>
                                    ${cotizacion.serviciosGastronomia.map(servicio => `
                                    <tr style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                        <td>${servicio.NombreServicio}</td>
                                        <td>${servicio.CantidadPersonas}</td>
                                        <td style="text-align: right;"><strong>${servicio.CostoServicio.toLocaleString()}</strong></td>
                                    </tr>
                                    `).join('')}
                                    <tr style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                        <td colspan="2">Sub Total por ${cotizacion.diffDays} días</td>
                                        <td style="text-align: right;"><strong>${(cotizacion.totalCostGastronomia * cotizacion.diffDays).toLocaleString()}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                            ` : ''}

                            ${cotizacion.serviciosOtros.length > 0 ? `
                            <p><strong>Otros servicios</strong></p>
                                    
                            <table width="100%" border="0" style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                <thead>
                                    <tr style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                        <td>Servicio</td>
                                        <td>Descripción</td>
                                        <td style="text-align: right;">Costo</td>
                                    </tr>
                                </thead>
        
                                <tbody>
                                    ${cotizacion.serviciosGastronomia.map(servicio => `
                                    <tr style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                        <td>${servicio.NombreServicio}</td>
                                        <td>${servicio.CantidadPersonas}</td>
                                        <td style="text-align: right;"><strong>${servicio.CostoServicio.toLocaleString()}</strong></td>
                                    </tr>
                                    `).join('')}
                                    <tr style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                        <td colspan="2">Sub Total</td>
                                        <td style="text-align: right;"><strong>${cotizacion.totalCostOtros.toLocaleString()}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                            ` : ''}

                            <p><strong>Total</strong></p>
                                        
                            <table width="100%" border="0" style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                <tbody>
                                    <tr style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                        <td>Costo Locación (x ${cotizacion.diffDays} días)</td>
                                        <td style="text-align: right;"><strong>${(cotizacion.costoLocacionNumber * cotizacion.diffDays).toLocaleString()}</strong></td>
                                    </tr>
                                    <tr style="border-collapse: collapse; border: 0; border-bottom: 1px solid #cccccc; margin: 0;">
                                        <td>Total</td>
                                        <td style="text-align: right;"><strong>${cotizacion.totalCost.toLocaleString()}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <!-- Footer -->
                    <tr>
                        <td class="footer" style="background-color: #333333; padding: 0px; text-align: center; color: white; font-size: 14px;">
                            <img src="https://firebasestorage.googleapis.com/v0/b/shoot-in-patagonia.appspot.com/o/web%2Frecursos_correos%2FFooter%20mailing%20shoot%20in%20patagonia.jpg?alt=media&token=432f9180-e585-45e7-91ed-4ae948067ea7" alt="footer mail" width="100%">
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</body>
</html>
`;

export async function sendCustomEmail(nombre, correo, cotizacion) {
  const collectionRef = collection(db, 'mail');
  const emailContent = {
    to: `desarrollador@catarsis.cl, ${correo}`, // Envía el correo al destinatario específico
    message: {
      subject: `Cotización solicitada por ${nombre}`,
      text: `Cotización solicitada por ${nombre}. Detalles: ${JSON.stringify(cotizacion)}`,
      html: cuerpoCorreoHTML(nombre, correo, cotizacion),
    },
  };
  Swal.fire('¡ENVIADO! Muy pronto estaremos respondiendo a tu correo.');
  console.log('listo para ser enviado');
  return await addDoc(collectionRef, emailContent);
}
