//App.js 
import './App.css';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

//importamos nuestros componentes 
import Ver from './componentes/ver';
import Crear from './componentes/crear';
import Inspeccionar from './componentes/inspeccionar';
import Editar from './componentes/editar';
import EditarServicioAlojamiento from './componentes/editar_servicioAlojamiento';
import EditarServicioTransporte from './componentes/editar_servicioTransporte';
import EditarServicioGastronomico from './componentes/editar_servicioGastronomico';
import EditarServicioOtro from './componentes/editar_servicioOtro';
import Servicio from './componentes/servicio';
import ServicioTransporte from './componentes/servicio_trasnporte';
import ServicioGastronomico from './componentes/servicio_gastronomico';
import ServicioOtro from './componentes/servicio_otro';
import BlogEntrada from './componentes/blog_entrada';
//import logo from './imagenes/logos/logo-rectagular.png'; //requiere cambio de color de fodo para usar #0000 compatible
import logoCuadrado from './imagenes/logos/shootin-white.svg'
import logoGob from './imagenes/logos/23_MCAP_RGB-03.png';
import logoCatarsis from './imagenes/logos/logo_catarsis_blanco.png';
import VerLocacion from './componentes/locacionVer';
import EditarLocacion from './componentes/editar_locacion';

//Autenticación de usuarios 
import Sesion from './componentes/Sesion';
import RutaPrivada from './componentes/ruta_privada';

//Paginas de la app públicas
import Inicio from './paginas/inicio';
import Locaciones from './paginas/locaciones';
import Nosotros from './paginas/nosotros';
import Blog from './paginas/blog';
import BlogVista from './paginas/blogVista';
import LocacionSeleccionada from './paginas/locacionSeleccionada';
import Cotizador from './componentes/componentes_paginas/cotizador';
import VerServicioAlojamiento from './componentes/componentes_paginas/verServicioAlojamiento';
import PreviewCotizador from './paginas/previewCotizador';
import Servicios from './paginas/servicios';


import { BrowserRouter, Route, Routes } from 'react-router-dom';

// BOOTSTRAP REACT
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function App() {

  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('selectedLanguage', lng);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  return (     
    <BrowserRouter>
  <Navbar collapseOnSelect expand="lg" className="bg-black fixed-top position-fixed" variant='dark'>
    <Container>
      <Navbar.Brand href={'/Inicio'}>
        <img src={logoCuadrado} className='img-fluid' alt={t('LOGO_ALT.PROYECTO')} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ms-auto">
          <Nav.Link href={'/locaciones'}>{t('NAVBAR.LOCACIONES')}</Nav.Link>
          <Nav.Link href="/servicios">{t('NAVBAR.SERVICIOS')}</Nav.Link>
          <Nav.Link href="/nosotros">{t('NAVBAR.NOSOTROS')}</Nav.Link>
          <Nav.Link href="/sesion">{t('NAVBAR.LOGIN')}</Nav.Link>
          <NavDropdown title={<i className="bi bi-globe"></i>} id="collapsible-nav-dropdown" align="end" className="icons">
            <NavDropdown.Item onClick={() => changeLanguage('es')}>{t('NAVBAR.IDIOMA_ES')}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => changeLanguage('en')}>{t('NAVBAR.IDIOMA_EN')}</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>

  <Routes>
    <Route path='/' element={<Inicio />} />
    <Route path='/Inicio' element={<Inicio />} />
    <Route path='/Sesion' element={<Sesion />} />
    <Route path='/nosotros' element={<Nosotros />} />
    <Route path='/locaciones' element={<Locaciones />} />
    <Route path='/servicios' element={<Servicios />} />
    <Route path='/locacionSeleccionada/:id/' element={<LocacionSeleccionada />} />
    <Route path='/cotizador/:id/:ciudadCercana/' element={<Cotizador />} />
    <Route path="/previewCotizador" element={<PreviewCotizador />} />
    <Route path='/verServicioAlojamiento/:id/:tipoServicio/' element={<VerServicioAlojamiento />} />
    <Route path='/blog' element={<Blog />} />
    <Route path='/blog_vista/:id' element={<BlogVista />} />
  </Routes>

  <RutaPrivada path='/ver' element={<Ver />} />
  <RutaPrivada path='/crear' element={<Crear />} />
  <RutaPrivada path='/locacionVer' element={<VerLocacion />} />
  <RutaPrivada path='/servicio' element={<Servicio />} />
  <RutaPrivada path='/servicio_transporte' element={<ServicioTransporte />} />
  <RutaPrivada path='/servicio_gastronomico' element={<ServicioGastronomico />} />
  <RutaPrivada path='/servicio_otro' element={<ServicioOtro />} />
  <RutaPrivada path='/inspeccionar/:id/:tipoServicio' element={<Inspeccionar />} />
  <RutaPrivada path='/editar/:id' element={<Editar />} />
  <RutaPrivada path='/editar_servicioAlojamiento/:id/:tipoServicio' element={<EditarServicioAlojamiento />} />
  <RutaPrivada path='/editar_servicioTransporte/:id/:tipoServicio' element={<EditarServicioTransporte />} />
  <RutaPrivada path='/editar_servicioGastronomico/:id/:tipoServicio' element={<EditarServicioGastronomico />} />
  <RutaPrivada path='/editar_servicioOtro/:id/:tipoServicio' element={<EditarServicioOtro />} />
  <RutaPrivada path='/editar_locacion/:id/:tipoServicio' element={<EditarLocacion />} />
  <RutaPrivada path='/blog_entrada' element={<BlogEntrada />} />

  <footer className="footer">
    <div className="container">
      <div className="row d-flex align-items-center">
        <div className="col-12 col-lg-4 mt-4">
          <div className="row d-flex align-items-center">
            <div className="col col-lg-2 text-center">
              <a href="http://facebook.com" className='fs-1 text-white'>
                <i className="bi bi-facebook" aria-label={t('FOOTER.FACEBOOK')}></i>
              </a>
            </div>
            <div className="col col-lg-2 text-center">
              <a href="http://youtube.com" className='fs-1 text-white'>
                <i className="bi bi-youtube" aria-label={t('FOOTER.YOUTUBE')}></i>
              </a>
            </div>
            <div className="col col-lg-2 text-center">
              <a href="http://instagram.com" className='fs-1 text-white'>
                <i className="bi bi-instagram" aria-label={t('FOOTER.INSTAGRAM')}></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 offset-lg-4">
          <div className="row d-flex align-items-center">
            <div className="col">
              <img src={logoCatarsis} className='img-fluid' alt={t('LOGO_ALT.PROYECTO')} />
            </div>
            <div className="col">
              <img src={logoGob} className='img-fluid' alt={t('LOGO_ALT.PROYECTO')} />
            </div>
          </div>
        </div>
        <div className='col-12 bg-black'>
          <p className='m-0 p-4 text-center'>
            <small className='text-secondary fw-light'>{t('FOOTER.FINANCIADO_POR')}</small>
          </p>
        </div>
      </div>
    </div>
  </footer>
</BrowserRouter>

  );
}
export default App;